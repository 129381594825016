import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import EmployeeSummaryTable from "./EmployeeSummaryTable";
import CustomerSummaryTable from "./CustomerSummaryTable";
import { useGetCompletedOrdersQuery } from '../../../../store/APIs/completedOrderHandlingApi';
import { SearchProps } from '.';
import { searchResultColumns, produceCsvProps } from "./SearchResultFormatters";
import { paginationOptions } from "../../../../properties/reactBootstrapTableProperties";
import Authorized from "../../../components/Auth/Authorized";
import { exportToExcelManager } from "../../../components/Auth/permissionConstants";

export function SearchResult( { vin, name, email, startDate, endDate }: SearchProps ) {
    const { data, error, isLoading, isFetching } = useGetCompletedOrdersQuery({ vin: vin, name: name, email: email, startDate: startDate, endDate: endDate });
    const { ExportCSVButton } = CSVExport;

    function noDataIndication() {
        return "There are no order results that match the search criteria in the system.";
    }

    return (
        <div className="d-print-none">
            { (isLoading || isFetching) && <p>Searching database...</p> }
            { (!data && !isLoading && !isFetching) && <p>Nothing found for the criteria</p> }
            { (data && !isLoading && !isFetching) &&
                <>
                    <h4 className="mb-3">Search results</h4>

                    <ToolkitProvider keyField="orderId" data={ data } columns={ searchResultColumns } bootstrap4={ true } exportCSV={ produceCsvProps("CCVINSearchOrders", startDate, endDate) }>
                        {
                            props => (
                                <>
                                    <Authorized requiredPermissions={[exportToExcelManager]} requireAll={true} showNotAuthorized={false}>
                                        <div className="text-right">
                                            <CustomerSummaryTable data={data} startDate={startDate} endDate={endDate} />
                                            <EmployeeSummaryTable data={data} startDate={startDate} endDate={endDate} />
                                            <ExportCSVButton className="btn btn-outline-primary" { ...props.csvProps }>Export Search Results</ExportCSVButton>
                                        </div>
                                    </Authorized>
                                    <BootstrapTable 
                                        { ...props.baseProps }
                                        id="order-search-result-table" 
                                        classes="my-3"
                                        bordered={false}
                                        striped={false}
                                        hover={true}
                                        noDataIndication={noDataIndication}
                                        defaultSorted={[{ dataField: "orderDate", order: "asc" }]}
                                        defaultSortDirection="asc"
                                        pagination={ paginationFactory(paginationOptions) }
                                    />
                                </>
                            )}
                    </ToolkitProvider>
                </>
            }           
        </div>
    )
}

export default SearchResult;