import { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { paginationOptions } from "../../../../properties/reactBootstrapTableProperties";
import BatchAddOrderMain from '../BatchAddOrder';
import { batchDetailColumns } from "./BatchDetailColumns";
import { BatchOrderInfo } from '../../../../types/BatchOrder';
import { CompletedOrderSummary } from "../../../../types/CompletedOrder";

interface BatchOrdersProps {
    batch?: BatchOrderInfo
}
function BatchOrders({batch}:BatchOrdersProps) {      
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [vinSearchResults,setVinSearchResults] = useState<CompletedOrderSummary[]>(batch?.orderSummaries??[]);    
    function noDataIndication() {
        return "There are no orders in the batch.";
    }  

    useEffect(() => {
        setVinSearchResults(batch?.orderSummaries??[]);
    },[batch?.orderSummaries]);
    return(
        <>
        <div className="form-row">
        <div className="col-10"><h4>Batch Orders</h4></div>
        <div className="col-2"><p className="text-right"><button type="submit" className="btn btn-primary" onClick={() => setIsAddModalOpen(true) } >Add Order</button></p></div>        
        </div>
        <BatchAddOrderMain show={isAddModalOpen} onHide={() => setIsAddModalOpen(false)} batch={batch} />
        { vinSearchResults && 
        <div>
        <ToolkitProvider keyField="orderId" data={ vinSearchResults } columns={ batchDetailColumns } bootstrap4={ true }>
                        {
                            props => (
                                <>
                                    <BootstrapTable 
                                        { ...props.baseProps }
                                        id="batch-orders-result-table" 
                                        classes="my-3"
                                        bordered={false}
                                        striped={false}
                                        hover={true}
                                        noDataIndication={noDataIndication}
                                        defaultSorted={[{ dataField: "orderDate", order: "asc" }]}
                                        defaultSortDirection="asc"
                                        pagination={ paginationFactory(paginationOptions) }
                                    />
                                </>
                            )}
                    </ToolkitProvider>
        </div>
        }
        </>
    );
}

export default BatchOrders;