import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { skipToken } from "@reduxjs/toolkit/query/react";
import { toast } from 'react-toastify';
import TableActionButton, { MenuOption } from "../../components/TableActionButton";
import { useAppDispatch } from "../../../store/ReduxToolkitSamples/hooks";
import { useDeleteInProgressOrderMutation ,useGetInProgressOrderQuery, useGetInProgressOrdersQuery } from "../../../store/APIs/inProgressOrderHandlingApi";
import { updateOrderInfo, updateCustomerInfo } from "../../../store/transaction/transactionSlice";
import { updateVehicleInfoListArray } from "../../../store/VinsForSearch/VinsForSearchSlice";
import { resetInProgressState } from "../../../utility/resetInProgressState";
import { mapInProgressOrderToOrderInfo, mapInProgressOrderToCustomerInfo } from "../../../mappers/InProgressOrderMappers";
import { mapInProgressOrderToVehicleInfoArray } from "../../../mappers/VehicleInfoMappers";
import { InProgressOrderSummary } from "../../../types/InProgressOrder";
import { uiPaths } from "../../../properties/appProperties";

interface InProgressActionButtonProps {
    row: InProgressOrderSummary;
}

function InProgressActionButton({ row }: InProgressActionButtonProps) {
    const history = useHistory();
    const [orderId, setOrderId] = useState<string>();
    const [deleteOrder, { isLoading: isDeleting }] = useDeleteInProgressOrderMutation();
    const {
        data: inProgressOrderData,
        error: inProgressOrderError,
        refetch: inProgressOrderRefetch,
        isError: inProgressOrderIsError,
        isFetching: inProgressOrderIsFetching,
        isLoading: inProgressOrderIsLoading,
        isSuccess: inProgressOrderIsSuccess,
    } = useGetInProgressOrderQuery(orderId ?? skipToken);
    const {
        refetch: inProgressOrdersRefetch,
    } = useGetInProgressOrdersQuery();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (inProgressOrderData !== undefined && !inProgressOrderError && inProgressOrderIsSuccess) {
            resetInProgressState();
            dispatch(updateOrderInfo(mapInProgressOrderToOrderInfo(inProgressOrderData)));
            dispatch(updateCustomerInfo(mapInProgressOrderToCustomerInfo(inProgressOrderData)));
            dispatch(updateVehicleInfoListArray(mapInProgressOrderToVehicleInfoArray(inProgressOrderData)));
            history.push(uiPaths.CREATE_ORDER);
        }
    }, [inProgressOrderData]);

    useEffect(() => {
        inProgressOrderIsError && toast.error("An error occurred when attempting to load the Order. Try again or call support.");
    }, [inProgressOrderIsError]);

    function handleContinueOrderClick() {
        (row.orderId === orderId) ? inProgressOrderRefetch() : setOrderId(row.orderId);
    }

    function handleDeleteOrderClick() {
        if (window.confirm("Are you sure you would like to delete the order?")) 
        {
            deleteOrder(row.orderId)
            .then(() => {
                toast.success("In Progress Order was deleted successfully.");
                inProgressOrdersRefetch();
            })
            .catch((error) => {
                toast.error("An error was encountered while deleting an In Progress Order. Try again or call support.");
            });
        }
    }

    const menuOptions: MenuOption[] = [
        {menuText: "Continue", onClickFunction: handleContinueOrderClick, dataTestId: "continueOrder"},
        {menuText: "Delete", onClickFunction: handleDeleteOrderClick, dataTestId: "deleteOrder"},
    ];

    return (
        <TableActionButton buttonId={`dropdown-button-drop-${row.orderId}`} menuOptions={menuOptions} />
    );
}

export default InProgressActionButton;
