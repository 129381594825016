import SkVinSearchDetails from "./SkVinSearchDetails";
import SkVinSearchClaim from "./SkVinSearchClaim";
import { SkData, SkVinClaimData } from "../../../types/VehicleInfo";

interface SkVinSearchResultsProps {
    skData: SkData,
    skClaims: SkVinClaimData[]
}

export function SkVinSearchResults( {skData, skClaims}: SkVinSearchResultsProps ) {
    return(
        <div className="sk-vin-search-results">
            <h4>Saskatchewan VIN search results</h4>
            <hr className="mb-4" />
            <div className="row mb-5">
                <div className="col">
                    <h5>Details:</h5>
                    <SkVinSearchDetails skData={skData} />
                </div>
                <div className="col-1"></div>
                <div className="col">
                    <h5>Claims:</h5>
                    { ((skClaims !== null) && (skClaims.length > 0)) ?
                        skClaims.map((c, i) => <SkVinSearchClaim claim={c} key={i} />) :
                        <p>No Saskatchewan damage claims reported to SGI.</p>
                    }
                </div>
            </div>
        </div>
    );
}

export default SkVinSearchResults;