import { VehicleRegistrationBasicInfo, JurisdictionCode, JurisdictionAndStatusInfo } from "../types/VehicleRegistrationInfo";
import { UpdateVehicleRegistrationInfoPayload, JurisdictionAndStatusInfoPayload, defaultUpdateVehicleRegistrationInfoPayload, defaultJurisdictionAndStatusInfoPayload } from "../types/apiPayloads/InProgressOrderPayload";

export function MapProvinceNameToJurisdictionCode(name: string | null | undefined): JurisdictionCode {
    switch (name) {
        case "Alberta": return JurisdictionCode.AB;
        case "Northwest Territories": return JurisdictionCode.NT;
        case "British Columbia": return JurisdictionCode.BC;
        case "Nunavut": return JurisdictionCode.NU;
        case "Manitoba": return JurisdictionCode.MB;
        case "Ontario": return JurisdictionCode.ON;
        case "New Brunswick": return JurisdictionCode.NB;
        case "Prince Edward Island": return JurisdictionCode.PE;
        case "Newfoundland and Labrador": return JurisdictionCode.NF;
        case "Quebec": return JurisdictionCode.QC;
        case "Nova Scotia": return JurisdictionCode.NS;
        case "Saskatchewan": return JurisdictionCode.SK;
        case "Yukon": return JurisdictionCode.YT;
        default: return JurisdictionCode.Empty
    }
}

export function mapVehicleRegistrationBasicInfoToUpdateVehicleRegistrationInfoPayload(model: VehicleRegistrationBasicInfo): UpdateVehicleRegistrationInfoPayload {
    
    function mapJurisdictionAndStatusInfoToPayload(model: JurisdictionAndStatusInfo): JurisdictionAndStatusInfoPayload {
        return {...defaultJurisdictionAndStatusInfoPayload,
            jurisdiction: model.jurisdiction,
            skStatus: (model.skStatus != null) ? model.skStatus : undefined,
            ireLocation: (model.ireLocation != null) ? model.ireLocation : undefined,
            ireCondition: (model.ireCondition != null) ? model.ireCondition : undefined,
        }
    }

    function mapJurisdictionAndStatusInfoArrayToPayloadArray(modelArray: JurisdictionAndStatusInfo[]): JurisdictionAndStatusInfoPayload[] {
        let payloadArray: JurisdictionAndStatusInfoPayload[] = [];
        if (modelArray != null && modelArray.length > 0) {
            modelArray.forEach((j) => payloadArray.push(mapJurisdictionAndStatusInfoToPayload(j)));
        }
        return payloadArray;
    }

    return {...defaultUpdateVehicleRegistrationInfoPayload, 
        vin: model.vin,
        lastRegistrationJurisdiction: mapJurisdictionAndStatusInfoToPayload(model.lastRegistrationJurisdiction),
        previousRegistrationJurisdictions: mapJurisdictionAndStatusInfoArrayToPayloadArray(model.previousRegistrationJurisdictions),
        jurisdictionsNotResponding: model.jurisdictionsNotResponding
    };
}