import Button from 'react-bootstrap/Button';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useAccount,
} from '@azure/msal-react';
import Dropdown from 'react-bootstrap/Dropdown';
import { AccountInfo } from '@azure/msal-browser';
import { loginRequest, logoutRequest } from '../Auth/authConfig';
import { persistor } from '../../../store/store';

const SignInOutButton = () => {
  const { instance, accounts } = useMsal();
  let account: AccountInfo | null;
  account = useAccount(accounts[0] || {});
  
  function LogOut() {    
    persistor.purge();
    instance.logoutRedirect(logoutRequest);
  }

  return (
    <>
      <AuthenticatedTemplate>
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-user">
            Welcome {account?.name}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={LogOut}
            >
              Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Button
          variant="secondary"
          className="ml-auto"
          title="Sign In"
          onClick={() => {
            instance.loginRedirect(loginRequest);
          }}
        >
          Sign In
        </Button>
      </UnauthenticatedTemplate>
    </>
  );
};

export default SignInOutButton;
