export const APPINSIGHTSCLOUDROLE = "CrossCanadaVinSearch.UI";

// Trace
export const SUCCESSFULSIGNINTRACEMSG = 'Successful sign-in.';

// Events
export const SUCCESSFULCUSTOMERINFOENTEREDEVENT = "Successful customer info entered";
export const CUSTOMERINFOVALIDATIONFAILEDEVENT = "Customer info form validation failed";
export const VINDECODESUCCESSEVENT = "Vin decoded successfully";
export const MULTIVINDECODESUCCESSEVENT = "Multiple vins decoded successfully";
export const VINDECODEFAILEDEVENT = "Attempt to decode vin failed";
export const MULTIVINDECODEFAILEDEVENT = "Attempt to decode multiple vins failed";
export const VINVALIDATIONFAILEDEVENT = "Entered Vin failed validation";
export const VINMANUALDECODEEVENT = "Vin decode info was entered manually";