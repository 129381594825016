import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { inProgressOrderTableColumns } from "./InProgressOrdersTableFormatters";
import { paginationOptions } from "../../../properties/reactBootstrapTableProperties";
import { InProgressOrderSummary } from "../../../types/InProgressOrder";

export interface InProgressOrdersTableProps {
    data: InProgressOrderSummary[]
}

function InProgressOrdersTable({ data } : InProgressOrdersTableProps) {
    
    function noDataIndication() {
        return "There are no in-progress orders in the system.";
    }

    return(
        <BootstrapTable 
            id="in-progress-order-table" 
            classes="my-3"
            wrapperClasses="table-responsive-lg"
            bordered={false}
            striped={false}
            hover={true}
            keyField='orderId' 
            data={ data } 
            columns={ inProgressOrderTableColumns } 
            noDataIndication={noDataIndication}
            defaultSorted={[{ dataField: "creationDate", order: "asc" }]}
            defaultSortDirection="asc"
            bootstrap4={true}
            pagination={ paginationFactory(paginationOptions) }
        />
    )
}

export default InProgressOrdersTable;