import { Modal } from "react-bootstrap";

interface LoadingModalProps {
    show: boolean;
    content: JSX.Element;
}

function LoadingModal(props : LoadingModalProps) {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
      <Modal.Body>
        {props.content}
      </Modal.Body>
    </Modal>
  );
}

export default LoadingModal;