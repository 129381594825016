import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./axiosBaseQuery";
import { bffEndpoints } from "../../properties/appProperties";
import { InProgressOrderPayload, UpdateVehicleNotesPayload } from "../../types/apiPayloads/InProgressOrderPayload";
import { InProgressOrderSummaryResponse, InProgressOrderResponse } from "../../types/apiResponses/InProgressOrderResponse";
import { InProgressOrder, InProgressOrderSummary, defaultInProgressOrder } from "../../types/InProgressOrder";
import { mapInProgressOrderSummaryResponseToInternalModel, mapInProgressOrderResponseToInternalModel } from "../../mappers/InProgressOrderMappers";

export const inProgressOrderHandlingApi = createApi({
  reducerPath: "inProgressOrderHandlingApi",  
  baseQuery: axiosBaseQuery(),
  tagTypes: ['InProgressOrder', 'InProgressOrders'],
  endpoints: (builder) => ({
    createInProgressOrder: builder.mutation<string, InProgressOrderPayload>({
        query: (payload) => ({
            url: bffEndpoints.CREATE_IN_PROGRESS_ORDER,
            method: "POST",
            data: payload,
        }),
    }),
    updateInProgressOrder: builder.mutation<string, InProgressOrderPayload>({
        query: (payload) => ({
            url: bffEndpoints.UPDATE_IN_PROGRESS_ORDER,
            method: "POST",
            data: payload,
        }),
    }),
    updateVehicleNotes: builder.mutation<void, UpdateVehicleNotesPayload>({
            query: (body) => ({
                url: bffEndpoints.UPDATE_VEH_NOTES,
                method: 'POST',
                data: body,
            }),
        }),
    deleteInProgressOrder: builder.mutation<string, string>({
        query: (orderId) => ({
            url: `${bffEndpoints.DELETE_IN_PROGRESS_ORDER}/${orderId}`, 
            method: 'DELETE',
        }),
    }),
    getInProgressOrder: builder.query<InProgressOrder,string>({
        query: (orderId) => {
            return {
                url: `${bffEndpoints.GET_IN_PROGRESS_ORDER}/${orderId}`,      
                method: "GET",
            };
        },
        transformResponse: (response: InProgressOrderResponse) => {
            return (response != null) ? mapInProgressOrderResponseToInternalModel(response) : {...defaultInProgressOrder};
        },
        keepUnusedDataFor: 1,     // Don't pull results from cache. Should always call endpoint.
    }),
    getInProgressOrders: builder.query<InProgressOrderSummary[],void>({
      query: () => ({
        url: `${bffEndpoints.GET_IN_PROGRESS_ORDERS}`,      
        method: "GET",
      }),
      transformResponse: (response: InProgressOrderSummaryResponse[]) => {
        let inProgressOrderSummaryArray: InProgressOrderSummary[] = [];
        ((response != null) && (response.length > 0)) && response.map(o => inProgressOrderSummaryArray.push(mapInProgressOrderSummaryResponseToInternalModel(o)));
        return inProgressOrderSummaryArray;
      },
      keepUnusedDataFor: 1,     // Don't pull results from cache. Should always call endpoint.
    }),
  }),
})

export const { useCreateInProgressOrderMutation, useUpdateInProgressOrderMutation, useUpdateVehicleNotesMutation, useGetInProgressOrderQuery, useGetInProgressOrdersQuery, useDeleteInProgressOrderMutation } = inProgressOrderHandlingApi;
