
/** A machine-readable format for specifying errors in HTTP API responses based on https://tools.ietf.org/html/rfc7807. */
export interface ProblemDetails {
    /** A URI reference [RFC3986] that identifies the problem type. This specification encourages that, when
dereferenced, it provide human-readable documentation for the problem type
(e.g., using HTML [W3C.REC-html5-20141028]).  When this member is not present, its value is assumed to be
"about:blank". */
    type?: string | null;
    /** A short, human-readable summary of the problem type.It SHOULD NOT change from occurrence to occurrence
of the problem, except for purposes of localization(e.g., using proactive content negotiation;
see[RFC7231], Section 3.4). */
    title?: string | null;
    /** The HTTP status code([RFC7231], Section 6) generated by the origin server for this occurrence of the problem. */
    status?: number | null;
    /** A human-readable explanation specific to this occurrence of the problem. */
    detail?: string | null;
    /** A URI reference that identifies the specific occurrence of the problem.It may or may not yield further information if dereferenced. */
    instance?: string | null;
}

export interface ApplicationMessage {
    severity?: ApplicationMessageSeverityType;
    message?: string;
    relatedField?: string | null;
}

export enum ApplicationMessageSeverityType {
    Success = "Success",
    Information = "Information",
    Warning = "Warning",
    Error = "Error",
}

export interface ApplicationProblemDetails extends ProblemDetails {
    messages?: ApplicationMessage[];
}
