import { CompletedOrderSummary } from "./CompletedOrder";

export interface BatchOrderInfo {
    batchId: string;
    customerEmail: string;
    createdByEmployee: string;
    createdDate: string;
    lastUpdatedDate: string;
    orderIds: string[];
    status?: string;
    orderSummaries: CompletedOrderSummary[];
}


// === Defaults ======================================================
export const defaultBatchOrderInfo: BatchOrderInfo = {
    batchId: "",
    customerEmail: "",
    createdByEmployee: "",
    createdDate: "",
    lastUpdatedDate: "",
    orderIds: [],
    status:"",
    orderSummaries: []
}