import { useState, useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/query/react";
import BatchOrders from "./BatchOrders";
import BatchProperties from "./BatchProperties";
import { useGetBatchOrderQuery } from "../../../../store/APIs/batchHandlingApi";
import { isUndefinedNullOrWhiteSpace } from "../../../../utility/stringFunctions";

function BatchDetails(props) {    
    const [batchId, setBatchId] = useState<string>();
    const { 
        data: batchData,
        error: batchError,
        refetch: batchRefetch,
        isError: batchIsError,
        isFetching: batchIsFetching,
        isLoading: batchIsLoading,
        isSuccess: batchIsSuccess
    } = useGetBatchOrderQuery(batchId ?? skipToken);

    useEffect(() => {
        let id = props.location.state.batchId;
        if (!isUndefinedNullOrWhiteSpace(id)) {
            setBatchId(id);
        }
    }, []);

    return(
        <div id="batch-management" className="mb-5">
            <h2>Batch Details</h2>
            { batchIsFetching && <p>Retrieving batch details from server...</p> }
            { batchIsError && <p>There was a problem retrieving the batch details. Please try again or contact support.</p>}
            { (batchData && batchIsSuccess && !batchIsError) && 
                <>
                    <BatchProperties batch={batchData} />            
                    <hr className="mb-5"/>
                    <BatchOrders batch={batchData}/>
                </>
            }
        </div>
    );
}

export default BatchDetails;