import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import TableActionButton, { MenuOption } from "../../../components/TableActionButton";
import { useCreateBatchOrderMutation } from "../../../../store/APIs/batchHandlingApi";
import { uiPaths } from "../../../../properties/appProperties";
import { CompletedOrderSummary } from "../../../../types/CompletedOrder";
import { isUndefinedNullOrWhiteSpace } from "../../../../utility/stringFunctions";


interface OrderSearchActionButtonProps {
    row: CompletedOrderSummary;
}

function OrderSearchActionButton({ row } : OrderSearchActionButtonProps) {

    const history = useHistory();
    const [createBatchOrder, {
        data: createBatchData,
        error: createBatchError,
        isLoading: createBatchIsFetching,
        isSuccess: createBatchIsSuccess,
        isError: createBatchIsError
    }] = useCreateBatchOrderMutation();

    function disableBatchLink() : boolean {
        return (!isUndefinedNullOrWhiteSpace(row.batchId)) ? true : false;
    }

    function handleViewDetailsClick() {
        history.push({pathname: uiPaths.ORDER_RESULT, state: { orderId: row.orderId}});
    }

    function handleCreateBatchClick() {
        // ToDo: Call create batch item API endpoint.  Pass in order.orderId. Should retrieve Batch Id
        createBatchOrder([row.orderId])
        .unwrap()
        .then((resp) => {
           // Successful response should equal the new Batch Id.  Redirect to batch item details page and pass in the Batch Id
           toast.success("Successfully created Batch order.");
           history.push({pathname: uiPaths.BATCH_DETAILS, state: { batchId: resp.batchId}});
        })
        .catch((error) => {
            toast.error("An error was encountered when creating the Batch order. Try again or call support.");
        });
    }

    const menuOptions: MenuOption[] = [
        {menuText: "View Details", onClickFunction: handleViewDetailsClick, dataTestId: "viewDetails"},
        {menuText: "Create Batch", onClickFunction: handleCreateBatchClick, dataTestId: "createBatch", disabled: disableBatchLink()},
    ];

    return (
        <TableActionButton buttonId={`dropdown-button-drop-${row.orderId}`} menuOptions={menuOptions} />
    );
}

export default OrderSearchActionButton;