import { isAuthorized } from './authUtilities';
import { useIsAuthenticated } from '@azure/msal-react';
import { useGetAllPermissionsQuery } from '../../../store/APIs/permissionsApi';

interface AuthorizedParams {
  requiredPermissions: string[];
  redirectOnUnAuthorized?: Boolean;
  requireAll?: boolean;
  showNotAuthorized?: boolean;
  children: JSX.Element;
}
const Authorized = ({ requiredPermissions, children, requireAll = true, showNotAuthorized=true }: AuthorizedParams) => {
    const isAuthenticated = useIsAuthenticated();
    const { data: permissions, isLoading, isFetching } = useGetAllPermissionsQuery();

    return (
        <>
            {
                (isAuthenticated && isAuthorized(permissions??[], requiredPermissions, requireAll)) ? 
                    children :     
                    <div>      
                        {isLoading || isFetching || !showNotAuthorized ? "" : 
                            <>
                                <h3>Insufficient privileges</h3>
                                <p>You are not authorized to perform this function.</p>
                            </>
                        }
                    </div>
            }
        </>    
    );
 };

export default Authorized;
