import React, { useState } from "react";
import { isNullOrWhiteSpace } from "../../../../utility/stringFunctions";
import SearchForm, { SearchInputs } from './SearchForm';
import SearchResult from './SearchResult';

export interface FormErrors {
    formError?: string | undefined;
    emailError?: string | undefined;
}

export interface SearchProps {
    vin: string;
    name: string;
    email: string;
    startDate: string;
    endDate: string;
}

export const OrderSearch = () => {
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [showResult, setShowResult] = useState(false);
    const [searchProps, setSearchProps] = useState<SearchProps>();

    function handleSubmit(searchInputs: SearchInputs) {

        if (isNullOrWhiteSpace(searchInputs.startDate) || isNullOrWhiteSpace(searchInputs.endDate)) {
            setFormErrors({ formError: 'Please provide at least a start and end date for search criteria.' });
            return;
        } else {
            setFormErrors({ formError: undefined });
        }

        if (!formErrors.formError && !formErrors.emailError) {
            setShowResult(true);
            setSearchProps({
                vin: searchInputs.searchVin,
                name: searchInputs.customerName,
                email: searchInputs.customerEmail,
                startDate: searchInputs.startDate,
                endDate: searchInputs.endDate
            });
        }
    }
    
    return (
        <div>
            <SearchForm onSubmit={handleSubmit} formErrors={formErrors} setFormErrors={setFormErrors} />
            {formErrors.formError && <>
                <br />
                <div className="alert alert-danger" data-testid="orderSearchInvalidEmail">
                    {formErrors.formError}
                </div>
            </>}
            {showResult && searchProps &&
                <>
                    <hr />
                    <SearchResult
                        vin={searchProps.vin}
                        name={searchProps.name}
                        email={searchProps.email}
                        startDate={searchProps.startDate}
                        endDate={searchProps.endDate}
                    />
                </>
            }
        </div>
    )
}

export default OrderSearch;