export interface GetBatchOrdersPayload { 
    email:string;
    batchId: string;
    startDate: string;
    endDate: string 
}

export interface AddOrderToBatchInfoPayload
{
    batchId: string;
    orderIds:string[]
}

export interface RemoveOrderFromBatchInfoPayload
{
    batchId: string;
    orderIds:string[]
}

// === Defaults ======================================================
export const defaultGetBatchOrdersPayload: GetBatchOrdersPayload = {
    email: "",
    batchId: "",
    startDate: "",
    endDate: ""
}