import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./axiosBaseQuery";
import { bffEndpoints } from "../../properties/appProperties";
import { CompletedOrderResponse, CompletedOrderSummaryResponse } from "../../types/apiResponses/CompletedOrderResponse";
import { CreateCompletedOrderPayload } from "../../types/apiPayloads/CompletedOrderPayload";
import { CompletedOrder, CompletedOrderSummary } from "../../types/CompletedOrder";
import { mapCompletedOrderResponseToInternalType, mapCompletedOrderSummaryResponseToInternalType } from "../../mappers/CompletedOrderMappers";

export const completedOrderHandlingApi = createApi({
    reducerPath: "completedOrderHandlingApi",  
    baseQuery: axiosBaseQuery(),
    tagTypes: ['CompletedOrders'],
    endpoints: (builder) => ({
        getCompletedOrders: builder.query<CompletedOrderSummary[], { vin:string, name:string, email:string, startDate: string, endDate: string }>({
            query: (arg) => {
                const { vin, name, email, startDate, endDate } = arg;
                return {
                    url: bffEndpoints.SEARCH_COMPLETED_ORDERS,
                    method: 'GET',
                    params: { vin, name, email, startDate, endDate },
                };
            },
            transformResponse: (response: CompletedOrderSummaryResponse[]) => {
                let completedOrderSummaryArray: CompletedOrderSummary[] = [];
                ((response != null) && (response.length > 0)) && response.forEach(o => completedOrderSummaryArray.push(mapCompletedOrderSummaryResponseToInternalType(o)));
                return completedOrderSummaryArray;
            },
            keepUnusedDataFor: 1,     // Don't pull results from cache. Should always call endpoint.
        }),
        createCompletedOrder: builder.mutation<string, CreateCompletedOrderPayload>({
            query: (payload) => {
                return {
                    url: bffEndpoints.CREATE_COMPLETED_ORDER,
                    method: 'POST',
                    data: payload,
                };
            },
            invalidatesTags: [{ type: 'CompletedOrders', id: 'LIST' }],
        }),
        getCompletedOrder: builder.query<CompletedOrder,string>({
            query: (orderId) => ({
                url: `${bffEndpoints.GET_COMPLETED_ORDER}/${orderId}`,      
                method: "GET",
            }),
            transformResponse: (response: CompletedOrderResponse) => {
                return mapCompletedOrderResponseToInternalType(response);
            },
            keepUnusedDataFor: 1,     // Don't pull results from cache. Should always call endpoint.
        }),
    }),
})
export const { useGetCompletedOrdersQuery, useCreateCompletedOrderMutation, useGetCompletedOrderQuery } = completedOrderHandlingApi
