import InProgressActionButton from "./InProgressActionButton";
import { formatUTCDateStringToDateTimeLocal } from "../../../utility/dateFunctions";
import { InProgressOrderSummary } from "../../../types/InProgressOrder";

export const inProgressOrderTableColumns = [{
    dataField: 'creationDate',
    text: 'Order Date',
    formatter: dateFormatter,
    sort: true,
},{
    dataField: 'name',
    text: 'Customer Name',
    sort: true,
}, {
    dataField: 'email',
    text: 'Email',
    sort: true,
}, {
    dataField: 'vinList',
    text: 'Vins',
    formatter: vinListFormatter,
    headerStyle: { width: "25%" }
},
{
    dataField: 'employeeName',
    text: 'Created By',
    sort: true,
},
{
    dataField: "actions",
    text: "Actions",
    isDummyField: true,
    sort: false,
    classes: "actions",
    headerClasses: "actions",
    formatter: actionsFormatter,
    headerStyle: { width: "8%" }
  },
];

function dateFormatter(cell: string) {
    return formatUTCDateStringToDateTimeLocal(cell);
}

function actionsFormatter (_cell: string, row: InProgressOrderSummary) {
    return <InProgressActionButton row={row} />
}

function vinListFormatter(cell: string[]) {
    return (cell != null && cell.length > 0) ?
        <ul>{cell.map(vin => <li key={vin}>{vin}</li>)}</ul> :
        <>&nbsp;</>
}