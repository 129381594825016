import { ColumnDescription } from "react-bootstrap-table-next";
import BatchDetailActionButton from "./BatchDetailActionButton";
import { formatUTCDateStringToDateTimeLocal } from "../../../../utility/dateFunctions";
import { CompletedOrderSummary } from "../../../../types/CompletedOrder";

export const batchDetailColumns: ColumnDescription[] = [{
    dataField: 'orderDate',
    text: 'Order Date',
    formatter: dateFormatter,
    sort: true,
},{
    dataField: 'customerName',
    text: 'Customer Name',
    sort: true,
},{
    dataField: 'df1',   // dummy value
    isDummyField: true,
    text: 'Vins',
    formatter: vinListFormatter,
},{
    dataField: 'actions',
    text: 'Actions',
    isDummyField: true,
    sort: false,
    formatter: actionsFormatter,
    headerStyle: { width: "8%" },
    csvExport: false,
}];

function dateFormatter(cell: string) {
    return formatUTCDateStringToDateTimeLocal(cell);
}

function vinListFormatter(cell: string, row: CompletedOrderSummary) {
    return (row.vins != null && row.vins.length > 0) ?
        <ul>{row.vins.map(v => <li key={v}>{v}</li>)}</ul> :
        <>&nbsp;</>;
}

function actionsFormatter (cell: string, row: CompletedOrderSummary) {
    return <BatchDetailActionButton row={row} />
};
