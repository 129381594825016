import { Dropdown } from "react-bootstrap";
import { MenuOption } from ".";

interface ActionMenuProps {
    menuOptions: MenuOption[]
}

function ActionMenu({ menuOptions } : ActionMenuProps) {
    return(
        <Dropdown.Menu data-testid="actionMenu">
            { (menuOptions != null && menuOptions.length > 0) &&
                menuOptions.map((o, i) =>
                    <Dropdown.Item key={i} data-testid={o.dataTestId} as="button" disabled={o.disabled} onClick={o.onClickFunction}>{o.menuText}</Dropdown.Item> )
            }
        </Dropdown.Menu>
    );
}

export default ActionMenu;
