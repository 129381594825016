import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./axiosBaseQuery";
import { bffEndpoints } from "../../properties/appProperties";
import { BatchOrderInfo, defaultBatchOrderInfo } from "../../types/BatchOrder";
import { AddOrderToBatchInfoPayload, RemoveOrderFromBatchInfoPayload, GetBatchOrdersPayload } from "../../types/apiPayloads/BatchOrderPayload";
import { BatchOrderInfoResponse } from "../../types/apiResponses/BatchOrderResponse";
import { mapBatchOrderInfoResponseToBatchOrderInfo } from "../../mappers/BatchOrderMappers";

export const batchHandlingApi = createApi({
    reducerPath: "batchHandlingApi",  
    baseQuery: axiosBaseQuery(),
    tagTypes: ['BatchOrders'],
    endpoints: (builder) => ({
        createBatchOrder: builder.mutation<BatchOrderInfo, string[]>({
            query: (payload) => ({
                url: bffEndpoints.CREATE_BATCH_ORDER,
                method: "POST",
                data: payload,
            }),
            transformResponse: (response: BatchOrderInfoResponse) => {
                return (response != null) ? mapBatchOrderInfoResponseToBatchOrderInfo(response) : {...defaultBatchOrderInfo};
            },
        }),
        getBatchOrders: builder.query<BatchOrderInfo[], GetBatchOrdersPayload>({
            query: (params) => {
                const { email, batchId, startDate, endDate } = params;
                return {
                    url: bffEndpoints.SEARCH_BATCH_ORDERS,
                    method: 'GET',
                    params: { batchId, email, startDate, endDate },
                };
            },
            transformResponse: (response: BatchOrderInfoResponse[]) => {
                let batchOrderInfoArray: BatchOrderInfo[] = [];
                ((response != null) && (response.length > 0)) && response.forEach(b => batchOrderInfoArray.push(mapBatchOrderInfoResponseToBatchOrderInfo(b)));
                return batchOrderInfoArray;
            },
            keepUnusedDataFor: 1,     // Don't pull results from cache. Should always call endpoint.
        }),
        getBatchOrder: builder.query<BatchOrderInfo, string>({
            query: (batchId) => {
                return {
                    url: `${bffEndpoints.GET_BATCH_ORDER}/${batchId}`,
                    method: 'GET',
                };
            },      
            transformResponse: (response: BatchOrderInfoResponse) => {
                return (response != null) ? mapBatchOrderInfoResponseToBatchOrderInfo(response) : {...defaultBatchOrderInfo};
            },     
        }),
        deleteBatchOrder: builder.mutation<string, string>({
            query: (batchId) => ({
                url: `${bffEndpoints.DELETE_BATCH_ORDER}/${batchId}`,
                method: "DELETE",
            }),
        }),
        addOrdersToBatchOrder: builder.mutation<string, AddOrderToBatchInfoPayload>({
            query: (payload) => ({
                url: `${bffEndpoints.ADD_COMPLETED_ORDERS_TO_BATCH_ORDER}`,
                method: "POST",
                data:payload,
            }),
        }),
        removeOrdersFromBatchOrder: builder.mutation<string, RemoveOrderFromBatchInfoPayload>({
            query: (payload) => ({
                url: `${bffEndpoints.REMOVE_COMPLETED_ORDERS_FROM_BATCH_ORDER}`,
                method: "POST",
                data:payload,
            }),
        }),
    }),
})
export const { useCreateBatchOrderMutation, useGetBatchOrdersQuery, useGetBatchOrderQuery, useDeleteBatchOrderMutation, useAddOrdersToBatchOrderMutation, useRemoveOrdersFromBatchOrderMutation } = batchHandlingApi;