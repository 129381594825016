import { BatchOrderInfo } from "../../../../types/BatchOrder";
import { formatUTCDateStringToDateTimeLocal } from "../../../../utility/dateFunctions";

interface BatchPropertiesProps {
    batch?:BatchOrderInfo
}

function BatchProperties({batch}:BatchPropertiesProps) {    
    return(
        <form >            
            <div className="form-row">             
                <div className="form-group col-6">
                    <div className="row mb-1">
                        <div className="col-2"><strong>Batch Id</strong></div>
                        <div className="col-10">{batch?.batchId}</div>
                    </div>                        
                </div>      
                <div className="form-group col-6">
                    <div className="row mb-1">
                        <div className="col-3"><strong>Email</strong></div>
                        <div className="col-9">{batch?.customerEmail}</div>
                    </div>                        
                </div>                
            </div>
            <div className="form-row justify-content-between">
                <div className="form-group col-6">                                        
                    <div className="row mb-1">
                        <div className="col-2"><strong>Created Date</strong></div>
                        <div className="col-10">{formatUTCDateStringToDateTimeLocal(batch?.createdDate??"")}</div>
                    </div>                        
                </div>
                <div className="form-group col-6">                                        
                    <div className="row mb-1">
                        <div className="col-3"><strong>Last Updated Date</strong></div>
                        <div className="col-9">{formatUTCDateStringToDateTimeLocal(batch?.lastUpdatedDate??"")}</div>
                    </div>                        
                </div>
            </div>
        </form>
    );
}

export default BatchProperties;