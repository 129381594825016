import { CompletedOrderResponse, VinInfoResponse, VehicleRegistrationResponse, JurisdictionAndStatusInfoResponse, InspectionInfoResponse, CompletedOrderSummaryResponse } from "../types/apiResponses/CompletedOrderResponse";
import { CompletedOrder, VinInfo, VehicleRegistration, JurisdictionAndStatusInfo, defaultCompletedOrder, defaultVinInfo, defaultVehicleRegistration, defaultJurisdictionAndStatusInfo, InspectionInfo, defaultInspectionInfo, CompletedOrderSummary, defaultCompletedOrderSummary} from "../types/CompletedOrder";

export function mapCompletedOrderResponseToInternalType (response: CompletedOrderResponse) : CompletedOrder {
    
    function mapInspectionInfoResponseToInternalType(response: InspectionInfoResponse) : InspectionInfo {
        return {...defaultInspectionInfo,
            vehicleRegisterable: response.vehicleRegisterable,
            inspectionRequired: response.inspectionRequired,
            importantInformation: response.importantInformation,
        }
    }

    function mapJurisdictionAndStatusInfoResponseToInternalType(response: JurisdictionAndStatusInfoResponse) : JurisdictionAndStatusInfo {
        return {...defaultJurisdictionAndStatusInfo,
            jurisdiction: response.jurisdiction,
            skStatus: (response.skStatus) && response.skStatus,
            ireLocation: (response.ireLocation) && response.ireLocation,
            ireCondition: (response.ireCondition) && response.ireCondition
        }; 
    }

    function mapJurisdictionAndStatusInfoResponseArrayToInternalTypeArray (responseArray: JurisdictionAndStatusInfoResponse[]) : JurisdictionAndStatusInfo[] {
        let jurisdictionArray: JurisdictionAndStatusInfo[] = [];
        responseArray.forEach((j) => jurisdictionArray.push(mapJurisdictionAndStatusInfoResponseToInternalType(j)));
        return jurisdictionArray;
    }

    function mapVehicleRegistrationResponseToInternalType(response: VehicleRegistrationResponse) : VehicleRegistration {
        return {...defaultVehicleRegistration,
            lastRegistrationJurisdiction: mapJurisdictionAndStatusInfoResponseToInternalType(response.lastRegistrationJurisdiction),
            previousRegistrationJurisdictions: mapJurisdictionAndStatusInfoResponseArrayToInternalTypeArray(response.previousRegistrationJurisdictions),
            jurisdictionsNotResponding: response.jurisdictionsNotResponding,
            inspectionInfo: (response.inspectionInfo) && mapInspectionInfoResponseToInternalType(response.inspectionInfo),
        };
    }

    function mapVinInfoResponseToInternalType(response: VinInfoResponse) : VinInfo {
        return {...defaultVinInfo,
            orderId : response.orderId,
            vinSearchDate: response.vinSearchDate,
            vin: response.vin,
            bodyStyle: response.bodyStyle,
            year: response.year,
            make: response.make,
            model: response.model,
            skSearchResponse: response.skSearchResponse,
            vehicleRegistration: mapVehicleRegistrationResponseToInternalType(response.vehicleRegistration),
            notes: response.notes,
        };
    }

    function mapVinInfoResponseArrayToInternalTypeArray (responseArray: VinInfoResponse[]) : VinInfo[] {
        let vinInfoArray: VinInfo[] = [];
        responseArray.forEach((v) => vinInfoArray.push(mapVinInfoResponseToInternalType(v)));
        return vinInfoArray;
    }

    return {...defaultCompletedOrder,
        orderId: response.orderId,
        vinSearchDate: response.vinSearchDate,
        vinSearches: mapVinInfoResponseArrayToInternalTypeArray(response.vinSearches),
        customerName: response.customerName,
        email: response.email,
        employeeName: response.employeeName,
        employeeEmail: response.employeeEmail,
        batchId: response.batchId
    };
}

export function mapCompletedOrderSummaryResponseArrayToInternalTypeArray (respArray: CompletedOrderSummaryResponse[]) : CompletedOrderSummary[] {
    let completedOrderSummaryArray: CompletedOrderSummary[] = [];
    respArray.forEach((o) => completedOrderSummaryArray.push(mapCompletedOrderSummaryResponseToInternalType(o)));
    return completedOrderSummaryArray;
}

export function mapCompletedOrderSummaryResponseToInternalType (resp: CompletedOrderSummaryResponse) : CompletedOrderSummary {
    return {...defaultCompletedOrderSummary,
        orderId: resp.orderId,
        orderDate: resp.orderDate,
        customerName: resp.customerName,
        email: resp.email,
        vins: resp.vins,
        employeeName: resp.employeeName,
        batchId: resp.batchId,
    };
}