import {Card, Accordion} from "react-bootstrap";
import VehicleData from "./VehicleData"
import {VehicleInfo} from "../../../types/VehicleInfo";
import { formatMakeAndModelString } from "../../../utility/formatVehicleMakeModel";

interface VehicleDetailsProps {
    index: string;
    vehicleInfo: VehicleInfo;
}

export function VehicleDetails( { index, vehicleInfo } : VehicleDetailsProps ) {
    return (        
        <Card className="vehicle-info-summary break-after">
            <Accordion.Toggle as={Card.Header} eventKey={index} className="d-print-none">
                <strong>{vehicleInfo.vin}</strong> - {vehicleInfo.year} { formatMakeAndModelString(vehicleInfo.make, vehicleInfo.model) }
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index} className="vehicle-info-summary-body">
                <Card.Body>
                    { (!vehicleInfo.vin || vehicleInfo.vin.match(/^\s*$/)) ? <p>Error getting Vin Info</p> : <VehicleData vehicleInfo={vehicleInfo} /> }
                </Card.Body>
            </Accordion.Collapse>
        </Card>  
    );
}

export default VehicleDetails;
