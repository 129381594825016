export interface InProgressOrderPayload {
	orderId?: string;
	name: string;
	email: string;
	vinList: string[];
	employeeName?: string;
	employeeEmail?: string | never[];
}

export interface UpdateVehicleRegistrationInfoPayload {
	orderId: string;
	vin: string;
	lastRegistrationJurisdiction: JurisdictionAndStatusInfoPayload;
	previousRegistrationJurisdictions: JurisdictionAndStatusInfoPayload[];
	jurisdictionsNotResponding: string[];
}

export interface JurisdictionAndStatusInfoPayload {
	jurisdiction: string;
	skStatus: string | undefined;
	ireLocation: string | undefined;
	ireCondition: string | undefined;
}

export interface UpdateVehicleNotesPayload {
	orderId: string;
	vin: string;
	notes: string;
}

export interface UpdateVehicleRegistrationInspectionDetailsPayload {
	orderId: string;
	vin: string;
	vehicleRegisterable: boolean;
	inspectionRequired: string;
	inspectionDetailsUnsaved: boolean;
}

// === Defaults ======================================================
export const defaultJurisdictionAndStatusInfoPayload: JurisdictionAndStatusInfoPayload =
	{
		jurisdiction: "",
		skStatus: undefined,
		ireLocation: undefined,
		ireCondition: undefined,
	};

export const defaultUpdateVehicleRegistrationInfoPayload: UpdateVehicleRegistrationInfoPayload =
	{
		orderId: "",
		vin: "",
		lastRegistrationJurisdiction: defaultJurisdictionAndStatusInfoPayload,
		previousRegistrationJurisdictions: [],
		jurisdictionsNotResponding: [],
	};
