import { useState } from 'react';
import { Modal, ModalBody } from "react-bootstrap";

interface VinSearchManualEntryProps {
    show: boolean;
    onHide : Function;
    modalSubmit: Function;
}

export interface VinSearchManualEntryInputs {
    make: string;
    model: string,
    year: string;
    bodyStyle: string;
}

const VinSearchManualEntry = ({show, onHide, modalSubmit}: VinSearchManualEntryProps) => {
    const [formInputs, setFormInputs] = useState<VinSearchManualEntryInputs>({
        make: "",
        model: "",
        year: "",
        bodyStyle: ""
    });
    const [formError, setFormError] = useState<string>('');

    function formInputsValid() {
        if(!formInputs.make || !formInputs.model || !formInputs.year || !formInputs.bodyStyle){
            setFormError('Please enter all information.');
            return false;
        }
        return true;
    }

    function handleInputChange(e: React.FormEvent<HTMLInputElement>) {
        setFormInputs({ ...formInputs, [e.currentTarget.id]: e.currentTarget.value});
    }

    function handleFormSubmit(e: React.SyntheticEvent) {
        e.preventDefault();
        if(formInputsValid()) {
            modalSubmit(formInputs);
        }
    }

    return(
        <Modal size="lg" show={show} onHide={() => onHide(formInputs)} centered dialogClassName="vin-search-modal">
            <Modal.Header closeButton>
                <Modal.Title>Enter Vehicle Information</Modal.Title>
            </Modal.Header>
            <ModalBody>
                <form onSubmit={handleFormSubmit} noValidate>
                <div className="form-row">
                    <div className="form-group col-lg-2">
                        <label htmlFor="year">Year</label>
                        <input type="text" className="form-control" id="year" value={formInputs.year} onChange={handleInputChange} />
                    </div>
                    <div className="form-group col-lg-5">
                        <label htmlFor="make">Make</label>
                        <input type="text" className="form-control" id="make" value={formInputs.make} onChange={handleInputChange} />
                    </div>
                    <div className="form-group col-lg-5">
                        <label htmlFor="model">Model</label>
                        <input type="text" className="form-control" id="model" value={formInputs.model} onChange={handleInputChange} />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="bodyStyle">Body Style</label>
                        <input type="text" className="form-control" id="bodyStyle" value={formInputs.bodyStyle} onChange={handleInputChange} />
                    </div>
                </div>
                <small className="form-text" style={{color: "red"}}>
                { formError? formError : <>&nbsp;</> }
                </small>
                <p className="text-right">
                    <button type="button" className="btn btn-outline-secondary" onClick={() => onHide(formInputs)}>Cancel</button>
                    <button type="submit" className="btn btn-primary ml-2">Save</button>
                </p>
            </form>
            </ModalBody>
        </Modal>        
    );
}

export default VinSearchManualEntry;