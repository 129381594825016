import { createApi } from "@reduxjs/toolkit/query/react";
import {  axiosBaseQuery} from "./axiosBaseQuery"; 
import { bffEndpoints } from "../../properties/appProperties";
import { BasicVehicleInfoResponse } from "../../types/apiResponses/BasicVehicleInfoResponse";
import { GetBasicVehicleInfoPayload, GetBasicVehiclesInfoPayload, SetBasicVehicleInfoPayload } from "../../types/apiPayloads/BasicVehicleInfoPayload";
import { mapBasicVehicleInfoResponseToBasicVehicleInfo, mapBasicVehiclesInfoResponseToBasicVehiclesInfo } from "../../mappers/VehicleInfoMappers";
import { BasicVehicleInfo, defaultBasicVehicleInfo } from "../../types/VehicleInfo";

export const basicVehicleInfoApi = createApi({
  reducerPath: "basicVehicleInfoApi",  
  baseQuery: axiosBaseQuery(),
  tagTypes: ['BasicVehicleInfo'],
  endpoints: (builder) => ({
    getBasicVehicleInfo: builder.mutation<BasicVehicleInfo, GetBasicVehicleInfoPayload>({
        query: (payload) => ({
            url: bffEndpoints.GET_BASIC_VEHICLE_INFO,
            method: "POST",
            data: payload,
        }),
        transformResponse: (response: BasicVehicleInfoResponse) => {
          return (response != null) ? mapBasicVehicleInfoResponseToBasicVehicleInfo(response) : {...defaultBasicVehicleInfo};
        },
    }),
    getBasicVehiclesInfo: builder.mutation<BasicVehicleInfo[], GetBasicVehiclesInfoPayload>({
      query: (payload) => ({
          url: bffEndpoints.GET_BASIC_VEHICLE_INFO_LIST,
          method: "POST",
          data: payload,
      }),
      transformResponse: (response: BasicVehicleInfoResponse[]) => {
        return (response != null) ? mapBasicVehiclesInfoResponseToBasicVehiclesInfo(response) : [{...defaultBasicVehicleInfo}];
      },
    }),
    setBasicVehicleInfo: builder.mutation<BasicVehicleInfo, SetBasicVehicleInfoPayload>({
      query: (payload) => ({
          url: bffEndpoints.SET_BASIC_VEHICLE_INFO,
          method: "POST",
          data: payload,
      }),
      transformResponse: (response: BasicVehicleInfoResponse) => {
        return (response != null) ? mapBasicVehicleInfoResponseToBasicVehicleInfo(response) : {...defaultBasicVehicleInfo};
      },
  }),
  }),
})

export const { useGetBasicVehicleInfoMutation, useGetBasicVehiclesInfoMutation, useSetBasicVehicleInfoMutation } = basicVehicleInfoApi
