import { toast } from 'react-toastify';
import { store } from "../../../store/store";
import { updateOrderInfo } from "../../../store/transaction/transactionSlice";
import { InProgressOrderPayload } from "../../../types/apiPayloads/InProgressOrderPayload";
import { CreateCompletedOrderPayload } from "../../../types/apiPayloads/CompletedOrderPayload";
import { OrderInfo } from "../../../types/OrderInfo";
import { uiPaths } from "../../../properties/appProperties";

export function createNewOrder(createOrder: any, forwardOneStep: any, order: InProgressOrderPayload) {
    createOrder(order)
    .unwrap()
    .then((resp) => {
        store.dispatch(updateOrderInfo({ orderId: JSON.parse(resp).id, creationDate: JSON.parse(resp).creationDate, 
                                    vins: [...order.vinList], employeeName: order.employeeName, employeeEmail: order.employeeEmail }));
        forwardOneStep();
    })
    .catch((error) => {
        toast.error("An error was encountered when creating the order. Try again or call support.");
    });
}

export function updateExistingOrder(updateOrder: any, forwardOneStep: any, order: InProgressOrderPayload, orderInfo: OrderInfo) {
    updateOrder(order)
    .unwrap()
    .then((resp) => {
        store.dispatch(updateOrderInfo(
            {   orderId: orderInfo.orderId, 
                creationDate: orderInfo.creationDate, 
                vins: [...order.vinList], 
                employeeName: order.employeeName, 
                employeeEmail: order.employeeEmail 
            }));
        forwardOneStep();
    })
    .catch((error) => {
        toast.error("An error was encountered when updating the order. Try again or call support.");
    });
}

export function finalizeOrder(createCompletedOrder: any, history: any, setFinishing: any, orderId: string) {
    const payload: CreateCompletedOrderPayload = {
        orderId: orderId,
    }

    createCompletedOrder(payload)
        .unwrap()
        .then((resp) => {
            toast.success("Order completed successfully.");
            history.push({pathname: uiPaths.ORDER_RESULT, state: { orderId: orderId, hideBack: true }});
        })
        .catch((error) => {
            setFinishing(false);
            toast.error("An error was encountered while completing the order. Try again or call support.");
        });
}