export interface EnvironmentVariableConfig {
REACT_APP_BASEURL:string;
REACT_APP_BFF_API_BASEURL:string;
REACT_APP_ENVIRONMENT:string;
REACT_APP_APPINSIGHTS_INSTR_KEY:string;
REACT_APP_MSAL_CLIENT_ID:string;
REACT_APP_MSAL_TENANT_ID:string;
REACT_APP_MSAL_SIGNUPSIGNIN_POLICY:string;
REACT_APP_MSAL_FORGOTPASSWORD_POLICY:string;
REACT_APP_MSAL_EDITPROFILE_POLICY:string;
REACT_APP_MSAL_POLICY_AUTHORITY_ROOT:string;
REACT_APP_MSAL_B2C_AUTHORITYDOMAIN:string;
REACT_APP_BFF_API_SCOPES:string;
}
export const devEnvironmentVariableConfig: EnvironmentVariableConfig = {
  REACT_APP_BASEURL:process.env.REACT_APP_BASEURL as string,
  REACT_APP_BFF_API_BASEURL: process.env.REACT_APP_BFF_API_BASEURL as string,
  REACT_APP_ENVIRONMENT:process.env.REACT_APP_ENVIRONMENT as string,
  REACT_APP_APPINSIGHTS_INSTR_KEY: process.env.REACT_APP_APPINSIGHTS_INSTR_KEY as string,
  REACT_APP_MSAL_CLIENT_ID: process.env.REACT_APP_MSAL_CLIENT_ID as string,
  REACT_APP_MSAL_TENANT_ID: process.env.REACT_APP_MSAL_TENANT_ID as string,
  REACT_APP_MSAL_SIGNUPSIGNIN_POLICY:process.env.REACT_APP_MSAL_SIGNUPSIGNIN_POLICY as string,
  REACT_APP_MSAL_FORGOTPASSWORD_POLICY: process.env.REACT_APP_MSAL_FORGOTPASSWORD_POLICY as string,
  REACT_APP_MSAL_EDITPROFILE_POLICY:process.env.REACT_APP_MSAL_EDITPROFILE_POLICY as string,
  REACT_APP_MSAL_POLICY_AUTHORITY_ROOT:process.env.REACT_APP_MSAL_POLICY_AUTHORITY_ROOT as string,
  REACT_APP_MSAL_B2C_AUTHORITYDOMAIN:process.env.REACT_APP_MSAL_B2C_AUTHORITYDOMAIN as string,
  REACT_APP_BFF_API_SCOPES:process.env.REACT_APP_BFF_API_SCOPES as string,
};