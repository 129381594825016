import {
  PublicClientApplication,
  EventType,
  AuthenticationResult,
} from '@azure/msal-browser';
import { getConfig, loginRequest } from './authConfig';
import { SUCCESSFULSIGNINTRACEMSG } from "../../../properties/appInsightsProperties";
import { appInsights } from '../../../AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { auditLogApi } from "../../../store/APIs/auditLogApi";
import { store } from '../../../store/store';
import { useIsAuthenticated } from '@azure/msal-react';

export var msalInstance: PublicClientApplication = new PublicClientApplication(
  getConfig(),
);

export const initializeMsalInstance = () => {    
  msalInstance = new PublicClientApplication(getConfig());
  msalInstance.addEventCallback((event) => {
    const authResult = event.payload as AuthenticationResult;
    if (event.eventType === EventType.LOGIN_SUCCESS && authResult.account) {
      const account = authResult.account;
      msalInstance.setActiveAccount(account);
      if (appInsights !== undefined) {
        appInsights.setAuthenticatedUserContext(account.username);
        appInsights.trackTrace(
          {
            message: SUCCESSFULSIGNINTRACEMSG,
            severityLevel: SeverityLevel.Information,
          },
          { user: account.username },
        );
      }      
 
      //TODO - need to find a way to add LogIn event. Find correct place to put in this code.
        //store.dispatch(auditLogApi.endpoints.createLogInAuditLogEntry.initiate());  
 
      
    }

    if (event.eventType === EventType.LOGIN_FAILURE) {
      // Catch the cancelled partially self asserted erorr (partial forgotten password or partial finished sign up )
      // and redirect back to login
      if (event.error?.message.includes('AADB2C90091')) {
        msalInstance.loginRedirect(loginRequest);
      }
    }
  });
  console.log(
    'msal init. Config is typically not loaded first time this happens',
  );
};


