import { useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import {
	RegistrationStatusTable,
	RegistrationStatusTableDisplayTypes,
} from "./RegistrationStatusTable";
import SingleSelectDropdown from "../../components/Common/SingleSelectDropdown";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../store/ReduxToolkitSamples/hooks";
import { updateRegistration } from "../../../store/VinsForSearch/VinsForSearchSlice";
import { selectOrderInfo } from "../../../store/transaction/transactionSlice";
import { useUpdateVehicleRegistrationInspectionDetailsMutation } from "../../../store/APIs/vehicleRegistrationInfoApi";
import {
	InspectionInfo,
	VehicleRegistrationBasicInfo,
	defaultInspectionInfo,
	JurisdictionCode,
} from "../../../types/VehicleRegistrationInfo";
import { VehicleInfo, skInspectionTypes } from "../../../types/VehicleInfo";
import { isNullOrWhiteSpace } from "../../../utility/stringFunctions";
import { UpdateVehicleRegistrationInspectionDetailsPayload } from "../../../types/apiPayloads/InProgressOrderPayload";

interface RegistrationAndInspectionDetailsProps {
	vehicleInfo: VehicleInfo;
	editable?: boolean;
}

export function RegistrationAndInspectionDetails({
	vehicleInfo,
	editable,
}: RegistrationAndInspectionDetailsProps) {
	const [resultFormValues, setResultFormValues] = useState({
		eligibleForSkReg: vehicleInfo.vehicleRegistration.inspectionInfo
			?.vehicleRegisterable
			? "Yes"
			: "No",
		requiredSkInspection:
			vehicleInfo.vehicleRegistration.inspectionInfo?.inspectionRequired ||
			"None",
		importantInfo:
			vehicleInfo.vehicleRegistration.inspectionInfo?.importantInformation ||
			[],
	});
	const [vehicleRegistrationInfo, setVehicleRegistrationInfo] =
		useState<VehicleRegistrationBasicInfo>({
			...vehicleInfo.vehicleRegistration,
			vin: vehicleInfo.vin,
		});
	const [vehicleInspectionInfo, setVehicleInspectionInfo] =
		useState<InspectionInfo>(
			determineInspectionInfoState(
				vehicleInfo.vehicleRegistration.inspectionInfo
			)
		);
	const [
		updateVehicleRegInspDetails,
		{
			data: updateVehicleRegInspDetailsData,
			error: updateVehicleRegInspDetailsError,
			isLoading: updateVehicleRegInspDetailsIsFetching,
			isSuccess: updateVehicleRegInspDetailsIsSuccess,
			isError: updateVehicleRegInspDetailsIsError,
		},
	] = useUpdateVehicleRegistrationInspectionDetailsMutation();
	const dispatch = useAppDispatch();
	const orderInfo = useAppSelector(selectOrderInfo);

	const boldPartOfText = (boldingText, targetText) => {
		if (!targetText) {
			return "";
		}
		const indexOfText = targetText.indexOf(boldingText);

		if (indexOfText === -1) {
			return targetText;
		}
		const endIndexOfText = indexOfText + boldingText.length;

		return (
			<>
				{`${targetText.slice(0, indexOfText)}`}
				<b>{targetText.slice(indexOfText, endIndexOfText)}</b>
				{targetText.slice(endIndexOfText)}
			</>
		);
	};

	function handleOnChange(
		event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) {
		const elementName = event.target.name;
		const elementValue = event.target.value;
		const updatedInspectionInfo: InspectionInfo = { ...vehicleInspectionInfo };

		if (elementName === "eligibleForSkReg")
			elementValue === "Yes"
				? (updatedInspectionInfo.vehicleRegisterable = true)
				: (updatedInspectionInfo.vehicleRegisterable = false);
		if (elementName === "requiredSkInspection")
			updatedInspectionInfo.inspectionRequired = elementValue;

		setResultFormValues({ ...resultFormValues, [elementName]: elementValue });
		setVehicleInspectionInfo({ ...updatedInspectionInfo });

		const updatedRegInfo: VehicleRegistrationBasicInfo = {
			...vehicleRegistrationInfo,
			inspectionDetailsUnsaved: true,
		};
		updatedRegInfo.inspectionInfo = { ...updatedInspectionInfo };
		dispatch(updateRegistration({ ...updatedRegInfo }));
		setVehicleRegistrationInfo(updatedRegInfo);
	}

	function handleSaveChanges() {
		if (!isNullOrWhiteSpace(orderInfo.orderId)) {
			const payload: UpdateVehicleRegistrationInspectionDetailsPayload = {
				orderId: orderInfo.orderId,
				vin: vehicleInfo.vin,
				vehicleRegisterable: new RegExp(/yes/i).test(
					resultFormValues.eligibleForSkReg
				)
					? true
					: false,
				inspectionRequired: resultFormValues.requiredSkInspection,
				inspectionDetailsUnsaved: false,
			};
			setVehicleRegistrationInfo({
				...vehicleRegistrationInfo,
				inspectionDetailsUnsaved: false,
			});
			updateVehicleRegInspDetails(payload)
				.unwrap()
				.then((resp) => {
					dispatch(updateRegistration({ ...vehicleRegistrationInfo }));
					toast.success(
						"Saved registration and inspection details successfully."
					);
				})
				.catch((error) => {
					toast.error(
						"An error was encountered when saving the registration and inspection details. Try again or call support."
					);
				});
		}
	}

	function determineInspectionInfoState(inspectionInfo) {
		return inspectionInfo ? { ...inspectionInfo } : defaultInspectionInfo;
	}

	function formatImportantVehicleInfo(infoList: string[]) {
		return !infoList || infoList.length === 0 ? (
			<p>No information to display.</p>
		) : (
			<>
				<p>This vehicle:</p>
				<ul aria-label="important-information-list">
					{infoList.map((info, index) => (
						<li key={index}>
							{boldPartOfText("Required Saskatchewan inspection(s)", info)}
						</li>
					))}
				</ul>
			</>
		);
	}

	function formatJurisdictionNotResponding(
		notRespondingList: JurisdictionCode[]
	) {
		return vehicleRegistrationInfo.jurisdictionsNotResponding.length === 0 ? (
			<p>All provinces responded.</p>
		) : (
			<>
				<p>
					The jurisdiction(s) below were unable to search for vehicle
					information:
				</p>
				<ul>
					{vehicleRegistrationInfo.jurisdictionsNotResponding.map((prov) => (
						<li key={prov}>{prov}</li>
					))}
				</ul>
			</>
		);
	}

	return (
		<div className="vin-registration-and-inspection-details mb-5">
			<h4 className="mb-3">Registration and inspection details</h4>
			<hr className="mb-4" />

			<div className="row mb-5">
				<div className="col">
					<h5>Eligible for Saskatchewan registration:</h5>
					{editable ? (
						<SingleSelectDropdown
							inputName="eligibleForSkReg"
							handleOnChange={handleOnChange}
							availableOptions={["Yes", "No"]}
							selectedOption={resultFormValues.eligibleForSkReg}
						/>
					) : (
						<p>{resultFormValues.eligibleForSkReg}</p>
					)}
				</div>
				<div className="col">
					<h5>Required Saskatchewan inspection(s):</h5>
					{editable ? (
						<SingleSelectDropdown
							inputName="requiredSkInspection"
							handleOnChange={handleOnChange}
							availableOptions={skInspectionTypes}
							selectedOption={resultFormValues.requiredSkInspection}
						/>
					) : (
						<p>{resultFormValues.requiredSkInspection}</p>
					)}
				</div>
			</div>
			{editable && (
				<p className="text-right d-print-none mb-5">
					<Button
						size="sm"
						variant="primary"
						onClick={handleSaveChanges}
						disabled={updateVehicleRegInspDetailsIsFetching}
					>
						{updateVehicleRegInspDetailsIsFetching ? "Updating..." : "Update"}
					</Button>
				</p>
			)}
			<hr className="mb-4" />

			<div className="row mb-3">
				<div className="col-4 pt-3">
					<strong>Most recently registered:</strong>
				</div>
				<div className="col-8 pl-4">
					{!isNullOrWhiteSpace(
						vehicleRegistrationInfo.lastRegistrationJurisdiction.jurisdiction
					) ? (
						<RegistrationStatusTable
							vehRegInfo={vehicleRegistrationInfo}
							display={RegistrationStatusTableDisplayTypes.RECENT}
						/>
					) : (
						<RegistrationStatusTable />
					)}
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-4 pt-3">
					<strong>Previous registration status:</strong>
				</div>
				<div className="col-8 pl-4">
					{vehicleRegistrationInfo.previousRegistrationJurisdictions.length >
					0 ? (
						<RegistrationStatusTable
							vehRegInfo={vehicleRegistrationInfo}
							display={RegistrationStatusTableDisplayTypes.PREVIOUS}
						/>
					) : (
						<RegistrationStatusTable />
					)}
				</div>
			</div>

			<hr className="mb-4" />

			<div className="row mb-3">
				<div className="col-4">
					<strong>Important information about this vehicle:</strong>
				</div>
				<div className="col-8">
					{formatImportantVehicleInfo(resultFormValues.importantInfo)}
				</div>
			</div>

			<div className="row mb-5">
				<div className="col-4">
					<strong>Jurisdiction search:</strong>
				</div>
				<div className="col-8">
					{formatJurisdictionNotResponding(
						vehicleRegistrationInfo.jurisdictionsNotResponding
					)}
				</div>
			</div>
		</div>
	);
}

export default RegistrationAndInspectionDetails;
