import { VehicleInfo, defaultVehicleInfo, BasicVehicleInfo, defaultBasicVehicleInfo } from "../types/VehicleInfo";
import { InProgressOrder } from "../types/InProgressOrder";
import { VinInfo, VehicleRegistration, JurisdictionAndStatusInfo as CompletedOrderJurisdictionAndStatusInfo,
    InspectionInfo as CompletedOrderInspectionInfo } from "../types/CompletedOrder";
import { VehicleRegistrationBasicInfo, defaultVehicleRegistrationBasicInfo, InspectionInfo, defaultInspectionInfo, JurisdictionAndStatusInfo, defaultJurisdictionAndStatusInfo, JurisdictionCode, SkVehicleRegistrationStatus, IreVehicleLocation, IreVehicleCondition } from "../types/VehicleRegistrationInfo";
import { BasicVehicleInfoResponse } from "../types/apiResponses/BasicVehicleInfoResponse";

export function mapBasicVehicleInfoResponseToBasicVehicleInfo (resp: BasicVehicleInfoResponse) : BasicVehicleInfo {
    return {...defaultBasicVehicleInfo,
        vin: resp.vin,
        bodyStyle: resp.bodyStyle,
        make: resp.make,
        model: resp.model,
        year: resp.year
    };
}

export function mapBasicVehiclesInfoResponseToBasicVehiclesInfo (resp: BasicVehicleInfoResponse[]) : BasicVehicleInfo[] {
    let basicVehiclesInfoArray : BasicVehicleInfo[] = [];
    resp.forEach(v => {
            basicVehiclesInfoArray.push(mapBasicVehicleInfoResponseToBasicVehicleInfo(v));
    });
    return basicVehiclesInfoArray;
}

export function mapBasicVehicleInfoToVehicleInfo (basicVehicleInfo: BasicVehicleInfo) : VehicleInfo {
    return {...defaultVehicleInfo,
        vin: basicVehicleInfo.vin,
        bodyStyle: basicVehicleInfo.bodyStyle,
        make: basicVehicleInfo.make,
        model: basicVehicleInfo.model,
        year: basicVehicleInfo.year
    };
}

export function mapVinInfoToVehicleInfo (model: VinInfo) : VehicleInfo {

    function mapInspectionInfo(inspectionInfoDto: CompletedOrderInspectionInfo) : InspectionInfo {
        return {...defaultInspectionInfo,
            vehicleRegisterable: ((String(inspectionInfoDto.vehicleRegisterable).toLowerCase() === "true") ? true : false),
            inspectionRequired: inspectionInfoDto.inspectionRequired,
            importantInformation: inspectionInfoDto.importantInformation,
        };
    }

    function mapJurisdictionAndStatusInfo (jurisdictionAndStatusInfo: CompletedOrderJurisdictionAndStatusInfo) : JurisdictionAndStatusInfo {
        return {...defaultJurisdictionAndStatusInfo,
            jurisdiction: jurisdictionAndStatusInfo.jurisdiction as JurisdictionCode,
            skStatus: (jurisdictionAndStatusInfo.skStatus) ? jurisdictionAndStatusInfo.skStatus as SkVehicleRegistrationStatus : undefined,
            ireLocation: (jurisdictionAndStatusInfo.ireLocation) ? jurisdictionAndStatusInfo.ireLocation as IreVehicleLocation : undefined,
            ireCondition: (jurisdictionAndStatusInfo.ireCondition) ? jurisdictionAndStatusInfo.ireCondition as IreVehicleCondition : undefined,
        }
    }

    function mapJurisdictionAndStatusInfoArray (prevJurisdictionArray: CompletedOrderJurisdictionAndStatusInfo[]) : JurisdictionAndStatusInfo[] {
        let prevArray: JurisdictionAndStatusInfo[] = [];
        if (prevJurisdictionArray.length > 0) {
            prevJurisdictionArray.forEach((j) => prevArray.push(mapJurisdictionAndStatusInfo(j)));
        }
        return prevArray;
    }

    function mapVehicleRegistrationToVehicleRegistrationBasicInfo (vehRegModel: VehicleRegistration) : VehicleRegistrationBasicInfo {
        return {...defaultVehicleRegistrationBasicInfo,
            lastRegistrationJurisdiction: mapJurisdictionAndStatusInfo(vehRegModel.lastRegistrationJurisdiction),
            previousRegistrationJurisdictions: mapJurisdictionAndStatusInfoArray(vehRegModel.previousRegistrationJurisdictions),
            jurisdictionsNotResponding: mapStringArrayToJurisdictionCodeArray(vehRegModel.jurisdictionsNotResponding),
            inspectionInfo: (vehRegModel.inspectionInfo) && mapInspectionInfo(vehRegModel.inspectionInfo),
        }
    }

    function mapStringArrayToJurisdictionCodeArray(strArray: string[]) {
        let jurisdictionArray: JurisdictionCode[] = [];
        strArray.forEach((juris) => jurisdictionArray.push(juris as JurisdictionCode));
        return jurisdictionArray;
    }

    return {...defaultVehicleInfo,
        vin: model.vin,
        year: model.year,
        make: model.make,
        model: model.model,
        bodyStyle: model.bodyStyle,
        vehicleRegistration: mapVehicleRegistrationToVehicleRegistrationBasicInfo(model.vehicleRegistration),
        skSearchResponse: model.skSearchResponse,
        notes: model.notes,
    };
}

export function mapInProgressOrderToVehicleInfoArray (model: InProgressOrder): VehicleInfo[] {

    function mapVehRegistrationJsonStringToVehicleRegistrationBasicInfo(dtoStr: string) : VehicleRegistrationBasicInfo {
        // Object keys start with uppercase letters when saved in db, which JS doesn't like.  Need to convert key names to start with lowercase letter.
        const newJsonString = dtoStr.replace(/"([^"]+?)"\s*:/g, (matchedSubString, capturedString) => '"' + capturedString[0].toLowerCase() + capturedString.slice(1) + '":');
        return JSON.parse(newJsonString);
    }

    let vehInfoArray : VehicleInfo[] = [];
    if (model.vehicleInfoList.length > 0) {
        model.vehicleInfoList.forEach(v => {
            let vehInfo: VehicleInfo = {...defaultVehicleInfo,
                vin: v.vin,
                make: v.make,
                model: v.model,
                year: v.year,
                bodyStyle: v.bodyStyle,
                skSearchResponse: v.skSearchResults,
                vintelligenceResponse: v.vintelligenceResults,
                legacyInfoResponse: v.legacyInfo,
                vehicleRegistration: (v.vehicleRegistrationInfo != null) ? mapVehRegistrationJsonStringToVehicleRegistrationBasicInfo(v.vehicleRegistrationInfo) : defaultVehicleRegistrationBasicInfo,
                notes: v.notes,
            };
            vehInfoArray.push(vehInfo);
        });
    }
    return vehInfoArray;
}