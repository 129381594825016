import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import vinsForSearchReducer from "./VinsForSearch/VinsForSearchSlice";
import transactionReducer from './transaction/transactionSlice';
import { basicVehicleInfoApi } from "./APIs/basicVehicleInfoAPI";
import { completedOrderHandlingApi } from './APIs/completedOrderHandlingApi';
import { vehicleRegistrationInfoApi } from './APIs/vehicleRegistrationInfoApi';
import { inProgressOrderHandlingApi } from "./APIs/inProgressOrderHandlingApi";
import { batchHandlingApi } from "./APIs/batchHandlingApi";
import { combineReducers } from 'redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { permissionsApi } from './APIs/permissionsApi';
import { auditLogApi } from './APIs/auditLogApi';

const reducers = combineReducers({
  VinsForSearch: vinsForSearchReducer,
  transaction: transactionReducer,
  [basicVehicleInfoApi.reducerPath]: basicVehicleInfoApi.reducer,
  [completedOrderHandlingApi.reducerPath]: completedOrderHandlingApi.reducer,
  [vehicleRegistrationInfoApi.reducerPath]: vehicleRegistrationInfoApi.reducer,
  [inProgressOrderHandlingApi.reducerPath]: inProgressOrderHandlingApi.reducer,
  [permissionsApi.reducerPath]:permissionsApi.reducer,
  [auditLogApi.reducerPath]:auditLogApi.reducer,
  [batchHandlingApi.reducerPath]:batchHandlingApi.reducer
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [
    basicVehicleInfoApi.reducerPath,
    completedOrderHandlingApi.reducerPath,
    vehicleRegistrationInfoApi.reducerPath,
    inProgressOrderHandlingApi.reducerPath,
    permissionsApi.reducerPath,
    auditLogApi.reducerPath,
    batchHandlingApi.reducerPath
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(basicVehicleInfoApi.middleware, completedOrderHandlingApi.middleware, inProgressOrderHandlingApi.middleware, batchHandlingApi.middleware),
});

export const  persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
