import { formatUTCDateStringToDateTimeLocal } from "../../../utility/dateFunctions";

interface DisclaimerProps {
    orderDate: string
};

export function Disclaimer({ orderDate }: DisclaimerProps) {
    return (
        <div data-testid="disclaimer" className="vin-results-disclaimer small">
            <hr className="mb-4" />
            <ul className="mt-4">
                <li>The result of the search was based on the serial number provided at the time and is the latest information available as of <strong>{formatUTCDateStringToDateTimeLocal(orderDate)}</strong>. Vehicle status is subject to change and the search result is valid only on the date the search was completed.</li>
                <li>SGI and its agents assume no responsibility for errors or omissions resulting from errors in the serial number provided for the search or for the accuracy and reliability of vehicle information provided by previous owners or provincial registrars of motor vehicles.</li>
                <li>SGI assumes no responsibility for manufacturer warranties, saleability, liens or debts, mechanical /safety fitness, or registration activity of the vehicle identified on this search report.</li>
                <li>SGI does not guarantee the safety/road-worthiness of this vehicle through the issuance of this search report. The results of this search do not authorize registration in any other jurisdiction.</li>
            </ul>
        </div>
    );
}

export default Disclaimer;
