import React, { useEffect, useState } from "react";
import { Form, Col } from "react-bootstrap";
import { ireVehicleConditions, ireVehicleLocations } from "../../../../types/VehicleRegistrationInfo";
import SingleSelectDropdown from "../../../components/Common/SingleSelectDropdown";

export interface IreVehicleStatusOptionsProps {
    condition: string | undefined;
    location: string | undefined;
    handleOnChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

const IreVehicleStatusOptions = ({ condition, location, handleOnChange }: IreVehicleStatusOptionsProps) => {

    const [ireCondition, setIreCondition] = useState(condition || "");
    const [ireLocation, setIreLocation] = useState(location || "");
    const [conditionDisabled, setConditionDisabled] = useState<boolean>(false);
    const [locationDisabled, setLocationDisabled] = useState<boolean>(false);

    useEffect(() => {
        if (ireCondition === "" && ireLocation === "") {
            setLocationDisabled(false);
            setConditionDisabled(false);
        }
        else if (ireCondition !== "") {
            setIreLocation("");
            setLocationDisabled(true);
        }
        else if (ireLocation !== "") {
            setIreCondition("");
            setConditionDisabled(true);
        }
    }, [ireCondition, ireLocation]);

    function handleOnStatusChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const elementName = event.target.name;
        const elementValue = event.target.value;
        if (elementName === 'ireCondition') {
            setIreCondition(elementValue)
        } else {
            setIreLocation(elementValue);
        }
        handleOnChange(event);
    }

    return (
        <>
            <Form.Group as={Col} className="col-3" controlId="formIREConditionStatus">
                <Form.Label>IRE Condition Status:</Form.Label>
                <SingleSelectDropdown
                    inputName="ireCondition"
                    handleOnChange={handleOnStatusChange}
                    availableOptions={ireVehicleConditions}
                    selectedOption={ireCondition || ""}
                    disabled={conditionDisabled}
                />
            </Form.Group>
            <Form.Group as={Col} className="col-3" controlId="formIRELocationStatus">
                <Form.Label>IRE Location Status:</Form.Label>
                <SingleSelectDropdown
                    inputName="ireLocation"
                    handleOnChange={handleOnStatusChange}
                    availableOptions={ireVehicleLocations}
                    selectedOption={ireLocation || ""}
                    disabled={locationDisabled}
                />
            </Form.Group>
        </>
    );
}

export default IreVehicleStatusOptions;