import { createApi } from "@reduxjs/toolkit/query/react"
import { axiosBaseQuery } from "./axiosBaseQuery";
import { bffEndpoints } from "../../properties/appProperties"

export const auditLogApi = createApi({
    reducerPath: "auditLogApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['logInAuditLogEntry'],
    endpoints: (builder) => ({
        createLogInAuditLogEntry: builder.mutation<void, void>({
            query: (body) => ({
                url: bffEndpoints.CREATE_LOGIN_AUDIT_LOG,
                method: 'POST'                
            }),
            invalidatesTags: [{ type: 'logInAuditLogEntry', id: 'LIST' }],
        }),
        createLogOutAuditLogEntry: builder.mutation<void, void>({
            query: (body) => ({
                url: bffEndpoints.CREATE_LOGOUT_AUDIT_LOG,
                method: 'POST'                
            }),            
        })
    })
});

export const { useCreateLogInAuditLogEntryMutation,useCreateLogOutAuditLogEntryMutation } = auditLogApi