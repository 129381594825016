import { VehicleInfo } from "../../../types/VehicleInfo";
import { isNullOrWhiteSpace } from "../../../utility/stringFunctions";

interface JsonDisplayProps {
    vehicleInfo: VehicleInfo;
};

export function JsonDisplay( {vehicleInfo} : JsonDisplayProps ) {
    return(
        <div className="show-json-div row d-print-none">
            <div className="col-4"> 
                <p>SK Vin Search JSON:</p>
                <pre>{!isNullOrWhiteSpace(vehicleInfo.skSearchResponse) ? JSON.stringify(JSON.parse(vehicleInfo.skSearchResponse),null,2) : "None"}</pre>
            </div>
            <div className="col-4">
                <p>Vintelligence JSON:</p>
                <pre>{!isNullOrWhiteSpace(vehicleInfo.vintelligenceResponse) ? JSON.stringify(JSON.parse(vehicleInfo.vintelligenceResponse), null, 2) : "None"}</pre>
            </div>
            <div className="col-4">
                <p>Vehicle Registration Info:</p>
                <pre>{(typeof vehicleInfo.vehicleRegistration !== "undefined") ? JSON.stringify(vehicleInfo.vehicleRegistration, null, 2) : "None"}</pre>
                <p>Legacy JSON (Deferred Inspection):</p>
                <pre>{!isNullOrWhiteSpace(vehicleInfo.legacyInfoResponse) ? vehicleInfo.legacyInfoResponse : "None"}</pre>
            </div>
        </div>
    );
}

export default JsonDisplay;