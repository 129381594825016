import { SkVinServiceReturnCode } from "../enums/SkVinServiceReturnCode";
import { VehicleRegistrationBasicInfo, defaultVehicleRegistrationBasicInfo } from "./VehicleRegistrationInfo";

export interface BasicVehicleInfo {
    vin: string;
    bodyStyle: string;
    year: string;
    make: string;
    model: string;
}

// Used to structure Vehicle Info in Redux state
export interface VehicleInfo extends BasicVehicleInfo {
    skSearchResponse: string;
    vintelligenceResponse: string;
    legacyInfoResponse: string;
    vehicleRegistration: VehicleRegistrationBasicInfo;
    notes: string;
    notesUnSaved: boolean;
}

// Properties are Pascalcase to work with JSON.parse of the JSON response.
export interface SkData {
    ReturnCode: SkVinServiceReturnCode;
    VehicleEffectiveDate: string;
    VehicleExpiryDate: string;
    ThirdPartyLiability: number;
    PhysicalDamageDeductible: number;
    VinStatusCode: string;
    VinStatusDesc: string;
    VinStatusDate: string;
    VinClaims: SkVinClaimData[];
    VinStatusId: number;
    Pst: string;
}

// Properties are Pascalcase to work with JSON.parse of the JSON response.
export interface SkVinClaimData {
    DateOfLoss: string;
    TotalClaimAmount: number;
    TypeOfLoss: string;
    KindOfLoss: string;
    EstimateInfo: number;
}

export const skInspectionTypes = ["None", "Body Integrity & Mechanical inspections", "Mechanical inspection", "Motorcycle inspection", "Semi-trailer inspection"];

// === Defaults ======================================================
export const defaultBasicVehicleInfo: BasicVehicleInfo = {
    vin: "",
    make: "",
    model: "",
    year: "",
    bodyStyle: "",
}

export const sampleBasicVehicleInfo: BasicVehicleInfo = {
    vin: "TestVinNumber",
    make: "TestMake",
    model: "TestModel",
    year: "9999",
    bodyStyle: "TestBodyStyle",
}

export const defaultVehicleInfo: VehicleInfo = {
    vin: "",
    make: "",
    model: "",
    year: "",
    bodyStyle: "",
    skSearchResponse: "",
    vintelligenceResponse: "",
    legacyInfoResponse: "",
    vehicleRegistration: defaultVehicleRegistrationBasicInfo,
    notes: "",
    notesUnSaved: false,
}

export const sampleVehicleInfo: VehicleInfo = {
    vin: "TestVinNumber",
    make: "TestMake",
    model: "TestModel",
    year: "9999",
    bodyStyle: "TestBodyStyle",
    skSearchResponse: "",
    vintelligenceResponse: "",
    legacyInfoResponse: "",
    vehicleRegistration: defaultVehicleRegistrationBasicInfo,
    notes: "sample notes",
    notesUnSaved: false,
}

export const sampleSkData: SkData = {
    ReturnCode: SkVinServiceReturnCode.NoResults,
    VehicleEffectiveDate: '',
    VehicleExpiryDate: '',
    ThirdPartyLiability: 0,
    PhysicalDamageDeductible: 0,
    VinStatusCode: '',
    VinStatusDesc: '',
    VinStatusDate: '',
    VinClaims: [],
    VinStatusId: 0,
    Pst: ''
}
export const sampleSkVinClaimDataDto: SkVinClaimData = {
    DateOfLoss: '',
    TotalClaimAmount: 0,
    TypeOfLoss: '',
    KindOfLoss: '',
    EstimateInfo: 0
}