import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { skipToken } from "@reduxjs/toolkit/query/react";
import {useAppSelector, useAppDispatch} from "../../../store/ReduxToolkitSamples/hooks";
import {selectVinsForSearch, updateVinSearchResponses} from "../../../store/VinsForSearch/VinsForSearchSlice";
import { selectOrderInfo } from "../../../store/transaction/transactionSlice";
import { useGetInProgressOrderQuery } from "../../../store/APIs/inProgressOrderHandlingApi";
import VehicleDetails from "./VehicleDetails";
import LoadingModal from "../../components/Common/LoadingModal";

function DetailsMain() {
    const vinsForSearch = useAppSelector(selectVinsForSearch);
    const orderInfo = useAppSelector(selectOrderInfo);
    const [orderId, setorderId] = useState<string>(orderInfo.orderId);
    const [modalContent, setModalContent] = useState(<></>);
    const dispatch = useAppDispatch();

    const {
        data: inProgressOrderData,
        error: inProgressOrderError,
        refetch: inProgressOrderRefetch,
        isError: inProgressOrderIsError,
        isFetching: inProgressOrderIsFetching,
        isLoading: inProgressOrderIsLoading,
        isSuccess: inProgressOrderIsSuccess
    } = useGetInProgressOrderQuery(orderId ?? skipToken);


    useEffect(() => {
        if (inProgressOrderIsLoading) {
            setModalContent(
                <>
                    <p>Loading...</p>
                </>
            );
        } else if (inProgressOrderIsError) {
            setModalContent(
                <>
                    <p>There was an error retrieving the VIN results.  Please contact support.</p>
                    <p>{JSON.stringify(inProgressOrderError)}</p>
                </>
            );
        } else {
            setModalContent(<></>);
        }
    }, [inProgressOrderIsLoading, inProgressOrderIsError]);

    useEffect(() => {
        if (inProgressOrderData && inProgressOrderIsSuccess) {
            inProgressOrderData.vehicleInfoList.forEach(v => dispatch(updateVinSearchResponses(v)));
        }
    }, [inProgressOrderData, inProgressOrderIsSuccess]);

    function handlePreviewPDF() {
        window.print();
    }
    
    return (    
        <>
            <h2 className="d-print-none">Search Results</h2>  
            <p className="text-right d-print-none"><Button variant="primary" size="sm" onClick={handlePreviewPDF}>Preview PDF</Button></p>
            <Accordion className="mt-3 mb-5 results-accordion">
                {vinsForSearch.map((vinForSearch, i) => <VehicleDetails index={i.toString()} vehicleInfo={vinForSearch} key={i} />)}
            </Accordion> 
            <div className="d-print-none">
                <LoadingModal show={inProgressOrderIsLoading || inProgressOrderIsError} content={modalContent} />
            </div>
        </>
    );
}
export default DetailsMain;
