import { useState } from "react";
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import moment from 'moment';

interface BatchSearchFormProps {
    submitForm: (searchInputs: BatchSearchInputs) => void;
}

export interface BatchSearchInputs {
    customerEmail: string;
    batchId: string,
    startDate: string;
    endDate: string;
}

interface FormErrors {
    date: boolean;
}

function BatchSearchForm({ submitForm }: BatchSearchFormProps) {
    const [startDate, setStartDate] = useState(moment().subtract(1, 'month'));
    const [endDate, setEndDate] = useState(moment().add(1, 'day'));
    const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
    const [formInputs, setFormInputs] = useState<BatchSearchInputs>({
        customerEmail: "",
        batchId: "",
        startDate: "",
        endDate: ""
    });
    const [formErrors, setFormErrors] = useState<FormErrors>({ date: false });

    function handleDateChange(datesPicked) {
        setStartDate(datesPicked.startDate);
        setEndDate(datesPicked.endDate);
        if (datesPicked.startDate == null || datesPicked.endDate == null)
            setFormErrors({...formErrors, date: true});
        else
            setFormErrors({...formErrors, date: false});
    }

    function handleFocusChange(focusedInput: FocusedInputShape | null) {
        setFocusedInput(focusedInput);
    }

    function handleFormSubmit(e: React.SyntheticEvent) {
        e.preventDefault();
        let validForm = true;
        const inputsForSubmit = { ...formInputs };
        inputsForSubmit.startDate = (startDate != null) ? startDate.utc().format("YYYY-MM-DD") : "";
        inputsForSubmit.endDate = (endDate != null) ? endDate.utc().format("YYYY-MM-DD") : "";
        Object.values(formErrors).forEach(v => { if (v === true) validForm = false; });
        (validForm) && submitForm(inputsForSubmit);
    }

    function handleInputChange(e: React.FormEvent<HTMLInputElement>) {
        setFormInputs({ ...formInputs, [e.currentTarget.id]: e.currentTarget.value});
    }

    return(
        <form onSubmit={handleFormSubmit} id="batch-management-search-form" noValidate>
            <div className="form-row">
                <div className="form-group col-6">
                    <label htmlFor="email">Email</label>
                    <input type="email" className="form-control" id="customerEmail" value={formInputs.customerEmail} onChange={handleInputChange} />
                </div>
                <div className="form-group col-6">
                    <label htmlFor="searchDate">Search Date</label>
                    <DateRangePicker 
                        startDate={startDate}
                        startDateId="startDate"
                        endDate={endDate}
                        endDateId="endDate"
                        onDatesChange={handleDateChange}
                        focusedInput={focusedInput}
                        onFocusChange={handleFocusChange}
                        isOutsideRange={() => false}
                    />
                    <small id="datePickerErrorBlock" className="form-text" style={{color: "red"}}>
                        { formErrors.date ? "Start and end dates must be defined." : <>&nbsp;</> }
                    </small>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-6">
                    <label htmlFor="batchId">Batch ID</label>
                    <input type="text" className="form-control" id="batchId" value={formInputs.batchId} onChange={handleInputChange} />
                </div>
                <div className="form-group col-6"></div>
            </div>
            <p className="text-right"><button type="submit" className="btn btn-primary">Search</button></p>
        </form>
    );
}

export default BatchSearchForm;