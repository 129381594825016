// Used to structure Order info in redux state.
export interface OrderInfo {
    orderId: string;
    creationDate: string;
    vins: string[];
    employeeName?: string;
    employeeEmail?: string|never[];
}

// === Defaults ======================================================
export const defaultOrderInfo: OrderInfo = {
    orderId: "",
    creationDate: "",
    vins: [],
    employeeName: "",
    employeeEmail: "",
}