import { useState, useEffect } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { MULTIVINDECODEFAILEDEVENT, MULTIVINDECODESUCCESSEVENT } from "../../../../properties/appInsightsProperties";
import { ApplicationProblemDetails } from '../../../../types/AuthorizationBFFGeneratedTypes';
import { useAppDispatch, useAppSelector } from "../../../../store/ReduxToolkitSamples/hooks";
import { useGetBasicVehiclesInfoMutation } from "../../../../store/APIs/basicVehicleInfoAPI";
import { selectCustomerInfo } from "../../../../store/transaction/transactionSlice";
import { BasicVehicleInfo } from "../../../../types/VehicleInfo";
import { addVinForSearch } from "../../../../store/VinsForSearch/VinsForSearchSlice";
import { isNullOrWhiteSpace } from "../../../../utility/stringFunctions";
import { validateInput } from "./VinSearchFunctions";

function MultipleVinSearch() {
    const statusStrings = {
        default: "",
        apiError: "There was a problem retrieving info for this Vin Number.",
        apiLoading: "Retrieving results..."
    };
    const [input, setInput] = useState<string>("");
    const [getBasicVehiclesInfo, { 
        isLoading: getBasicVehiclesInfoIsFetching,
        }] = useGetBasicVehiclesInfoMutation();
    const dispatch = useAppDispatch();
    const customerInfo = useAppSelector(selectCustomerInfo);
    const appInsights = useAppInsightsContext();
    const [formStatus, setFormStatus] = useState<string>(statusStrings.default);
    const [basicVehiclesInfo, setBasicVehiclesInfo] = useState<BasicVehicleInfo[]>([]);

    useEffect(() => {
        if (getBasicVehiclesInfoIsFetching)
            setFormStatus(statusStrings.apiLoading);
    }, [getBasicVehiclesInfoIsFetching, statusStrings.apiLoading]);

    const handleChange = e => {
        setInput(e.target.value);
    };
    
    const handlePaste = e => {
        setInput(e.clipboardData.getData('Text'));
    };

    const handleSubmit = () => {
        let vinNumbers = validateVinNumbers(input.split('\n'));
        
        getBasicVehiclesInfo({ vins: vinNumbers, name: customerInfo?.name, email: customerInfo?.emailAddress })
        .unwrap()
        .then((resp: BasicVehicleInfo[]) => {
            setBasicVehiclesInfo(resp);
            setFormStatus(statusStrings.default);

            if (appInsights !== undefined) {
                appInsights.trackEvent(
                    { name: MULTIVINDECODESUCCESSEVENT },
                    { vinInfo: resp },
                );
            }
        })
        .catch((error: ApplicationProblemDetails) => {
            if (appInsights !== undefined) {
                appInsights.trackEvent(
                    { name: MULTIVINDECODEFAILEDEVENT },
                    {
                        vin: vinNumbers,
                        status: error.status,
                        msg: JSON.stringify(error.messages)
                    },
                );
                
            }
            let messages = JSON.stringify(error.messages).replace(/("\\"|\\"")/g, "");
            setFormStatus(messages);
        });
    }

    function handleAddVinsToList() {
        basicVehiclesInfo.forEach(v => {
            if(v.make || v.model || v.year)
                dispatch(addVinForSearch(v));
        });
        setInput("");
        setFormStatus(statusStrings.default);
    }

    function validateVinNumbers(vinNumbers:string[]) {
        let validVinList:string[] = [];

        vinNumbers.forEach(v => {
            let str = v.trim();
            if(!isNullOrWhiteSpace(str) && validateInput(str) && validVinList.indexOf(str)<0)
                validVinList.push(str);
        });

        return validVinList;
    }

    return (
        <> 
            <Form noValidate>
                <Form.Group>
                    <Row>
                        <Col>&nbsp;</Col>
                    </Row>
                    <Row>
                        <Col as="legend" sm={9}>
                            <Form.Control as="textarea" rows={5} 
                                onPaste={handlePaste} 
                                onChange={handleChange} 
                                data-testid="multi-vin"
                            />
                        </Col>
                        <Col xs="auto">
                            <Button variant="outline-primary" onClick={handleSubmit} data-testid="search-vins">Search</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Text id="vinHelpBlock" muted>Search VINs (Maximum 10)</Form.Text>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Control.Feedback type="invalid">
                    
                </Form.Control.Feedback>
                <h6>Search result</h6>
                <p>
                    {formStatus && 
                        <>{formStatus}</>
                    }
                </p>
                <p>
                    {basicVehiclesInfo.map(v => 
                        <li key={v.vin}>{v.vin}: {v.year} {v.make} {v.model}</li>)
                    }
                </p>
                <p>
                    <Button variant="outline-secondary" onClick={handleAddVinsToList} data-testid="add-all">Add all to list</Button>
                </p>
            </Form>
        </>
    );
}
export default MultipleVinSearch;