import { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import { skipToken } from "@reduxjs/toolkit/query/react";
import OrderResultsAccordion from "./OrderResultsAccordion";
import GenerateCustomerEmailButton from "../../../components/GenerateCustomerEmailButton";
import { formatUTCDateStringToDateTimeLocal } from '../../../../utility/dateFunctions';
import { isUndefinedNullOrWhiteSpace } from "../../../../utility/stringFunctions";
import { useGetCompletedOrderQuery } from "../../../../store/APIs/completedOrderHandlingApi";

export function OrderResults(props: any) {  
    const [orderId, setOrderId] = useState<string>();
    const {
        data: completedOrderData,
        error: completedOrderError,
        refetch: completedOrderRefetch,
        isError: completedOrderIsError,
        isFetching: completedOrderIsFetching,
        isLoading: completedOrderIsLoading,
        isSuccess: completedOrderIsSuccess,
    } = useGetCompletedOrderQuery(orderId ?? skipToken);

    useEffect(() => {
        const id = props.location.state.orderId;
        if (!isUndefinedNullOrWhiteSpace(id)) 
            setOrderId(id);
    }, []);

    function handlePrintPDF() {
        window.print();
    }

    return (
        <>
            { completedOrderIsFetching && <p>Retrieving order details from server...</p> }
            { completedOrderIsError && <p>There was a problem retrieving the order details. Please try again or contact support.</p>}
            { (completedOrderData && completedOrderIsSuccess && !completedOrderIsError) &&                
                <div>
                    <h2 className="d-print-none">Order Details</h2>
                    <div className="row d-print-none">
                        <div className="col">
                            <p>
                                <strong>Order Id:</strong> {completedOrderData.orderId}<br />
                                <strong>Search Date:</strong> {formatUTCDateStringToDateTimeLocal(completedOrderData.vinSearchDate)}<br />
                                <strong>Customer Name:</strong> {completedOrderData.customerName}<br />
                                <strong>Customer Email:</strong> {completedOrderData.email}<br />
                                <strong>Batch Id:</strong> {(completedOrderData.batchId) ? completedOrderData.batchId : "n/a"}
                            </p>
                        </div>
                        <div className="col text-right">
                            <p className="text-right d-print-none"><Button variant="primary" size="sm" onClick={handlePrintPDF}>Save/Print PDF</Button></p>
                            <p><GenerateCustomerEmailButton toEmail={completedOrderData.email} orderId={completedOrderData.orderId} /></p>
                            { !props.location.state.hideBack && 
                                <p><Button variant="outline-primary" size="sm" onClick={()=>{props.history.goBack()}}>Go Back</Button></p>
                            }
                        </div>
                    </div>
                    <OrderResultsAccordion vinSearches={completedOrderData.vinSearches} />
                </div>
            }
        </>
    );
}

export default OrderResults;