import React, { useEffect, useState } from "react";
import { Card, Accordion, Button, Form, Col } from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/pro-duotone-svg-icons";
import { toast } from "react-toastify";
import SkVehicleStatusOptions from './SkVehicleStatusOptions';
import IreVehicleStatusOptions from './IreVehicleStatusOptions';
import SingleSelectDropdown from "../../../components/Common/SingleSelectDropdown";
import JurisdictionAndStatusInputRow from "./JurisdictionAndStatusInputRow";
import PreviousRegistrationStatusTable from './PreviousRegistrationStatusTable';
import { useAppDispatch, useAppSelector } from "../../../../store/ReduxToolkitSamples/hooks";
import { updateRegistration } from "../../../../store/VinsForSearch/VinsForSearchSlice";
import { useUpdateVehicleRegistrationInfoMutation } from "../../../../store/APIs/vehicleRegistrationInfoApi";
import { selectOrderInfo } from "../../../../store/transaction/transactionSlice";
import { LegacyInfo, IreVehicleCondition, IreVehicleLocation, JurisdictionAndStatusInfo, JurisdictionCode, PreviousJurisdictionsAndStatusInfo, SkVehicleRegistrationStatus, VehicleRegistrationBasicInfo } from "../../../../types/VehicleRegistrationInfo";
import { VehicleInfo } from "../../../../types/VehicleInfo";
import { isNullOrWhiteSpace } from "../../../../utility/stringFunctions";
import { GenerateJurisdictionMultiSelectOptions, preLoadSelectedOptions } from "../../../../utility/multiSelectFunctions";
import { formatUTCDateStringToDateTimeLocal } from "../../../../utility/dateFunctions";
import { MapProvinceNameToJurisdictionCode, mapVehicleRegistrationBasicInfoToUpdateVehicleRegistrationInfoPayload } from "../../../../mappers/VehicleRegistrationInfoMappers";
import Notes from "../../../components/Notes/Notes";

interface VehicleRegistrationFormProps {
    index: string;
    vehicleInfo: VehicleInfo;
}

interface Option {
    value: string;
    label: string;
}

export const VehicleRegistrationForm = ({ index, vehicleInfo }: VehicleRegistrationFormProps) => {

    const [vehicleRegistrationInfo, setVehicleRegistrationInfo] = useState<VehicleRegistrationBasicInfo>({ ...vehicleInfo.vehicleRegistration, vin: vehicleInfo.vin });
    const [lastRegistrationStatusInfo, setLastRegistrationStatusInfo] = useState<JurisdictionAndStatusInfo>({ ...vehicleInfo.vehicleRegistration.lastRegistrationJurisdiction });
    const [prevRegistrationStatusList, setPrevRegistrationStatusList] = useState<JurisdictionAndStatusInfo[]>(
        [
            ...vehicleInfo.vehicleRegistration.previousRegistrationJurisdictions
        ].filter(p => p.jurisdiction !== JurisdictionCode.Empty));
    const [jurisdictionsNotRespondingSelected, setJurisdictionsNotRespondingSelected] = useState<Option[]>(preLoadSelectedOptions([...vehicleInfo.vehicleRegistration.jurisdictionsNotResponding]));
    const [legacyInfo, setLegacyInfo] = useState<LegacyInfo>();
    const dispatch = useAppDispatch();
    const orderInfo = useAppSelector(selectOrderInfo);
    const [updateVehicleRegistrationInfo, {
        data: updateVehicleRegistrationInfoData,
        error: updateVehicleRegistrationInfoError,
        isLoading: updateVehicleRegistrationInfoIsFetching,
        isSuccess: updateVehicleRegistrationInfoIsSuccess,
        isError: updateVehicleRegistrationInfoIsError
    }] = useUpdateVehicleRegistrationInfoMutation();

    useEffect(() => {
        setVehicleRegistrationInfo({ ...vehicleRegistrationInfo, jurisdictionsNotResponding: jurisdictionsNotRespondingSelected.map((selectedOption) => MapProvinceNameToJurisdictionCode(selectedOption.value)) });
    }, [jurisdictionsNotRespondingSelected]);

    function MapPreviousJurisdictionInfoToJurisdictionAndStatusArray(prevJurisdictionInfo: PreviousJurisdictionsAndStatusInfo): JurisdictionAndStatusInfo[] {
        return prevJurisdictionInfo.jurisdictions.map(j => {
            return {
                jurisdiction: j,
                ireCondition: prevJurisdictionInfo.ireCondition,
                ireLocation: prevJurisdictionInfo.ireLocation,
                skStatus: undefined,
            }
        });
    }

    function handleOnSubmit(event: React.SyntheticEvent) {
        event.preventDefault();
        if (!isNullOrWhiteSpace(orderInfo.orderId)) {
            let updatedVehRegInfo = { ...vehicleRegistrationInfo };
            updatedVehRegInfo.lastRegistrationJurisdiction = { ...lastRegistrationStatusInfo };
            updatedVehRegInfo.previousRegistrationJurisdictions = [...prevRegistrationStatusList];
            updatedVehRegInfo.inspectionInfo = undefined;
            setVehicleRegistrationInfo(updatedVehRegInfo);

            let payload = mapVehicleRegistrationBasicInfoToUpdateVehicleRegistrationInfoPayload(updatedVehRegInfo);
            payload.orderId = orderInfo.orderId;
            
            updateVehicleRegistrationInfo(payload)
                .unwrap()
                .then((resp) => {
                    updatedVehRegInfo.inspectionInfo = JSON.parse(resp);
                    dispatch(updateRegistration(updatedVehRegInfo));
                    setLegacyInfo(updatedVehRegInfo.inspectionInfo?.legacyInfo);
                    toast.success("Vehicle registration saved successfully.");
                })
                .catch((error) => {
                    toast.error("An error was encountered when saving the vehicle registration. Try again or call support.");
                });
        }
    }

    function handleOnChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const elementName = event.target.name;
        const elementValue = event.target.value;
        let updatedLastRegInfo = { ...lastRegistrationStatusInfo };
        if (elementName === 'lastRegistrationJurisdiction') {
            updatedLastRegInfo.jurisdiction = MapProvinceNameToJurisdictionCode(elementValue);
            if (elementValue === 'Saskatchewan') {
                updatedLastRegInfo.ireCondition = undefined;
                updatedLastRegInfo.ireLocation = undefined;
                if (lastRegistrationStatusInfo.skStatus === undefined)
                    updatedLastRegInfo.skStatus = 'Normal';
            }
            if (elementValue !== 'Saskatchewan') {
                updatedLastRegInfo.skStatus = undefined;
            }
        }
        if (elementName === 'skStatus') {
            updatedLastRegInfo.skStatus = elementValue as SkVehicleRegistrationStatus;
            updatedLastRegInfo.ireCondition = undefined;
            updatedLastRegInfo.ireLocation = undefined;
        }
        if (elementName === 'ireCondition') {
            updatedLastRegInfo.skStatus = undefined;
            updatedLastRegInfo.ireCondition = elementValue as IreVehicleCondition;
        }
        if (elementName === 'ireLocation') {
            updatedLastRegInfo.skStatus = undefined;
            updatedLastRegInfo.ireLocation = elementValue as IreVehicleLocation;
        }
        setLastRegistrationStatusInfo(updatedLastRegInfo);
    }

    function GenerateVehicleStatusSelectBoxes() {
        switch (lastRegistrationStatusInfo.jurisdiction) {
            case JurisdictionCode.Empty: return <></>;
            case JurisdictionCode.SK: return <SkVehicleStatusOptions status={lastRegistrationStatusInfo.skStatus} handleOnChange={handleOnChange} />;
            default: return <IreVehicleStatusOptions condition={lastRegistrationStatusInfo.ireCondition} location={lastRegistrationStatusInfo.ireLocation} handleOnChange={handleOnChange} />;
        }
    }

    function handleNewPrevJurisdictionAdded(newInfo: PreviousJurisdictionsAndStatusInfo) {
        const newListItems = MapPreviousJurisdictionInfoToJurisdictionAndStatusArray(newInfo);
        const updatedPrevRegList = prevRegistrationStatusList.concat(newListItems);
        setPrevRegistrationStatusList([...updatedPrevRegList]);
    }

    function handlePrevJurisdictionInfoRemoved(index: number) {
        let newList = [...prevRegistrationStatusList];
        newList.splice(index, 1);
        setPrevRegistrationStatusList(newList);
    }

    function mapInspectionCertificateType(inspectionCertificateType: number) {
        switch (inspectionCertificateType) {
            case 1: return "Alternative Fuel";
            case 2: return "Body Integrity";
            case 3: return "Heavy Vehicle";
            case 4: return "Light Vehicle";
            case 5: return "Oil Well Heavy Vehicle";
            case 6: return "Oil Well Trailer";
            case 7: return "Regular Bus";
            case 8: return "School Bus";
            case 9: return "Trailer";
            case 10: return "Motorcycle";
        }
    }

    return (
        <Accordion.Collapse eventKey={index}>
            <Card.Body>
                <Form data-testid="vehicleRegistrationForm" onSubmit={handleOnSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} className="col-4" controlId="lastRegistrationJurisdiction">
                            <Form.Label>Last Registration Jurisdiction:</Form.Label>
                            <SingleSelectDropdown
                                inputName="lastRegistrationJurisdiction"
                                handleOnChange={handleOnChange}
                                availableOptions={Object.values(JurisdictionCode)}
                                selectedOption={vehicleRegistrationInfo.lastRegistrationJurisdiction.jurisdiction || ""}
                            />
                        </Form.Group>
                        {GenerateVehicleStatusSelectBoxes()}
                    </Form.Row>
                    <Form.Row>
                        <Col className="vin-results-disclaimer small">
                        {legacyInfo?.deferInspectionExist &&
                            <div>
                                <b>Defer Inspection</b> condition exists <br/>
                                <b>Inspections Certificate(s): </b><br/>

                                {legacyInfo?.inspectionCertificates.map(cert => 
                                    <>
                                        Inspection Type: {mapInspectionCertificateType(cert.inspectionCertificateType)} | 
                                        Date: {formatUTCDateStringToDateTimeLocal(cert.inspectionDate)} | 
                                        Comment: {cert.inspectionComment} <br/>
                                    </>)
                                }
                            </div>
                        }
                        </Col>
                    </Form.Row>
                    <hr className="mb-4" />
                    <p>Previous Registration Jurisdictions:</p>
                    <JurisdictionAndStatusInputRow handleAdd={handleNewPrevJurisdictionAdded} />
                    <Form.Row>
                        <PreviousRegistrationStatusTable
                            prevRegistrationStatusList={prevRegistrationStatusList}
                            handleRemove={handlePrevJurisdictionInfoRemoved}
                        />
                    </Form.Row>
                    <hr className="mb-4" />
                    <Form.Row>
                        <Form.Group as={Col} className="col-4" controlId="jurisdictionsNotResponding">
                            <Form.Label>Jurisdictions Not Responding:</Form.Label>
                            <MultiSelect
                                className="open-options-above"
                                selectAllLabel="Select/Deselect All"
                                options={GenerateJurisdictionMultiSelectOptions()}
                                value={jurisdictionsNotRespondingSelected}
                                onChange={setJurisdictionsNotRespondingSelected}
                                ClearSelectedIcon={<FontAwesomeIcon icon={faEraser} title="Clear Selection" />}
                                labelledBy="jurisdictionsNotResponding"
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Col className="text-right mb-5">
                            <Button size="sm" variant="primary" type="submit" disabled={updateVehicleRegistrationInfoIsFetching}>
                                {updateVehicleRegistrationInfoIsFetching ? "Saving..." : "Save"}
                            </Button>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group controlId="notes" as={Col} class-name="col-1">
                            { (vehicleInfo.vin && !isNullOrWhiteSpace(vehicleInfo.vin)) ? 
                                <Notes vehicleInfo={vehicleInfo} editable={true} />
                                : <p>Error getting Vin Info</p> 
                            }
                        </Form.Group>
                    </Form.Row>
                </Form>
            </Card.Body>
        </Accordion.Collapse>
    )
}