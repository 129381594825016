import { useState } from 'react';
import { toast } from 'react-toastify';
import { skipToken } from "@reduxjs/toolkit/query/react";
import BatchSearchForm, { BatchSearchInputs } from "./BatchSearchForm";
import BatchSearchResult from './BatchSearchResult';
import { useGetBatchOrdersQuery } from "../../../../store/APIs/batchHandlingApi";
import { GetBatchOrdersPayload, defaultGetBatchOrdersPayload } from "../../../../types/apiPayloads/BatchOrderPayload";

function BatchManagement() {
    const [getBatchOrdersSearchParams, setGetBatchOrdersSearchParams] = useState<GetBatchOrdersPayload>();
    const { 
        data: getBatchOrdersData, 
        error: getBatchOrdersError, 
        refetch: getBatchOrdersRefetch,
        isError: getBatchOrdersIsError,
        isFetching: getBatchOrdersIsFetching,
        isLoading: getBatchOrdersIsLoading, 
        isSuccess: getBatchOrdersIsSuccess 
    } = useGetBatchOrdersQuery(getBatchOrdersSearchParams ?? skipToken);
    
    function handleSubmit(searchInputs: BatchSearchInputs) {
        // ToDo: perform error checking
        // If all good, call get Batch info API endpoint and pass in searchInputs
        setGetBatchOrdersSearchParams({...defaultGetBatchOrdersPayload,
            email: searchInputs.customerEmail,
            batchId: searchInputs.batchId,
            startDate: searchInputs.startDate,
            endDate: searchInputs.endDate
        });
        // toast.error("Service not setup yet.");
    }

    function onDeleteHandler() {
        getBatchOrdersRefetch();
    }

    return(
        <div id="batch-management" className="mb-5">
            <h2>Batch Management</h2>
            <BatchSearchForm submitForm={handleSubmit} />
            <hr />
            { (getBatchOrdersIsLoading) && <p>Searching database...</p> }
            { (getBatchOrdersData && getBatchOrdersIsSuccess) && <BatchSearchResult data={getBatchOrdersData} onDeleteHandler={onDeleteHandler} /> }
        </div>
    );
}

export default BatchManagement;