import React, { useState } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { AddCustomerForm } from "./AddCustomerForm"
import { CustomerFormValidationErrors, ValidateCustomerFormFields } from "./CustomerFormValidation";
import { useAppSelector, useAppDispatch } from "../../../../store/ReduxToolkitSamples/hooks";
import { selectCustomerInfo, selectOrderInfo, updateCustomerInfo } from "../../../../store/transaction/transactionSlice";
import { CUSTOMERINFOVALIDATIONFAILEDEVENT, SUCCESSFULCUSTOMERINFOENTEREDEVENT } from "../../../../properties/appInsightsProperties";

export const Customer = () => {
    const [formValidationErrors, setFormValidationErrors] = useState<CustomerFormValidationErrors>({});
    const [formValidated, setFormValidated] = useState(false);
    const dispatch = useAppDispatch();
    const appInsights = useAppInsightsContext();
    const customerInfo = useAppSelector(selectCustomerInfo);
    const orderInfo = useAppSelector(selectOrderInfo);

    const validateForm = (validationErrors) : boolean => {

        const formIsValid = Object.keys(validationErrors).length === 0;        
        setFormValidated(formIsValid);        

        return formIsValid;
    }

    function handleOnBlur(event: React.SyntheticEvent) {
        event.preventDefault();
        
        const validationErrors = ValidateCustomerFormFields(customerInfo);
        setFormValidationErrors(validationErrors);

        const formIsValid = validateForm(validationErrors);
        const updatedInfo = { ...customerInfo, valid: formIsValid };
        dispatch(updateCustomerInfo(updatedInfo));

        if (appInsights !== undefined) {
            appInsights.trackEvent(
                { name: 
                    formIsValid 
                        ? SUCCESSFULCUSTOMERINFOENTEREDEVENT 
                        : CUSTOMERINFOVALIDATIONFAILEDEVENT },
                { customerInfo: updateCustomerInfo },
            );
        }
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        
        let updatedInfo = { ...customerInfo, [event.target.name]: event.target.value};

        const validationErrors = ValidateCustomerFormFields(updatedInfo);
        const isValid = validateForm(validationErrors);
        updatedInfo = {...updatedInfo, valid: isValid};

        if(!validationErrors.name && !validationErrors.emailAddress)
            setFormValidationErrors(validationErrors);
 
        dispatch(updateCustomerInfo(updatedInfo))
    }

    return (
        <div className="mb-5">
            <h2 className="mb-4">Customer information</h2>
            <AddCustomerForm onBlur={handleOnBlur} onChange={handleChange} customerInfo={customerInfo} orderInfo={orderInfo}
                formValidated={formValidated} formValidationErrors={formValidationErrors} />
        </div>
    )
}

export default Customer;