import { stepLabels, OrderSteps } from "./StepperProperties";
import VinSearch from '../VinSearch/VinSearch';
import DetailsMain from "../../Results";
import { Customer } from '../Customer/Customer';
import { VehicleRegistration } from '../VehicleRegistration/VehicleRegistration';

export function toggleNextButtonDisable (activeStep: number, validCustomerInfo: boolean, vinSearchListLength: number) : boolean {
    if (activeStep === OrderSteps.CustomerInfo && !validCustomerInfo) return true;
    if (activeStep === OrderSteps.EnterVins && vinSearchListLength === 0) return true;
    return false;
}

export function displayNextFinishText (activeStep: number, finishingState: boolean) : string {
    return (activeStep === stepLabels.length - 1) ? finishButtonText(finishingState) : "Next";
}

export function finishButtonText (state: boolean) : string {
    return (state) ? 'Finishing...' : 'Finish';
}

export function getStepContent(step: number): JSX.Element | string {
    switch (step) {
        case 0:
            return <Customer />;
        case 1:
            return <VinSearch />;
        case 2:
            return <VehicleRegistration />;
        case 3:
            return <DetailsMain />;
        default:
            return 'Unknown step';
    }
}