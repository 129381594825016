import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown } from "@fortawesome/pro-duotone-svg-icons";
import ActionMenu from "./ActionMenu";

export interface MenuOption {
    menuText: string;
    onClickFunction: () => void;
    dataTestId: string;
    disabled?: boolean | undefined;
}

interface ActionButtonProps {
    buttonId: string,
    menuOptions: MenuOption[]
}

function TableActionButton({ buttonId, menuOptions } : ActionButtonProps) {
    return (
        <Dropdown>
            <Dropdown.Toggle variant="link" className="action-button" 
                    id={buttonId}>
                <span style={{ color: "gray", fontSize: "large" }}>
                    <FontAwesomeIcon icon={faAngleDoubleDown} title="Action menu" />
                </span>     
            </Dropdown.Toggle>
            <ActionMenu menuOptions={menuOptions}/>
        </Dropdown>
    );
}

export default TableActionButton;