import { createApi } from "@reduxjs/toolkit/query/react"
import { axiosBaseQuery } from "./axiosBaseQuery";
import { UpdateVehicleRegistrationInspectionDetailsPayload, UpdateVehicleRegistrationInfoPayload } from "../../types/apiPayloads/InProgressOrderPayload";
import { bffEndpoints } from "../../properties/appProperties"

export const vehicleRegistrationInfoApi = createApi({
    reducerPath: "vehicleRegistrationInfoApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['VehicleRegistrationInfo'],
    endpoints: (builder) => ({
        updateVehicleRegistrationInfo: builder.mutation<string, UpdateVehicleRegistrationInfoPayload>({
            query: (body) => ({
                url: bffEndpoints.UPDATE_VEH_REGISTRATION_INFO,
                method: 'POST',
                data: body,
            }),
        }),
        updateVehicleRegistrationInspectionDetails: builder.mutation<void, UpdateVehicleRegistrationInspectionDetailsPayload>({
            query: (body) => ({
                url: bffEndpoints.UPDATE_VEH_REGISTRATION_INSPECTION_DETAILS,
                method: 'POST',
                data: body,
            }),
        }),
    })
});

export const { useUpdateVehicleRegistrationInfoMutation, useUpdateVehicleRegistrationInspectionDetailsMutation } = vehicleRegistrationInfoApi