import moment from "moment";
import { SkVinClaimData } from "../types/VehicleInfo";
import { isNullOrWhiteSpace } from "../utility/stringFunctions";

export function formatVehicleExpiryDate (expiryDate: string): string {
    const minValueMatch = /0001-01-01/;
    const perpetualTermMatch = /9999-12-31/;
    
    if (expiryDate.match(perpetualTermMatch)) { return "Perpetual Term" }
    return (moment(expiryDate).isValid() && !expiryDate.match(minValueMatch)) ? moment(expiryDate).format("DD MMMM YYYY").toString() : "";
}

export function formatPst (pst: string): string {
    const pstMatch = /^(Y|N)$/;

    if (!pst.match(pstMatch)) { return "" }
    return "Payable";
}

export function formatVinStatusDate (statusDate: string): string {
    const minValueMatch = /0001-01-01/;

    return (moment(statusDate).isValid() && !statusDate.match(minValueMatch)) ? moment(statusDate).format("DD MMMM YYYY").toString() : "";
}

export function formatVinStatusDesc (statusDesc: string): string {
    if(isNullOrWhiteSpace(statusDesc)) { return "No vehicle status reported to SGI"; }
    return statusDesc;
}

export function formatDateOfLoss (lossDate: string): string {
    const minValueMatch = /0001-01-01/;

    return (moment(lossDate).isValid() && !lossDate.match(minValueMatch)) ? moment(lossDate).format("DD MMMM YYYY").toString() : "";
}

export function formatTypeOfLoss (typeOfLoss: string): string {
    let type = "Total";
    if (typeOfLoss === "P") { type = "Partial"; }
    return type;
}

export function formatTotalClaimAmount (claim: SkVinClaimData): string {
    let totalValue = "";
    if (claim.TotalClaimAmount === 0 || claim.TotalClaimAmount === 0.00) {
        totalValue = (claim.TypeOfLoss.match(/^T$/i)) ? "Total Loss" : "Unavailable";
    } else {
        totalValue = `$${claim.TotalClaimAmount.toString()}`;
    }
    return totalValue;
}