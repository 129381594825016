import { SkData } from "../../../types/VehicleInfo";
import { formatVehicleExpiryDate, formatPst, formatVinStatusDate, formatVinStatusDesc } from "../../../utility/SkVinServiceResultFormatters";
import { isNullOrWhiteSpace } from "../../../utility/stringFunctions";

interface SkVinSearchDetailsProps {
    skData: SkData;
}

export function SkVinSearchDetails({ skData }: SkVinSearchDetailsProps) {

    function constructExpiryDate() {
        const formattedValue = formatVehicleExpiryDate(skData.VehicleExpiryDate);
        return (formattedValue !== "") && <>{detailRow("SK registration expiry:", formattedValue)}</>;
    }

    function constructPst() {
        const formattedValue = formatPst(skData.Pst);
        return (formattedValue !== "") && <>{detailRow("SK PST status:", formattedValue)}</>;
    }

    function constructVinStatusDate() {
        const formattedValue = formatVinStatusDate(skData.VinStatusDate);
        return (formattedValue !== "") && <>{detailRow("VIN status date:", formattedValue)}</>;
    }

    function constructVinStatusDesc() {
        const formattedValue = formatVinStatusDesc(skData.VinStatusDesc);
        return (formattedValue !== "") && <>{detailRow("VIN status:", formattedValue)}</>;
    }

    function detailRow(detail: string, value: string) {
        return (
            <div className="row mb-1">
                <div className="col-6"><strong>{detail}</strong></div>
                <div className="col-6">{value}</div>
            </div>
        );
    }

    return (
        <>
            {constructExpiryDate()}
            {constructPst()}
            {!isNullOrWhiteSpace(skData.VinStatusDesc) && constructVinStatusDate()}
            {constructVinStatusDesc()}
        </>
    );
}

export default SkVinSearchDetails;
