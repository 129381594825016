import { Nav } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { Link, useHistory } from "react-router-dom";
import SignInOutButton from "../SignInOutButton/index";
import { uiPaths } from "../../../properties/appProperties";
import { resetInProgressState } from "../../../utility/resetInProgressState";

function Header() {
    const history = useHistory();

    function handleCreateOrder() {
        resetInProgressState();
        history.push(uiPaths.CREATE_ORDER);
    }

    return (
        <div className="container-fluid p-0 mb-5 d-print-none">
            <Navbar bg="light" expand="lg">
                <Navbar.Brand as={Link} to={uiPaths.ROOT}>
                    <img src="/sgi_logo.svg" alt="SGI Logo" width="128" height="30" className="d-inline-block align-top pr-2" />
                    Cross Canada VIN Search
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse>
                    <Nav className="mr-auto">
                        <Link to="#" onClick={handleCreateOrder} className="nav-link" style={{borderRight: "1px solid #DDD"}}>Create Order</Link>
                        <Link to={uiPaths.ORDER_SEARCH} className="nav-link" style={{borderRight: "1px solid #DDD"}}>Order Search</Link>
                        <Link to={uiPaths.BATCH_MANAGEMENT} className="nav-link">Batch Management</Link>
                    </Nav>
                    <Nav>
                        <SignInOutButton />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}

export default Header;
