import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomerInfo, defaultCustomerInfo } from '../../types/CustomerInfo'
import { OrderInfo, defaultOrderInfo } from '../../types/OrderInfo'
import { RootState } from '../store'

export interface TransactionState {
    customerInfo: CustomerInfo;
    orderInfo: OrderInfo;
}

const initialState: TransactionState = {
    customerInfo: defaultCustomerInfo,
    orderInfo: defaultOrderInfo
};

export const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        updateCustomerInfo: (state, action: PayloadAction<CustomerInfo>) => {
            state.customerInfo = action.payload;
        },
        resetCustomerInfo: (state) => {
            state.customerInfo = defaultCustomerInfo;
        },
        updateOrderInfo: (state, action: PayloadAction<OrderInfo>) => {
            state.orderInfo = action.payload;
        },
        resetOrderInfo: (state) => {
            state.orderInfo = defaultOrderInfo;
        },
    },
});

export const { updateCustomerInfo, resetCustomerInfo, updateOrderInfo, resetOrderInfo } = transactionSlice.actions;

export const selectCustomerInfo = (state: RootState) => state.transaction.customerInfo;

export const selectOrderInfo = (state: RootState) => state.transaction.orderInfo;

export default transactionSlice.reducer;