export interface InProgressOrder {
    orderId: string;
    name: string;
    email: string;
    vinList: string[];
    creationDate: string;
    vehicleInfoList: InProgressVin[];
    employeeName?: string;
    employeeEmail?: string|never[];
}

export interface InProgressOrderSummary {
    orderId: string;
    name: string;
    email: string;
    vinList: string[];
    creationDate: string;
    employeeName?: string;
    employeeEmail?: string|never[];
}

export interface InProgressVin {
    vin: string;
    make: string;
    model: string;
    bodyStyle: string;
    year: string;
    skSearchResults: string;
    vintelligenceResults: string;
    vehicleRegistrationInfo: string;
    legacyInfo: string;
    importantInfo: string[];
    notes: string;
}

// === Defaults ======================================================
export const defaultInProgressOrder: InProgressOrder = {
    orderId: "",
    name: "",
    email: "",
    vinList: [],
    creationDate: "",
    vehicleInfoList: [],
    employeeName: "",
    employeeEmail: "",
}

export const defaultInProgressOrderSummary: InProgressOrderSummary = {
    orderId: "",
    name: "",
    email: "",
    vinList: [],
    creationDate: "",
    employeeName: "",
    employeeEmail: "",
}

export const defaultInProgressVin: InProgressVin = {
    vin: "",
    make: "",
    model: "",
    bodyStyle: "",
    year: "",
    skSearchResults: "",
    vintelligenceResults: "",
    vehicleRegistrationInfo: "",
    legacyInfo: "",
    importantInfo: [],
    notes: ""
}