import React, { useEffect, useState } from "react";
import { Form, Col, Button } from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/pro-duotone-svg-icons";
import { GenerateJurisdictionMultiSelectOptions } from "../../../../utility/multiSelectFunctions";
import { MapProvinceNameToJurisdictionCode } from "../../../../mappers/VehicleRegistrationInfoMappers";
import { Option } from "react-multi-select-component/src/lib/interfaces";
import SingleSelectDropdown from "../../../components/Common/SingleSelectDropdown";
import { IreVehicleCondition, ireVehicleConditions, IreVehicleLocation, ireVehicleLocations, PreviousJurisdictionsAndStatusInfo } from "../../../../types/VehicleRegistrationInfo";

interface JurisdictionAndStatusInputProps {
  handleAdd: (newInfo: PreviousJurisdictionsAndStatusInfo) => void;
}

const JurisdictionAndStatusInputRow = ({ handleAdd }: JurisdictionAndStatusInputProps) => {

  const [previousRegistrationJurisdictionsSelected, setPreviousRegistrationJurisdictionsSelected] = useState<Option[]>([]);
  const [condition, setCondition] = useState<IreVehicleCondition>("");
  const [location, setLocation] = useState<IreVehicleLocation>("");
  const [addDisabled, setAddDisabled] = useState<boolean>(true);
  const [conditionDisabled, setConditionDisabled] = useState<boolean>(false);
  const [locationDisabled, setLocationDisabled] = useState<boolean>(false);
  
  
  useEffect(() => {
    if (previousRegistrationJurisdictionsSelected.length === 0) {
      setAddDisabled(true);
      if (condition === "" && location === "") {
        setLocationDisabled(false);
        setConditionDisabled(false);
      }
      if (condition !== "") {
        setLocation("");
        setLocationDisabled(true);
      }
      else if (location !== "") {
        setCondition("");
        setConditionDisabled(true);
      }
    }
    else if (condition === "" && location === "") {
      setAddDisabled(true);
      setLocationDisabled(false);
      setConditionDisabled(false);
    }
    else if (condition !== "") {
      setLocation("");
      setLocationDisabled(true);
      setAddDisabled(false);
    }
    else if (location !== "") {
      setCondition("");
      setConditionDisabled(true);
      setAddDisabled(false);
    }
    else 
      setAddDisabled(false);
   }, [previousRegistrationJurisdictionsSelected, condition, location]);

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const elementName = event.target.name;
    let elementValue: IreVehicleCondition | IreVehicleLocation = "";
    if (elementName === 'ireCondition') {
      elementValue = event.target.value as IreVehicleCondition;
      setCondition(elementValue)
    } else {
      elementValue = event.target.value as IreVehicleLocation;
      setLocation(elementValue);
    }
  }

  function handleAddJurisdictionAndStatus() {
    const jurisdictionsSelected = previousRegistrationJurisdictionsSelected.map(so => MapProvinceNameToJurisdictionCode(so.value));
    const newInfo: PreviousJurisdictionsAndStatusInfo = {
      jurisdictions: jurisdictionsSelected,
      ireCondition: condition,
      ireLocation: location
    };
    handleAdd(newInfo);

    // Reset inputs
    setPreviousRegistrationJurisdictionsSelected([]);
    setCondition("");
    setLocation("");
    setAddDisabled(true);
    setConditionDisabled(false);
    setLocationDisabled(false);
  }

  return (
    <Form.Row>
      <Form.Group as={Col} className="col-4" controlId="previousRegistrationJurisdictions">
        <Form.Label>Jurisdiction:</Form.Label>
        <MultiSelect
          selectAllLabel="Select/Deselect All"
          options={GenerateJurisdictionMultiSelectOptions()}
          value={previousRegistrationJurisdictionsSelected}
          onChange={setPreviousRegistrationJurisdictionsSelected}
          ClearSelectedIcon={<FontAwesomeIcon icon={faEraser} title="Clear Selection" />}
          labelledBy="previousRegistrationJurisdictions"
        />
      </Form.Group>
      <Form.Group as={Col} className="col-3" controlId="formIREConditionStatus">
        <Form.Label>IRE Condition Status:</Form.Label>
        <SingleSelectDropdown
          inputName="ireCondition"
          handleOnChange={handleOnChange}
          availableOptions={ireVehicleConditions}
          selectedOption={condition || ""}
          disabled={conditionDisabled}
        />
      </Form.Group>
      <Form.Group as={Col} className="col-3" controlId="formIRELocationStatus">
        <Form.Label>IRE Location Status:</Form.Label>
        <SingleSelectDropdown
          inputName="ireLocation"
          handleOnChange={handleOnChange}
          availableOptions={ireVehicleLocations}
          selectedOption={location || ""}
          disabled={locationDisabled}
        />
      </Form.Group>
      <div className="col-1 align-self-center">
        <Button className="mt-3" size="sm" variant="primary" onClick={handleAddJurisdictionAndStatus} disabled={addDisabled}>Add</Button>
      </div>
    </Form.Row>
  )
};

export default JurisdictionAndStatusInputRow;
