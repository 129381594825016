import InProgressOrders from "./InProgressOrders";

function Home() {
    return (
        <>        
            <InProgressOrders />
        </>
    );
}

export default Home;
