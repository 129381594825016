import { CustomerInfo } from '../../../../types/CustomerInfo'

export interface CustomerFormValidationErrors {
    name?: string;
    emailAddress?: string;
}

export enum CustomerValidationErrorMessages {
    Name = "Please provide a valid name. Name should not contain \? # / \\",
    Email_Address = "Please provide a valid email address",
}

export function ValidateCustomerFormFields(fields: CustomerInfo): CustomerFormValidationErrors {
    const formValidationErrors: CustomerFormValidationErrors = {};
    // valid name regex - name should start with a letter, number or double quote.
    const validNameFormat = /^[a-zA-Z0-9"]+[^\\^\/^\?^#]*$/;
    // valid email regex taken from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    const validEmailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!fields.name.match(validNameFormat))
        formValidationErrors.name = CustomerValidationErrorMessages.Name;

    if (!fields.emailAddress.match(validEmailFormat))
        formValidationErrors.emailAddress = CustomerValidationErrorMessages.Email_Address;

    return formValidationErrors;
}

