import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import moment from "moment";
import TableActionButton, { MenuOption } from "../../../components/TableActionButton";
import { CompletedOrderSummary } from "../../../../types/CompletedOrder";
import { useRemoveOrdersFromBatchOrderMutation, useGetBatchOrderQuery } from '../../../../store/APIs/batchHandlingApi';
import { uiPaths } from "../../../../properties/appProperties";
import { useGetCompletedOrdersQuery } from "../../../../store/APIs/completedOrderHandlingApi";

interface BatchDetailActionButtonProps {
    row: CompletedOrderSummary;
}

function BatchDetailActionButton({ row } : BatchDetailActionButtonProps) {
    let startDate = moment().subtract(30, 'day').utc().format("YYYY-MM-DD");
    let endDate = moment().add(1, 'day').utc().format("YYYY-MM-DD");
    const history = useHistory();
    const { data: vinSearchResults, refetch } = useGetCompletedOrdersQuery({ vin: "", name: "", email: "", startDate: startDate, endDate: endDate });

    const [removeOrdersFromBatch, {
        data: removeOrdersFromBatchData,
        error: removeOrdersFromBatchError,
        isLoading: removeOrdersFromBatchIsFetching,
        isSuccess: removeOrdersFromBatchIsSuccess,
        isError: removeOrdersFromBatchIsError
    }] = useRemoveOrdersFromBatchOrderMutation();

    const {
        data: batchOrderData,
        error: batchOrderError,
        refetch: batchOrderRefetch,
        isError: batchOrderIsError,
        isFetching: batchOrderIsFetching,
        isLoading: batchOrderIsLoading,
        isSuccess: batchOrderIsSuccess,
    } = useGetBatchOrderQuery(row.batchId);

    function handleViewOrder() {
        history.push({pathname: uiPaths.ORDER_RESULT, state: { orderId: row.orderId}});
    }

    function handleRemoveFromBatchClick() {
        if (row.batchId) {
            removeOrdersFromBatch({batchId:row.batchId, orderIds:[row.orderId]})
            .unwrap()
            .then((resp) => {
                batchOrderRefetch();
                refetch();
                toast.success("Order successfully removed from batch. ");
            })
            .catch((error) => {
                toast.error("An error was encountered while adding order to the batch. Try again or call support.");
            });    
        } else
        {
            toast.error("BatchId can not be null.");
        }  
    }

    const menuOptions: MenuOption[] = [
        { menuText: "View Order", onClickFunction: handleViewOrder, dataTestId: "viewOrder" },
        { menuText: "Remove from Batch", onClickFunction: handleRemoveFromBatchClick, dataTestId: "removeFromBatch" },
    ];

    return (
        <>
            <TableActionButton buttonId={`dropdown-button-drop-${row.orderId}`} menuOptions={menuOptions} />
        </>
    );
}

export default BatchDetailActionButton;