import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BasicVehicleInfo, VehicleInfo } from "../../types/VehicleInfo";
import { RootState } from "../store";
import { InProgressVin } from "../../types/InProgressOrder";
import { mapBasicVehicleInfoToVehicleInfo } from "../../mappers/VehicleInfoMappers";
import { VehicleRegistrationBasicInfo } from "../../types/VehicleRegistrationInfo";

export interface VinsForSearchState {
	vehicleInfoList: VehicleInfo[];
}

export interface UpdateNotesProps {
	vin: string;
	notes: string;
	notesUnSaved: boolean;
}

const initialState: VinsForSearchState = {
	vehicleInfoList: [],
};

export const VinsForSearchSlice = createSlice({
	name: "vinsForSearch",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		addVinForSearch: (state, action: PayloadAction<BasicVehicleInfo>) => {
			var vinToAdd = action.payload;
			var vinIsAlreadyPresent = state.vehicleInfoList.findIndex(
				(vin) => vin.vin === vinToAdd.vin
			);
			if (vinIsAlreadyPresent === -1)
				// Map BasicVehicleInfo to VehicleInfo object.
				state.vehicleInfoList.push(mapBasicVehicleInfoToVehicleInfo(vinToAdd));
		},
		updateVehicleInfoListArray: (
			state,
			action: PayloadAction<VehicleInfo[]>
		) => {
			state.vehicleInfoList = action.payload;
		},
		updateVinSearchResponses: (state, action: PayloadAction<InProgressVin>) => {
			var responses = action.payload;
			let index = state.vehicleInfoList.findIndex(
				(v) => v.vin === responses.vin
			);
			state.vehicleInfoList[index] = {
				...state.vehicleInfoList[index],
				skSearchResponse: responses.skSearchResults,
				vintelligenceResponse: responses.vintelligenceResults,
				legacyInfoResponse: responses.legacyInfo,
			};
		},
		removeVinForSearch: (state, action: PayloadAction<string>) => {
			const indexOfVinToRemove = state.vehicleInfoList.findIndex(
				(vin) => vin.vin === action.payload
			);
			if (indexOfVinToRemove > -1)
				state.vehicleInfoList.splice(indexOfVinToRemove, 1);
		},
		resetVinForSearch: (state) => {
			state.vehicleInfoList = [];
		},
		updateRegistration: (
			state,
			action: PayloadAction<VehicleRegistrationBasicInfo>
		) => {
			let vinInfo = state.vehicleInfoList.find((s) => {
				return s.vin === action.payload.vin;
			});
			if (vinInfo) {
				vinInfo.vehicleRegistration = action.payload;
				vinInfo.vehicleRegistration.inspectionDetailsUnsaved =
					action.payload.inspectionDetailsUnsaved;
			}
		},
		updateNotes: (state, action: PayloadAction<UpdateNotesProps>) => {
			let vinInfo = state.vehicleInfoList.find((s) => {
				return s.vin === action.payload.vin;
			});
			if (vinInfo) {
				vinInfo.notes = action.payload.notes;
				vinInfo.notesUnSaved = action.payload.notesUnSaved;
			}
		},
	},
});

export const {
	addVinForSearch,
	updateVehicleInfoListArray,
	removeVinForSearch,
	resetVinForSearch,
	updateRegistration,
	updateVinSearchResponses,
	updateNotes,
} = VinsForSearchSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.VinsForSearch.value)`
export const selectVinsForSearch = (state: RootState) =>
	state.VinsForSearch.vehicleInfoList;

export default VinsForSearchSlice.reducer;
