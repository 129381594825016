import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import moment from "moment";
import {Modal, ModalBody } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { paginationOptions } from "../../../../properties/reactBootstrapTableProperties";
import { searchResultColumns } from "../OrderSearch/SearchResultFormatters";
import { useGetCompletedOrdersQuery } from "../../../../store/APIs/completedOrderHandlingApi";
import { BatchOrderInfo } from "../../../../types/BatchOrder";
import { useAddOrdersToBatchOrderMutation, useGetBatchOrderQuery } from '../../../../store/APIs/batchHandlingApi';
import { CompletedOrderSummary } from "../../../../types/CompletedOrder";

interface BatchOrdersProps {
    show: boolean;
    onHide : Function;
    batch?: BatchOrderInfo;
}

const BatchAddOrderMain = ({show, onHide, batch}: BatchOrdersProps) => {
    let startDate = moment().subtract(30, 'day').utc().format("YYYY-MM-DD");
    let endDate = moment().add(1, 'day').utc().format("YYYY-MM-DD");
    const { data: vinSearchResults, refetch } = useGetCompletedOrdersQuery({ vin: "", name: "", email: "", startDate: startDate, endDate: endDate });
    const {refetch: batchOrderRefetch} = useGetBatchOrderQuery(batch?.batchId??"");
    const [filteredVinSearchResults, setFilteredVinSearchResults] = useState<CompletedOrderSummary[]>();
    const [addOrdersToBatch] = useAddOrdersToBatchOrderMutation();
    const addOrderToBatchColumns = removeUneccessaryColumns([...searchResultColumns]);
        
    function removeUneccessaryColumns( columns) {
        let index = columns.findIndex(c => c.dataField === 'batchId');
        columns[index] = {...columns[index], hidden: true};
        index = columns.findIndex(c => c.dataField === 'email');
        columns[index] = {...columns[index], hidden: true};
        index = columns.findIndex( c => c.dataField ==='actions');         
        columns.splice(index,1);
        return columns;
    }

    function refreshOrderList(batch: BatchOrderInfo | undefined) {
        refetch();        
        let filteredResults = vinSearchResults?.filter(vinSearchResult => (batch?.customerEmail === vinSearchResult.email && !vinSearchResult.batchId));
        setFilteredVinSearchResults(filteredResults);
    }

    function noDataIndication() {
        return "There are no orders.";
    }

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if (batch?.batchId) {
                addOrdersToBatch({batchId:batch?.batchId??"", orderIds:[row.orderId]})
                .unwrap()
                .then(_resp => {
                   // Successful response should equal the new Batch Id. Redirect to batch item details page and pass in the Batch Id
                   refreshOrderList(batch);     
                   toast.success("Order successfully added to batch. ");
                   batchOrderRefetch();                   
                })
                .catch(_error => {
                    toast.error("An error was encountered while adding order to the batch. Try again or call support.");
                });    
            } else
            {
                toast.error("BatchId can not be null.");
            }          
        }
    }
    
    useEffect(
        () => {
            if (show) {
                refreshOrderList(batch);    
            }
            
        }
    ,[show,vinSearchResults]);

    return(
        <Modal show={show} onHide={() => onHide()} centered dialogClassName="add-order-to-batch-modal">
            <ModalHeader closeButton>
                <h5>Select the order to be added to the batch.</h5>
            </ModalHeader>
            <ModalBody>
            { filteredVinSearchResults && 
                <div>
                <ToolkitProvider keyField="orderId" data={ filteredVinSearchResults } columns={ addOrderToBatchColumns} bootstrap4={ true }>
                                {
                                    props => (
                                        <>
                                            <BootstrapTable 
                                                { ...props.baseProps }
                                                id="batch-orders-result-table" 
                                                classes="my-3"
                                                bordered={false}
                                                striped={false}
                                                hover={true}
                                                noDataIndication={noDataIndication}
                                                defaultSorted={[{ dataField: "orderDate", order: "asc" }]}
                                                defaultSortDirection="asc"
                                                rowEvents={rowEvents}
                                                pagination={ paginationFactory(paginationOptions) }
                                            />
                                        </>
                                    )}
                            </ToolkitProvider>
                </div>
            }
            </ModalBody>
        </Modal>        
    );

}

export default BatchAddOrderMain;


