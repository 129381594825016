import { AxiosError, AxiosRequestConfig } from 'axios';
import { msalInstance } from '../../app/components/Auth/initializeMsal';

export async function handleResponse(response: any) {
  if (response.status >= 200 && response.status < 300) return response;
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    console.error(error);
    return response;
  }
  if (response.status === 404) {
    return 'Not Found';
  }
  console.error('Network response was not ok.');
  return response;
}

// In a real app, would likely call an error logging service.
export function handleError(error: AxiosError) {
  throw error;
}

export async function getAccessToken() {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts() ?? [];

  if (!activeAccount && accounts.length === 0) {
    throw new Error('No active account');
  }

  const authResult = await msalInstance.acquireTokenSilent({
    scopes: [(window as any)['runConfig']?.REACT_APP_BFF_API_SCOPES],
    account: activeAccount ?? accounts[0],
  })
  .catch((error) => {
    // fallback to interaction when silent call fails
    return msalInstance.acquireTokenRedirect({
      scopes: [(window as any)['runConfig']?.REACT_APP_BFF_API_SCOPES],
      account: activeAccount ?? accounts[0],
    });
  });
  return authResult?.accessToken;
}

export async function prepareBffRequestAsync(): Promise<AxiosRequestConfig> {
  const token = await getAccessToken();
  return {
    headers: { Authorization: 'Bearer ' + token },
  };
}

const apiUtils = { handleResponse, handleError };
export default apiUtils;
