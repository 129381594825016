import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { useEffect, useState } from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { produceCsvProps } from "./SearchResultFormatters";
import { CompletedOrderSummary } from "../../../../types/CompletedOrder";

export interface CustomerSummaryTableProps {
    data: CompletedOrderSummary[];
    startDate: string;
    endDate: string;
}

export interface CustomerReportProps {
    customerName: string;
    customerEmail: string;
    orderTotal: number;
    vinTotal: number;
}

function CustomerSummaryTable({ data, startDate, endDate } : CustomerSummaryTableProps) {
    const [customerSummaryData, setCustomerSummaryData] = useState<CustomerReportProps[]>([]);
    const { ExportCSVButton } = CSVExport;

    useEffect(() => {
        if (data != null && data.length > 0) {
            let unknown: CustomerReportProps = { customerName: "Unknown", customerEmail: "Unknown", orderTotal: 0, vinTotal: 0 };
            let reducedData: CustomerReportProps[] = Object.values( data.reduce((r, o) => {
                    if (o.email != null && o.email !== "") {
                        r[o.email + o.customerName] = r[o.email + o.customerName] || { customerName: o.customerName, customerEmail: o.email, orderTotal: 0, vinTotal: 0 };
                        r[o.email + o.customerName].orderTotal += 1;
                        r[o.email + o.customerName].vinTotal += o.vins.length;
                        return r;
                    } else {
                        unknown.orderTotal += 1;
                        unknown.vinTotal += o.vins.length;
                        return r;
                    }
                }, {}) );
            reducedData.push(unknown);
            setCustomerSummaryData(reducedData);
        }
    },[data]);

    const customerSummaryDataColumns: ColumnDescription[] = [{
        dataField: 'customerName',
        text: 'Customer Name',
        sort: true,
    },{
        dataField: 'customerEmail',
        text: 'Customer Email',
        sort: true,
    },{
        dataField: 'orderTotal',
        text: 'Total Orders',
        sort: true,
    }, {
        dataField: 'vinTotal',
        text: 'Total Vins',
        sort: true,
    }]; 

    function noDataIndication() {
        return "There are no order results that match the search criteria in the system.";
    }

    return (
        <ToolkitProvider keyField="customerEmail" data={ customerSummaryData } columns={ customerSummaryDataColumns } bootstrap4={ true } exportCSV={ produceCsvProps("CustomerSummary", startDate, endDate) }>
            {
                props => (
                    <>
                        <BootstrapTable 
                            { ...props.baseProps }
                            id="customer-summary-result-table" 
                            classes="my-3"
                            wrapperClasses="d-none"
                            noDataIndication={noDataIndication}
                            defaultSorted={[{ dataField: "customerName", order: "asc" }]}
                            defaultSortDirection="asc"
                        />
                        <ExportCSVButton className="btn btn-outline-primary mr-1" { ...props.csvProps }>Export Customer Summary</ExportCSVButton>
                    </>
                )}
        </ToolkitProvider>
    );
}

export default CustomerSummaryTable;