import { Button } from "react-bootstrap";
import LetterHead from "../../components/Common/LetterHead";
import { VehicleInfo } from "../../../types/VehicleInfo";
import { formatMakeAndModelString } from "../../../utility/formatVehicleMakeModel";
import Watermark from "../../components/Common/Watermark";
import "../../../styles/custom.css";

interface ResultsHeaderProps {
    vehicleInfo: VehicleInfo;
    showJson?: boolean;
    handleShowHideJson?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    editable?: boolean;
};

export function ResultsHeader({ vehicleInfo, showJson, handleShowHideJson, editable } : ResultsHeaderProps) {
    return(
        <div className="vin-search-results-header mb-3">
            <LetterHead />
            <h2 className="mb-4">Cross-Canada Vehicle Identification Number (VIN) report</h2>
            <div className="row">
                <div className="col-6">
                    <h3>{vehicleInfo.vin}</h3>
                    <p>
                        {vehicleInfo.year.toString()} {formatMakeAndModelString(vehicleInfo.make, vehicleInfo.model)}<br />
                        {vehicleInfo.bodyStyle}
                    </p>
                </div>
                <div className="col-6 text-right d-print-none">
                    { ((showJson != null) && (handleShowHideJson != null)) &&
                        <p><Button variant="outline-primary" size="sm" onClick={handleShowHideJson}>{ showJson ? "Hide" : "Show" } Json</Button></p>
                    }
                </div>
            </div>
            { editable && <Watermark text="Preview Only" /> }
        </div>
    );
}

export default ResultsHeader;