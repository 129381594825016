import moment from 'moment';
import 'moment-timezone';

export function formatUTCDateStringToDateTimeLocal(utcDateTime: string, timeZone?: string): string {
    let convertedString = "";
    if (moment(utcDateTime).isValid()) {
        if ((timeZone) && (moment.tz.zone(timeZone))) {
            convertedString = moment.utc(utcDateTime).tz(timeZone).format("YYYY-MM-DD HH:mm:ss");
        } else {
            convertedString = moment.utc(utcDateTime).local().format("YYYY-MM-DD HH:mm:ss");
        }
    }
    return convertedString;
}

export function formatLocalDateTimeToUTCDateTimeDotNet(localDateTime: string, timeZone?: string): string {
    let convertedString = "";
    if (moment(localDateTime).isValid()) {
        if ((timeZone) && (moment.tz.zone(timeZone))) {
            convertedString = moment.tz(localDateTime, timeZone).utc().format("YYYY-MM-DDTHH:mm:ss");
        } else {
            convertedString = moment(localDateTime).utc().format("YYYY-MM-DDTHH:mm:ss");
        }
    }
    return convertedString;
}