import BootstrapTable from 'react-bootstrap-table-next';
import { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { paginationOptions } from "../../../../properties/reactBootstrapTableProperties";
import { BatchOrderInfo } from "../../../../types/BatchOrder";
import { formatUTCDateStringToDateTimeLocal } from '../../../../utility/dateFunctions';
import BatchSearchActionButton from './BatchSearchActionButton';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { CompletedOrderSummary } from '../../../../types/CompletedOrder';

interface BatchSearchResultProps {
    data: BatchOrderInfo[];
    onDeleteHandler: () => void;
}

function BatchSearchResult({ data, onDeleteHandler } : BatchSearchResultProps) {
    
    // const [batchInfoData, setBatchInfoData] = useState<BatchOrderInfo[]>([...data]);

    function noDataIndication() {
        return "There are no order results that match the search criteria in the system.";
    }

    const batchSearchResultColumns: ColumnDescription[] = [{
        dataField: 'createdDate',
        text: 'Creation Date',
        formatter: dateFormatter,
        sort: true,
    },{
        dataField: 'batchId',
        text: 'Batch ID',
        sort: true,
    }, {
        dataField: 'customerEmail',
        text: 'Customer Email',
        sort: true,
    }, {
        dataField: 'df1',   // dummy value
        isDummyField: true,
        sort: false,
        text: 'Orders',
        formatter: orderListFormatter,
    }, {
        dataField: 'actions',
        text: 'Actions',
        isDummyField: true,
        sort: false,
        formatter: actionsFormatter,
        headerStyle: { width: "8%" },
    }];
    
    function dateFormatter(cell: string) {
        return formatUTCDateStringToDateTimeLocal(cell);
    }
    
    function orderListFormatter(cell: string, row: BatchOrderInfo) {
        
        const popover = (summary: CompletedOrderSummary) => (
            <Popover id="popover-basic">
                <Popover.Content>
                    {summary.vins.map(v => <>{v}<br /></>)}
                </Popover.Content>
            </Popover>
        );

        if (row.orderSummaries != null && row.orderSummaries.length > 0) {
            return ( 
                <ul>
                    {row.orderSummaries.map(v => 
                        <li key={v.orderId}>
                            <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={popover(v)}>
                                <span>{formatUTCDateStringToDateTimeLocal(v.orderDate)} (Vins: {v.vins.length})</span>
                            </OverlayTrigger>
                        </li>
                    )}
                </ul>
            );
        } else {
            return (row.orderIds != null && row.orderIds.length > 0) ?
                <ul>{row.orderIds.map(v => <li key={v}>{v}</li>)}</ul> :
                <>&nbsp;</>
        }
    }
    
    function actionsFormatter (cell: string, row: BatchOrderInfo) {
        return <BatchSearchActionButton row={row} onDeleteHandler={onDeleteHandler} />
    }

    return( 
        <>
            <h4 className="mb-3">Batch search results</h4>
            <BootstrapTable 
                keyField="batchId"
                data={data}
                columns={batchSearchResultColumns}
                bootstrap4={true}
                id="order-search-result-table" 
                classes="my-3"
                bordered={false}
                striped={false}
                hover={true}
                noDataIndication={noDataIndication}
                defaultSorted={[{ dataField: "createdDate", order: "asc" }]}
                defaultSortDirection="asc"
                pagination={ paginationFactory(paginationOptions) }
            />
        </>
    );
}

export default BatchSearchResult;