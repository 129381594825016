import { SkVinClaimData } from "../../../types/VehicleInfo";
import { formatDateOfLoss, formatTypeOfLoss, formatTotalClaimAmount } from "../../../utility/SkVinServiceResultFormatters";

interface   SkVinSearchClaimProps {
    claim: SkVinClaimData;
}

export function SkVinSearchClaim( {claim} : SkVinSearchClaimProps ) {    

    function detailRow(detail: string, value: string) {
        return (
            <div className="row mb-1">
                <div className="col-7"><strong>{detail}</strong></div>
                <div className="col-5">{value}</div>
            </div>
        );
    }

    return (
        <>
            <div className="my-4">
                {detailRow("Date of loss:", formatDateOfLoss(claim.DateOfLoss))}
                {detailRow("Extent of loss:", formatTypeOfLoss(claim.TypeOfLoss))}
                {detailRow("Description of loss:", claim.KindOfLoss)}
                {detailRow("Appraised damage amount:", formatTotalClaimAmount(claim))}
            </div>
            <hr />
        </>
    );
}

export default SkVinSearchClaim;
