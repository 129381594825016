import { useState, useEffect } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useGetBasicVehicleInfoMutation, useSetBasicVehicleInfoMutation } from "../../../../store/APIs/basicVehicleInfoAPI";
import { useAppDispatch, useAppSelector } from "../../../../store/ReduxToolkitSamples/hooks";
import { addVinForSearch } from "../../../../store/VinsForSearch/VinsForSearchSlice";
import { selectCustomerInfo } from "../../../../store/transaction/transactionSlice";
import { validateInput } from "./VinSearchFunctions";
import { VINDECODEFAILEDEVENT, VINDECODESUCCESSEVENT, VINMANUALDECODEEVENT, VINVALIDATIONFAILEDEVENT } from "../../../../properties/appInsightsProperties";
import { formatMakeAndModelString } from "../../../../utility/formatVehicleMakeModel";
import { isNullOrWhiteSpace } from "../../../../utility/stringFunctions";
import { ApplicationProblemDetails } from '../../../../types/AuthorizationBFFGeneratedTypes';
import VinSearchManualEntry, { VinSearchManualEntryInputs } from "./VinSearchManualEntry";
import { BasicVehicleInfo, defaultBasicVehicleInfo } from "../../../../types/VehicleInfo";
import { BasicVehicleInfoPayload } from "../../../../types/apiPayloads/BasicVehicleInfoPayload";

export function SingleVinSearch() {
    const statusStrings = {
        default: "",
        apiError: "There was a problem retrieving info for this Vin Number.",
        apiLoading: "Retrieving results..."
    };
    const [vinLoaded, setVinLoaded] = useState<boolean>(false);
    const [invalid, setInvalid] = useState<boolean>(false);
    const [inputErrors, setInputErrors] = useState<string[]>([]);
    const [vinNumber, setVinNumber] = useState<string>("");
    const [formStatus, setFormStatus] = useState<string>(statusStrings.default);
    const dispatch = useAppDispatch();
    const [basicVehicleInfo, setStateBasicVehicleInfo] = useState<BasicVehicleInfo>({...defaultBasicVehicleInfo});
    const [getBasicVehicleInfo, { 
        isLoading: getBasicVehicleInfoIsFetching,
        }] = useGetBasicVehicleInfoMutation();
    const [setBasicVehicleInfo, { 
        isLoading: setBasicVehicleInfoIsFetching,
        }] = useSetBasicVehicleInfoMutation();  
    const appInsights = useAppInsightsContext();
    const customerInfo = useAppSelector(selectCustomerInfo);
    const [isVinNotFound, setIsVinNotFound] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (getBasicVehicleInfoIsFetching || setBasicVehicleInfoIsFetching)
            setFormStatus(statusStrings.apiLoading);
    }, [getBasicVehicleInfoIsFetching, setBasicVehicleInfoIsFetching, statusStrings.apiLoading]);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setInvalid(false);
        setInputErrors([]);
        setFormStatus(statusStrings.default);
        setVinLoaded(false);

        let str = event.target.value;
        setVinNumber(str);

        if (str.length === 0)
            return;

        let errors: string[] = validateInput(str);
         if (errors.length > 0) {
            setInvalid(true);
            setInputErrors(errors);
            if (appInsights !== undefined) {
                appInsights.trackEvent(
                    { name: VINVALIDATIONFAILEDEVENT },
                    {
                        vin: str,
                        validationErrors: errors
                    },
                );
            }
        }
    }

    function handleSearchClick() {
        setIsVinNotFound(false);
        if (!isNullOrWhiteSpace(vinNumber) && (invalid === false)) {
            getBasicVehicleInfo({ vin: vinNumber, name: customerInfo.name, email: customerInfo.emailAddress })
                .unwrap()
                .then((resp: BasicVehicleInfo) => {
                    setFormStatus(`${resp.year} ` + formatMakeAndModelString(resp.make,resp.model) + ` ${resp.bodyStyle}`); 
                    setVinLoaded(true);
                    setStateBasicVehicleInfo({...resp});
                    if (appInsights !== undefined) {
                        appInsights.trackEvent(
                            { name: VINDECODESUCCESSEVENT },
                            { vinInfo: resp },
                        );
                    }
                })
                .catch((error: ApplicationProblemDetails) => {
                    if (appInsights !== undefined) {
                        appInsights.trackEvent(
                            { name: VINDECODEFAILEDEVENT },
                            {
                                vin: vinNumber,
                                status: error.status,
                                msg: JSON.stringify(error.messages)
                            },
                        );
                    }
                    if (error.status === 404) {
                        setIsVinNotFound(true);
                        setFormStatus("Vin information could not be found.");
                    }
                    else {
                        let messages = JSON.stringify(error.messages).replace(/("\\"|\\"")/g, "");
                        setFormStatus(messages);
                    }
                });
        } 
    }

    function handleAddVinToList() {
        if (vinLoaded) {
            dispatch(addVinForSearch(basicVehicleInfo));
        }
        setVinNumber("");
        setVinLoaded(false);
        setFormStatus(statusStrings.default);
    }

    function handleSubmit(e: React.SyntheticEvent) {
        e.preventDefault();
    }

    function handleModalSubmit(formInputs: VinSearchManualEntryInputs) {
        setIsModalOpen(false);
        setIsVinNotFound(false);
        var basicVehicleInfoPayload: BasicVehicleInfoPayload = {
            vin: vinNumber,
            make: formInputs.make,
            model: formInputs.model,
            year: formInputs.year,
            bodyStyle: formInputs.bodyStyle,
        };
        if (appInsights !== undefined) {
            appInsights.trackEvent(
                { name: VINMANUALDECODEEVENT },
                { vinInfo: basicVehicleInfoPayload },
            );
        }

        setBasicVehicleInfo({ name: customerInfo.name, email: customerInfo.emailAddress, basicVehicleInfo: basicVehicleInfoPayload })
        .unwrap()
        .then((resp: BasicVehicleInfo) => {
            setVinLoaded(true);
            setFormStatus(`${formInputs.year} ` + formatMakeAndModelString(formInputs.make,formInputs.model) + ` ${formInputs.bodyStyle}`);
            setStateBasicVehicleInfo({...resp});
        })
        .catch((error: ApplicationProblemDetails) => {
            let messages = JSON.stringify(error.messages).replace(/("\\"|\\"")/g, "");
            setFormStatus(messages);
        });
    }

    return (
        <>
            <Form noValidate validated={vinLoaded} onSubmit={handleSubmit}>
                <Form.Group>
                    <Row>
                        <Col>&nbsp;</Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col sm={9}>
                            <Form.Control type="text" onChange={handleChange} name="vinNumber" id="vinInput" value={vinNumber} data-testid="vinNumber" aria-label={"vin"}
                                isInvalid={invalid} aria-describedby="vinHelpBlock" />
                        </Col>
                        <Col xs="auto">
                            <Button variant="outline-primary" onClick={handleSearchClick} disabled={getBasicVehicleInfoIsFetching}>Search</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Text id="vinHelpBlock" muted>Please enter a VIN</Form.Text>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Control.Feedback type="invalid">
                    {inputErrors.map(err => <div key={err}>{err}<br /></div>)}
                </Form.Control.Feedback>
            </Form>
            <h6>Search result</h6>
            { (formStatus !== "") ? 
                <p>
                    {formStatus} 
                    {isVinNotFound && 
                        <>
                            <VinSearchManualEntry show={isModalOpen} onHide={() => setIsModalOpen(false)} modalSubmit={handleModalSubmit}/>
                            <Button className="ml-5 mb-1" variant="info btn-sm" onClick={() => setIsModalOpen(true) } >Enter Vehicle Info</Button>
                        </>
                    }
                </p> 
                : <p>&nbsp;</p> }
            <p>
                <Button variant="outline-secondary" onClick={handleAddVinToList} disabled={!vinLoaded}>Add to list</Button>
            </p>
        </>
    );
}

export default SingleVinSearch;