// Used to structure Customer info in redux state.
export interface CustomerInfo {
    name: string;
    emailAddress: string;
    valid: boolean;
}

// === Defaults ======================================================
export const defaultCustomerInfo: CustomerInfo = {
    name: "",
    emailAddress: "",
    valid: false,
}