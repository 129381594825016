import { Form, Col } from "react-bootstrap";
import { skRegistrationStatuses } from "../../../../types/VehicleRegistrationInfo";
import SingleSelectDropdown from "../../../components/Common/SingleSelectDropdown";

export interface SkVehicleStatusOptionsProps {
    handleOnChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    status: string | undefined;
}

const SkVehicleStatusOptions = ({ handleOnChange, status }: SkVehicleStatusOptionsProps) => {

    return (
        <Form.Group as={Col} className="col-3" controlId="formRegistrationStatus">
            <Form.Label>Status:</Form.Label>
            <SingleSelectDropdown
                inputName="skStatus"
                handleOnChange={handleOnChange}
                availableOptions={skRegistrationStatuses}
                selectedOption={status || ""}
            />
        </Form.Group>
    )
}

export default SkVehicleStatusOptions;