import { VehicleRegistrationBasicInfo, JurisdictionCode, JurisdictionAndStatusInfo } from "../../../types/VehicleRegistrationInfo";
import { isNullOrWhiteSpace, isUndefinedNullOrWhiteSpace } from "../../../utility/stringFunctions";

interface RegistrationStatusTableProps {
    vehRegInfo?: VehicleRegistrationBasicInfo,
    display?: RegistrationStatusTableDisplayTypes,
}

export enum RegistrationStatusTableDisplayTypes {
    RECENT = "recent",
    PREVIOUS = "previous",
    NA = "na"
}

export function RegistrationStatusTable({ vehRegInfo, display }: RegistrationStatusTableProps) {

    const naRow = <tr><td colSpan={2}>N/A</td></tr>;
    
    function determineRecentStatus() {
        let status = <></>;
        if (vehRegInfo != null) {
            const lastReg = vehRegInfo.lastRegistrationJurisdiction;
            if (lastReg.jurisdiction === JurisdictionCode.SK) {
                let jurisdictionStatus = "";
                if (lastReg.skStatus != null)
                    jurisdictionStatus = lastReg.skStatus;
                status = createJurisdictionRow(lastReg.jurisdiction, jurisdictionStatus);
            } else {
                status = determineIreStatus(lastReg);
            }
        }
        return(status);
    }

    function determineIreStatus(jurisdictionInfo: JurisdictionAndStatusInfo, i?: number) {
        let jurisdictionStatus = "";
        if (!isUndefinedNullOrWhiteSpace(jurisdictionInfo.ireCondition))
            jurisdictionStatus = jurisdictionInfo.ireCondition || "";
        else if (!isUndefinedNullOrWhiteSpace(jurisdictionInfo.ireLocation))
            jurisdictionStatus = jurisdictionInfo.ireLocation || "";
        return createJurisdictionRow(jurisdictionInfo.jurisdiction, jurisdictionStatus, i);
    }

    function createJurisdictionRow(jurisdiction: string, status: string, i?: number) {
        let row = naRow;
        if (!isNullOrWhiteSpace(jurisdiction)) { 
            row = (
                <tr key={i}>
                    <td>{ jurisdiction }</td>
                    <td>
                        <p>{!isNullOrWhiteSpace(status) ? <>{ status }</> : <>&nbsp;</>}</p>
                    </td>
                </tr>
            )
        }
        return row;
    }

    function drawTableRows() {
        let rows = naRow;
        if (vehRegInfo != null && display != null) {
            if (vehRegInfo.lastRegistrationJurisdiction != null && display === RegistrationStatusTableDisplayTypes.RECENT) {
                rows = determineRecentStatus();
            } else if (vehRegInfo.previousRegistrationJurisdictions.length > 0 && display === RegistrationStatusTableDisplayTypes.PREVIOUS) {
                rows = (
                    <>      
                        { vehRegInfo.previousRegistrationJurisdictions.map((row, i) => 
                            determineIreStatus(row, i)
                        )}
                    </>
                );
            }
        }  
        return (rows);
    }

    return(
        <table className="table">
            <thead>
                <tr>
                    <th style={{ width: "50%" }}>Jurisdiction</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>{drawTableRows()}</tbody>
        </table>
    )
}

export default RegistrationStatusTable;