import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import StepperVisual from "./StepperVisual";
import LoadingModal from "../../../components/Common/LoadingModal";
import { stepLabels, OrderSteps } from "./StepperProperties";
import {
	toggleNextButtonDisable,
	displayNextFinishText,
	getStepContent,
} from "./StepperFunctions";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../store/ReduxToolkitSamples/hooks";
import {
	selectCustomerInfo,
	selectOrderInfo,
} from "../../../../store/transaction/transactionSlice";
import {
	selectVinsForSearch,
	UpdateNotesProps,
	updateNotes,
	updateRegistration,
} from "../../../../store/VinsForSearch/VinsForSearchSlice";
import {
	useCreateInProgressOrderMutation,
	useUpdateInProgressOrderMutation,
	useUpdateVehicleNotesMutation,
} from "../../../../store/APIs/inProgressOrderHandlingApi";
import { useCreateCompletedOrderMutation } from "../../../../store/APIs/completedOrderHandlingApi";
import { isNullOrWhiteSpace } from "../../../../utility/stringFunctions";
import { resetInProgressState } from "../../../../utility/resetInProgressState";
import {
	getActiveAccountName,
	getActiveAccountEmail,
} from "../../../components/Auth/authUtilities";
import {
	createNewOrder,
	updateExistingOrder,
	finalizeOrder,
} from "../OrderFunctions";
import {
	InProgressOrderPayload,
	UpdateVehicleNotesPayload,
	UpdateVehicleRegistrationInspectionDetailsPayload,
} from "../../../../types/apiPayloads/InProgressOrderPayload";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import { useUpdateVehicleRegistrationInspectionDetailsMutation } from "../../../../store/APIs/vehicleRegistrationInfoApi";

export default function HorizontalLinearStepper() {
	const [activeStep, setActiveStep] = useState(0);
	const [modalContent, setModalContent] = useState(<></>);
	const [finishing, setFinishing] = useState<boolean>(false);
	const customerInfo = useAppSelector(selectCustomerInfo);
	const orderInfo = useAppSelector(selectOrderInfo);
	const vinsForSearch = useAppSelector(selectVinsForSearch);
	const [
		createOrder,
		{
			data: createOrderData,
			error: createOrderError,
			isLoading: createOrderIsFetching,
			isSuccess: createOrderIsSuccess,
			isError: createOrderIsError,
		},
	] = useCreateInProgressOrderMutation();
	const [
		updateOrder,
		{
			data: updateOrderData,
			error: updateOrderError,
			isLoading: updateOrderIsFetching,
			isSuccess: updateOrderIsSuccess,
			isError: updateOrderIsError,
		},
	] = useUpdateInProgressOrderMutation();
	const [
		createCompletedOrder,
		{
			data: createCompletedOrderData,
			error: createCompletedOrderError,
			isLoading: createCompletedOrderIsFetching,
			isSuccess: createCompletedOrderIsSuccess,
			isError: createCompletedOrderIsError,
		},
	] = useCreateCompletedOrderMutation();
	const [
		updateVehicleRegInspDetails,
		{
			data: updateVehicleRegInspDetailsData,
			error: updateVehicleRegInspDetailsError,
			isLoading: updateVehicleRegInspDetailsIsFetching,
			isSuccess: updateVehicleRegInspDetailsIsSuccess,
			isError: updateVehicleRegInspDetailsIsError,
		},
	] = useUpdateVehicleRegistrationInspectionDetailsMutation();
	const [updateVehicleNotes] = useUpdateVehicleNotesMutation();

	const history = useHistory();
	const dispatch = useAppDispatch();
	const { instance } = useMsal();

	useEffect(() => {
		if (createOrderIsFetching || updateOrderIsFetching) {
			setModalContent(
				<p>
					Processing order and gathering additional information on each VIN...
				</p>
			);
		} else if (createOrderIsError || updateOrderIsError) {
			setModalContent(
				<>
					<p>
						There was an error processing the order. Please contact support.
					</p>
					{createOrderIsError && <p>{JSON.stringify(createOrderError)}</p>}
					{updateOrderIsError && <p>{JSON.stringify(createOrderError)}</p>}
				</>
			);
		} else {
			setModalContent(<></>);
		}
	}, [
		createOrderIsFetching,
		updateOrderIsFetching,
		createOrderIsError,
		updateOrderIsError,
	]);

	const handleNext = async () => {
		// If we are moving from the Enter Vin(s) to the Registration Information screen, we want to do a bit of extra work beforehand.
		if (activeStep === OrderSteps.EnterVins) {
			let orderPayload: InProgressOrderPayload = {
				name: customerInfo.name,
				email: customerInfo.emailAddress,
				vinList: vinsForSearch.map((v) => {
					return v.vin;
				}),
				employeeName: getActiveAccountName(instance),
				employeeEmail: getActiveAccountEmail(instance),
			};
			if (isNullOrWhiteSpace(orderInfo.orderId)) {
				createNewOrder(createOrder, forwardOneStep, orderPayload);
			} else {
				orderPayload.orderId = orderInfo.orderId;
				updateExistingOrder(
					updateOrder,
					forwardOneStep,
					orderPayload,
					orderInfo
				);
			}
		} else {
			// If we're on the last page, finalize order
			if (activeStep === stepLabels.length - 1) {
				if (
					(await handleUnsavedNotes()) === undefined &&
					(await handleUnsavedSaskInspectionDetails()) === undefined
				) {
					setFinishing(true);
					if (!isNullOrWhiteSpace(orderInfo.orderId))
						finalizeOrder(
							createCompletedOrder,
							history,
							setFinishing,
							orderInfo.orderId
						);
				}
			} else {
				forwardOneStep();
			}
		}
	};

	const forwardOneStep = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const backOneStep = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const resetSteps = () => {
		setActiveStep(0);
	};

	function handleFormReset() {
		resetInProgressState();
		resetSteps();
	}

	async function handleUnsavedNotes() {
		let isThereUnsavedNote = false;
		vinsForSearch.forEach((v) => {
			if (v.notesUnSaved) isThereUnsavedNote = true;
		});

		if (isThereUnsavedNote) {
			if (
				window.confirm(
					"There is unsaved note(s). Do you want to save them all?"
				)
			) {
				vinsForSearch.forEach((v) => {
					const payload: UpdateVehicleNotesPayload = {
						orderId: orderInfo.orderId,
						vin: v.vin,
						notes: v.notes,
					};
					if (v.notesUnSaved) {
						updateVehicleNotes(payload)
							.unwrap()
							.then((resp) => {
								const dispatchPayload: UpdateNotesProps = {
									vin: v.vin,
									notes: v.notes,
									notesUnSaved: false,
								};
								dispatch(updateNotes(dispatchPayload));
								toast.success("Saved notes successfully.");
							})
							.catch((error) => {
								toast.error(
									"An error was encountered when saving the notes. Try again or call support."
								);
								return "SystemError";
							});
					}
				});
			} else {
				toast.error("Please click Update for each note to save.");
				return "DoNotSave";
			}
		}
	}

	async function handleUnsavedSaskInspectionDetails() {
		let isThereUnsavedInspectionDetails = false;
		vinsForSearch.forEach((v) => {
			if (v.vehicleRegistration.inspectionDetailsUnsaved)
				isThereUnsavedInspectionDetails = true;
		});

		if (isThereUnsavedInspectionDetails) {
			if (
				window.confirm(
					"There is unsaved vehicle Registration and inspection detail(s). Do you want to save them?"
				)
			) {
				vinsForSearch.forEach((v) => {
					const payload: UpdateVehicleRegistrationInspectionDetailsPayload = {
						orderId: orderInfo.orderId,
						vin: v.vin,
						vehicleRegisterable:
							v.vehicleRegistration.inspectionInfo?.vehicleRegisterable ||
							false,
						inspectionRequired:
							v.vehicleRegistration.inspectionInfo?.inspectionRequired || "",
						inspectionDetailsUnsaved: false,
					};

					if (v.vehicleRegistration.inspectionDetailsUnsaved) {
						updateVehicleRegInspDetails(payload)
							.unwrap()
							.then((resp) => {
								dispatch(
									updateRegistration({
										...v.vehicleRegistration,
										inspectionDetailsUnsaved: false,
									})
								);
								toast.success(
									"Saved registration and inspection details successfully."
								);
							})
							.catch((error) => {
								toast.error(
									"An error was encountered when saving the registration and inspection details. Try again or call support."
								);
								return "SystemError";
							});
					}
				});
			} else {
				toast.error(
					"Please click Update for vehicle Registration and inspection details to save."
				);
				return "DoNotSave";
			}
		}
	}

	return (
		<>
			<Box sx={{ width: "100%" }}>
				<StepperVisual activeStep={activeStep} />
				{getStepContent(activeStep)}
				<Box
					sx={{ display: "flex", flexDirection: "row", pt: 2 }}
					className="d-print-none"
				>
					{activeStep !== 0 && (
						<Button variant="outline-secondary" onClick={backOneStep}>
							Previous
						</Button>
					)}
					<Box sx={{ flex: "1 1 auto" }} />
					<Button
						variant="outline-secondary"
						onClick={handleFormReset}
						className="mr-2"
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={handleNext}
						disabled={
							toggleNextButtonDisable(
								activeStep,
								customerInfo.valid,
								vinsForSearch.length
							) || finishing
						}
					>
						{displayNextFinishText(activeStep, finishing)}
					</Button>
				</Box>
			</Box>
			<LoadingModal
				show={
					createOrderIsFetching ||
					updateOrderIsFetching ||
					createOrderIsError ||
					updateOrderIsError
				}
				content={modalContent}
			/>
		</>
	);
}
