import { Accordion } from "react-bootstrap";
import { useAppSelector } from "../../../../store/ReduxToolkitSamples/hooks";
import { selectVinsForSearch } from "../../../../store/VinsForSearch/VinsForSearchSlice";
import { VehicleRegistrationCard } from "./VehicleRegistrationCard";

export const VehicleRegistration = () => {
    const vehicleList = useAppSelector(selectVinsForSearch);

    return (
        <>
            <h2 className="d-print-none">Enter Vehicle Registration Information</h2>  
            <Accordion className="mt-3 mb-5">
                { vehicleList.map((vehicleInfo, i) => <VehicleRegistrationCard key={i} index={i.toString()} vehicleInfo={vehicleInfo} />)}
            </Accordion>
        </>
    )
}

export default VehicleRegistration;