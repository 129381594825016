import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./axiosBaseQuery";
import { bffEndpoints } from "../../properties/appProperties";

export const permissionsApi = createApi({
  reducerPath: 'permissionsApi',  
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Permissions'],
  endpoints: (builder) => ({
    getAllPermissions: builder.query<string[],void>({ 
      query: () => ({
        url:  bffEndpoints.GET_ALL_PERMISSIONS,
        method: 'GET',            
      }),      
      providesTags: ['Permissions'],
    }),
  }),
})
export const { useGetAllPermissionsQuery } = permissionsApi
