import { Card, Accordion } from "react-bootstrap";
import { VehicleInfo } from "../../../../types/VehicleInfo";
import { VehicleRegistrationForm } from './VehicleRegistrationForm';
import { formatMakeAndModelString } from "../../../../utility/formatVehicleMakeModel";

interface VehicleRegistrationCardProps {
    index: string;
    vehicleInfo: VehicleInfo;
}

export const VehicleRegistrationCard = ({ index, vehicleInfo }: VehicleRegistrationCardProps) => {
    return (
        <Card className="vehicle-registration-card" data-testid="vehicleRegistrationCard">
            <Accordion.Toggle as={Card.Header} eventKey={index} className="d-print-none">
                <strong>{vehicleInfo.vin}</strong> - {vehicleInfo.year} {formatMakeAndModelString(vehicleInfo.make, vehicleInfo.model)}
            </Accordion.Toggle>
            {vehicleInfo.vehicleRegistration ?
                <VehicleRegistrationForm index={index} vehicleInfo={vehicleInfo} /> :
                <h2>Could not find registration info.</h2>
            }
        </Card >
    );
}

export default VehicleRegistrationCard;