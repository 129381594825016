import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { stepLabels } from "./StepperProperties";

interface StepperVisualProps {
    activeStep: number;
}

function StepperVisual ({ activeStep } : StepperVisualProps) {
    return (
        <Stepper activeStep={activeStep} alternativeLabel className="d-print-none mb-5">
            {stepLabels.map((label, index) => {
                return (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    )
}

export default StepperVisual;