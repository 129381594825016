import { ColumnDescription } from "react-bootstrap-table-next";
import { CSVProps } from 'react-bootstrap-table2-toolkit';
import OrderSearchActionButton from "./OrderSearchActionButton";
import { formatUTCDateStringToDateTimeLocal } from "../../../../utility/dateFunctions";
import { CompletedOrderSummary } from "../../../../types/CompletedOrder";
import { Link } from "react-router-dom";
import { uiPaths } from "../../../../properties/appProperties";

export const searchResultColumns: ColumnDescription[] = [{
    dataField: 'orderDate',
    text: 'Order Date',
    formatter: dateFormatter,
    sort: true,
},{
    dataField: 'customerName',
    text: 'Customer Name',
    sort: true,
}, {
    dataField: 'email',
    text: 'Email',
    sort: true,
}, {
    dataField: 'df1',   // dummy value
    isDummyField: true,
    text: 'Vins',
    formatter: vinListFormatter,
    csvFormatter: vinListCsvFormatter,
}, {
    dataField: 'df2',   // dummy value
    isDummyField: true,
    text: 'No. of Vins',
    hidden: true,
    formatter: noOfVinsCsvFormatter,
    csvFormatter: noOfVinsCsvFormatter,
    csvType: Number,
}, {
    dataField: 'employeeName',
    text: 'Employee',
    sort: true,
}, {
    dataField: 'employeeEmail',
    text: 'Employee Email',
    hidden: true,
}, {
    dataField: 'batchId',
    text: 'Batch Id',
    formatter: batchIdFormatter,
    sort: true,
}, {
    dataField: 'actions',
    text: 'Actions',
    isDummyField: true,
    sort: false,
    formatter: actionsFormatter,
    headerStyle: { width: "8%" },
    csvExport: false,
}];

export function produceCsvProps (filename: string, startDate: string, endDate: string) : CSVProps {
    return {
        fileName: `${filename}_${startDate}-${endDate}.csv`,
    };
}

function dateFormatter(cell: string) {
    return formatUTCDateStringToDateTimeLocal(cell);
}

function vinListFormatter(_cell: string, row: CompletedOrderSummary) {
    return (row.vins != null && row.vins.length > 0) ?
        <ul>{row.vins.map(v => <li key={v}>{v}</li>)}</ul> :
        <>&nbsp;</>
}

function vinListCsvFormatter(_cell: string, row: CompletedOrderSummary) {
    let vinList = "";
    (row.vins != null && row.vins.length > 0) &&
        row.vins.forEach(v => { vinList += `${v},` })
    return vinList;
}

function noOfVinsCsvFormatter(_cell: string, row: CompletedOrderSummary) {
    return (row.vins != null && row.vins.length > 0) ? row.vins.length : 0;
}

function actionsFormatter (_cell: string, row: CompletedOrderSummary) {
    return <OrderSearchActionButton row={row} />
}

function batchIdFormatter (_cell: string, row: CompletedOrderSummary) {
    return <Link to={{
        pathname: uiPaths.BATCH_DETAILS,
        state: {
            batchId: row.batchId
        }
     }} className="nav-link">{row.batchId}</Link>;
}