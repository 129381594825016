
export function validateInput(vin: string): string[] {
    let errors: string[] = [];

    if(vin.length > 17) 
        errors.push("VIN must be 17 characters or less.");

    if(!vin.match("^[a-zA-Z0-9]+$"))
        errors.push("VIN must include numbers or letters only.");

    if(vin.match("[O]"))
        errors.push("VIN must not include the character O.");

    if(vin.match("[I]"))
        errors.push("VIN must not include the character I.");

    if(vin.match("[Q]"))
        errors.push("VIN must not include the character Q.");

    return errors;
}
