import { Button } from "react-bootstrap";
import { renderToString } from 'react-dom/server'
import { customerEmailBody, customerEmailSubject } from "../../../properties/customerEmailProperties";

interface GenerateCustomerEmailButtonProps {
    toEmail: string;
    orderId: string;
}

function GenerateCustomerEmailButton({ toEmail, orderId } : GenerateCustomerEmailButtonProps) {

    function generateEmail() {

        function makeTextFile(text) {
            let data = new Blob([text], {type: 'text/plain'});
            return window.URL.createObjectURL(data);
        }

        let emailBodyAsString = renderToString(customerEmailBody());

        let emlContent = 'To: ' + toEmail + '\n';
        emlContent += 'Subject: ' + customerEmailSubject + '\n';
        emlContent += 'X-Unsent: 1\n';
        emlContent += 'Content-Type: text/html\n';
        emlContent += '\n';
        emlContent += emailBodyAsString;

        // Generate a link element, download the file, and remove the link element
        let a = document.createElement('a'); 
        a.href = makeTextFile(emlContent);
        a.download = toEmail + '-' + orderId + '.eml';
        document.body.appendChild(a);
        a.click();
        a.parentNode?.removeChild(a);
    }

    return(
        <Button variant="primary" id="emailDownload" size="sm" onClick={generateEmail}>Generate Email</Button>
    );
}

export default GenerateCustomerEmailButton;