import React from 'react';
import { Button } from "react-bootstrap";
import { JurisdictionAndStatusInfo } from '../../../../types/VehicleRegistrationInfo';

interface PrevRegStatusTableProps {
    prevRegistrationStatusList: JurisdictionAndStatusInfo[];
    handleRemove: (index: number) => void;
}

const PreviousRegistrationStatusTable = ({ prevRegistrationStatusList, handleRemove }: PrevRegStatusTableProps) => {

    function determineStatusTableData(condition, location) {
        if (condition && location)
            return (
                <td>
                    Condition: {condition}<br />
                    Location: {location}
                </td>);
        else if (condition)
            return (<td>Condition: {condition}</td>);
        else
            return (<td>Location: {location}</td>);
    }

    function handleRemoveClick({ target }) {
        const index = parseInt(target.id);
        handleRemove(index);
    }

    return (
        <table className='table'>
            <thead>
                <tr>
                    <th style={{ width: "50%" }}>Jurisdiction</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    prevRegistrationStatusList.map((jurStatInfo, index) => (
                        <tr key={`tablerow${index}`}>
                            <td>{jurStatInfo.jurisdiction}</td>
                            {determineStatusTableData(jurStatInfo.ireCondition, jurStatInfo.ireLocation)}
                            <td>
                                <p className="d-print-none mb-5">
                                    <Button size="sm" variant="primary" id={index.toString()} onClick={handleRemoveClick}>Remove</Button>
                                </p>
                            </td>

                        </tr>
                    ))

                }
            </tbody>
        </table>
    );
};

export default PreviousRegistrationStatusTable;
