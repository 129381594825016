import {useAppDispatch} from "../../../../store/ReduxToolkitSamples/hooks";
import {removeVinForSearch} from "../../../../store/VinsForSearch/VinsForSearchSlice";
import { VehicleInfo } from "../../../../types/VehicleInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import { formatMakeAndModelString } from "../../../../utility/formatVehicleMakeModel";

interface VinSearchListItemProps {
    vehicleInfo: VehicleInfo;
}

function VinSearchListItem({vehicleInfo}: VinSearchListItemProps) {
    const dispatch = useAppDispatch();
    return (
        <tr>
            <td>
                <div className="row mb-1">
                    <div className="col-4"><strong>VIN</strong></div>
                    <div className="col-8 text-right">{vehicleInfo.vin}</div>
                </div>
                <div className="row">
                    <div className="col-4"><strong>Vehicle details</strong></div>
                    <div className="col-8 text-right">{vehicleInfo.year} { formatMakeAndModelString(vehicleInfo.make, vehicleInfo.model) }</div>
                </div>
            </td>
            <td className="text-right" style={{ width: "10%" }}>
                <FontAwesomeIcon icon={faTimesCircle} size="lg" className="red-times-circle" 
                    onClick={() => dispatch(removeVinForSearch(vehicleInfo.vin))} />
            </td>
        </tr>
    );
}
export default VinSearchListItem;
