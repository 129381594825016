import React from 'react'

interface SingleSelectDropdownProps {
    handleOnChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    availableOptions: readonly string[];
    selectedOption: string | undefined;
    inputName: string;
    disabled?: boolean;
}

const SingleSelectDropdown = ({ handleOnChange, availableOptions, selectedOption, inputName, disabled }: SingleSelectDropdownProps) => {

    const isDisabled: boolean = disabled === undefined ? false : disabled;

    function GenerateSelectOptions() {
        let options: JSX.Element[] = [];
        availableOptions.forEach(opt => {
            if (opt === selectedOption) {
                options.push(<option key={opt} value={opt} selected>{opt}</option>);
            } else {
                options.push(<option key={opt} value={opt}>{opt}</option>);
            }
        });
        return options;
    }

    return (
        <div>
            <select name={inputName} aria-label={inputName} onChange={handleOnChange} className="form-control" disabled={isDisabled}>
                {GenerateSelectOptions()}
            </select>
        </div>
    )
}

export default SingleSelectDropdown;
