import Accordion from 'react-bootstrap/Accordion';
import OrderResultsReport from './OrderResultsReport';
import { VinInfo } from "../../../../types/CompletedOrder";

interface OrderResultsAccordionProps {
    vinSearches: VinInfo[],
}

function OrderResultsAccordion({ vinSearches } : OrderResultsAccordionProps) {
    return(
        <Accordion className="mt-3 mb-5 results-accordion">
            { vinSearches.map((vinForSearch, i) => <OrderResultsReport index={i.toString()} vehicleInfo={vinForSearch} key={i} />) }
        </Accordion>
    );
}

export default OrderResultsAccordion;