export const customerEmailSubject = "Cross Canada VIN Search";

export const customerEmailBody = () => {
    return (
        <html style={{fontSize: 16}}>
            <body>
                <p>Hi there,</p>
                <p>Please find attached the results of your Cross-Canada VIN Search. The report has all the information you need on your VIN(s) with a new, easy-to-read layout.</p>
                <p>If you have any additional questions, please contact the SGI Customer Service Centre at 1-800-667-9868.</p>
                <p>Take care out there.</p>
                <p>SGI's Cross-Canada VIN search team</p>
                <p>
                    <strong>Information Services</strong><br />
                    Auto Fund Operations
                </p>
                <p>
                    SGI (Regina Operations Centre)<br />
                    5104 Donnelly Cres., Regina, SK S4P 3C4<br />
                    Ph: 1-800-667-9868<br /> 
                    <a href="mailto:canada_vin_search@sgi.sk.ca">canada_vin_search@sgi.sk.ca</a> | <a href="https://www.sgi.sk.ca/cross-canada-vin">www.sgi.sk.ca/cross-canada-vin</a>
                </p>
                <div style={{textAlign: "center", borderTop: "1px solid #ccc", paddingTop: "1em"}}>
                    <p><a href="http://www.facebook.com/SGIcommunity">Facebook</a> | <a href="http://twitter.com/#!/SGItweets">Twitter</a> | <a href="http://www.linkedin.com/company/sgi_5">LinkedIn</a> | <a href="http://www.youtube.com/SGIcommunications">YouTube</a> | <a href="http://instagram.com/sgiphotos">Instagram</a></p>
                </div>
            </body>
        </html>
    )
};