import { InProgressOrderResponse, InProgressVinResponse, InProgressOrderSummaryResponse } from "../types/apiResponses/InProgressOrderResponse";
import { OrderInfo, defaultOrderInfo } from "../types/OrderInfo";
import { InProgressOrder, defaultInProgressOrder, InProgressVin, defaultInProgressVin, InProgressOrderSummary, defaultInProgressOrderSummary } from "../types/InProgressOrder";
import { CustomerInfo, defaultCustomerInfo } from "../types/CustomerInfo";

export function mapInProgressOrderSummaryResponseToInternalModel (resp: InProgressOrderSummaryResponse) : InProgressOrderSummary {
    return {...defaultInProgressOrderSummary, 
        orderId: resp.orderId,
        name: resp.name,
        email: resp.email,
        vinList: resp.vinList,
        creationDate: resp.creationDate,
        employeeName: resp.employeeName,
        employeeEmail: resp.employeeEmail
    }
}

export function mapInProgressOrderResponseToInternalModel (resp: InProgressOrderResponse) : InProgressOrder {

    function mapInProgressVinResponseToInternalModel(vinResp: InProgressVinResponse) : InProgressVin {
        return {...defaultInProgressVin,
            vin: vinResp.vin,
            make: vinResp.make,
            model: vinResp.model,
            bodyStyle: vinResp.bodyStyle,
            year: vinResp.year,
            skSearchResults: vinResp.skSearchResults,
            vintelligenceResults: vinResp.vintelligenceResults,
            vehicleRegistrationInfo: vinResp.vehicleRegistrationInfo,
            legacyInfo: vinResp.legacyInfo,
            notes: vinResp.notes,
        }
    }

    function mapInProgressVinResponseArrayToInternalModelArray(vinRespArray: InProgressVinResponse[]) : InProgressVin[] {
        let inProgressVinArray: InProgressVin[] = [];
        (vinRespArray != null && vinRespArray.length > 0) && vinRespArray.forEach((vin) => inProgressVinArray.push(mapInProgressVinResponseToInternalModel(vin)));
        return inProgressVinArray;
    }

    return {...defaultInProgressOrder,
        orderId: resp.orderId,
        name: resp.name,
        email: resp.email,
        vinList: resp.vinList,
        creationDate: resp.creationDate,
        vehicleInfoList: mapInProgressVinResponseArrayToInternalModelArray(resp.vehicleInfoList),
        employeeName: resp.employeeName,
        employeeEmail: resp.employeeEmail
    }
}

export function mapInProgressOrderToOrderInfo (model: InProgressOrder) : OrderInfo {
    return {...defaultOrderInfo,
        orderId: model.orderId,
        creationDate: model.creationDate,
        vins: model.vinList,
        employeeName: model.employeeName,
        employeeEmail: model.employeeEmail
    }
}

export function mapInProgressOrderToCustomerInfo (model: InProgressOrder) : CustomerInfo {
    return {...defaultCustomerInfo,
        name: model.name,
        emailAddress: model.email,
        valid: true,
    }
}