import InProgressOrdersTable from "./InProgressOrdersTable";
import { useGetInProgressOrdersQuery } from "../../../store/APIs/inProgressOrderHandlingApi";

function InProgressOrders() {
    const {
        data: inProgressOrdersData,
        error: inProgressOrdersError,
        refetch: inProgressOrdersRefetch,
        isError: inProgressOrdersIsError,
        isFetching: inProgressOrdersIsFetching,
        isLoading: inProgressOrdersIsLoading,
        isSuccess: inProgressOrdersIsSuccess
    } = useGetInProgressOrdersQuery();
    
    return(
        <>
            <h2>In Progress Orders</h2>
            { inProgressOrdersIsLoading && <p>Retrieving In Progress Orders from server...</p> }
            { inProgressOrdersIsError && 
                <p>
                    An error was encountered while trying to fetch the In Progress Orders. Try again or contact support. <br />
                    { JSON.stringify(inProgressOrdersError) }
                </p> 
            }
            { (inProgressOrdersIsSuccess && inProgressOrdersData && !inProgressOrdersIsError) && 
                <InProgressOrdersTable data={inProgressOrdersData} />
            }
        </>
    );
}

export default InProgressOrders;