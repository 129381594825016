import { Form } from "react-bootstrap"
import { CustomerInfo } from "../../../../types/CustomerInfo"
import { OrderInfo } from "../../../../types/OrderInfo";
import { CustomerFormValidationErrors } from "./CustomerFormValidation"

export interface AddCustomerFormProps {
    onBlur: React.FocusEventHandler<HTMLInputElement>;
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
    formValidated: boolean;
    customerInfo: CustomerInfo;
    orderInfo: OrderInfo;
    formValidationErrors: CustomerFormValidationErrors;
}
export const AddCustomerForm = ({ onBlur, onChange, formValidated, customerInfo, orderInfo, formValidationErrors }: AddCustomerFormProps) => {
    
    function handleFormSubmit(e: React.SyntheticEvent) {
        e.preventDefault();
    }

    return (
        <Form
            noValidate
            validated={ formValidated }
            id="addCustomerForm"
            data-testid="addCustomerForm"
            onSubmit={ handleFormSubmit }
        >
            <Form.Group className="mb-4">
                <Form.Label><strong>Full name</strong></Form.Label>
                <Form.Control
                    type="text"
                    onChange={onChange}
                    onBlur={onBlur}
                    name="name"
                    id="name"
                    data-testid="customerName"
                    aria-label="customerName"
                    value={customerInfo.name || ''}
                    isInvalid={!!formValidationErrors.name}
                    disabled={orderInfo.orderId == null || orderInfo.orderId.trim() === ''? false: true}
                    required
                    placeholder="First and last name or business name."
                />
                <Form.Control.Feedback type="invalid" data-testid="customerFirstNameValidationError">
                    {formValidationErrors.name}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label><strong>Email address</strong></Form.Label>
                <Form.Control
                    type="text"
                    onChange={onChange}
                    onBlur={onBlur}
                    name="emailAddress"
                    id="emailAddress"
                    data-testid="emailAddress"
                    aria-label="emailAddress"
                    value={customerInfo.emailAddress || ''}
                    isInvalid={!!formValidationErrors.emailAddress}
                    disabled={orderInfo.orderId == null || orderInfo.orderId.trim() === ''? false: true}
                    required
                    placeholder="someone@example.com"
                />
                <Form.Control.Feedback type="invalid" data-testid="customerEmailAddressValidationError">
                    {formValidationErrors.emailAddress}
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    );
}