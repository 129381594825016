import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from "../../../store/ReduxToolkitSamples/hooks";
import { useUpdateVehicleNotesMutation } from "../../../store/APIs/inProgressOrderHandlingApi";
import { selectOrderInfo } from "../../../store/transaction/transactionSlice";
import { UpdateNotesProps, updateNotes } from '../../../store/VinsForSearch/VinsForSearchSlice';
import { isNullOrWhiteSpace, isUndefinedNullOrWhiteSpace } from '../../../utility/stringFunctions';
import { VehicleInfo } from '../../../types/VehicleInfo';
import { UpdateVehicleNotesPayload } from "../../../types/apiPayloads/InProgressOrderPayload";

interface NotesProps {
    vehicleInfo: VehicleInfo;
    editable?: boolean
}

export const NOTES_CHARACTER_LIMIT = 500;

const Notes = ({ vehicleInfo, editable }: NotesProps) => {
    const [notesValue, setNotesValue] = useState(!isUndefinedNullOrWhiteSpace(vehicleInfo.notes) ? vehicleInfo.notes : "");
    const [notesClassname, setNotesClassname] = useState(determineNotesClassname());
    const orderInfo = useAppSelector(selectOrderInfo);
    const [updateVehicleNotes, {
        data: updateVehicleNotesData,
        error: updateVehicleNotesError,
        isLoading: updateVehicleNotesIsFetching,
        isSuccess: updateVehicleNotesIsSuccess,
        isError: updateVehicleNotesIsError
    }] = useUpdateVehicleNotesMutation();
    const dispatch = useAppDispatch();

    useEffect(() => {
      setNotesClassname(determineNotesClassname());
    }, [notesValue]);

    function determineNotesClassname() {
        if (!isUndefinedNullOrWhiteSpace(notesValue))
            return 'vin-search-results-notes mb-5';
        else  
            return 'd-print-none vin-search-results-notes mb-5';
    }
    
    function handleBlur(e: any) {
        e.preventDefault();
        const dispatchPayload: UpdateNotesProps = {
            vin: vehicleInfo.vin,
            notes: notesValue,
            notesUnSaved: true
        };
        dispatch(updateNotes(dispatchPayload));
    }

    function handleUpdate() {
        if (!isNullOrWhiteSpace(orderInfo.orderId)) {
            const payload: UpdateVehicleNotesPayload = {
                orderId: orderInfo.orderId,
                vin: vehicleInfo.vin,
                notes: notesValue,
            }
            updateVehicleNotes(payload)
                .unwrap()
                .then((resp) => {
                    const dispatchPayload: UpdateNotesProps = {
                        vin: vehicleInfo.vin,
                        notes: notesValue,
                        notesUnSaved: false
                    };
                    dispatch(updateNotes(dispatchPayload));
                    toast.success("Saved notes successfully.");
                })
                .catch((error) => {
                    toast.error("An error was encountered when saving the notes. Try again or call support.");
                });
        }
    }

    function convertNotesForDisplay(notes: string) {
        const lineArray = notes.split(/\r?\n|\r|\n/g);
        return <p>{ lineArray.map((l, index) => <span key={index}>{l}<br /></span>) }</p>;
    }

    return (
        <div className={notesClassname} data-testid='notes-wrapper'>
            <h4>Notes</h4>
            <hr />
            { !editable && <div data-testid="notes-div">{convertNotesForDisplay(notesValue)}</div> }
            { editable && 
                <form name='Notes' aria-label='Notes'>
                    <div className='form-group'>
                        <label htmlFor='ResultNotesTextArea'>Special notes about this vehicle:</label>
                        <textarea 
                            className='form-control' 
                            name='ResultNotesTextArea' 
                            id='ResultNotesTextArea' 
                            rows={3}
                            onChange={(e) => setNotesValue(e.target.value)}
                            value={notesValue}
                            maxLength={NOTES_CHARACTER_LIMIT}
                            onBlur={handleBlur}
                        />
                        <div className='notes-character-count d-print-none'>[Character count:  {notesValue.length} / {NOTES_CHARACTER_LIMIT} max characters]</div>
                    </div>
                    <p className='text-right d-print-none'>
                        <Button size='sm' variant='primary' onClick={handleUpdate} disabled={updateVehicleNotesIsFetching}>
                            { updateVehicleNotesIsFetching ? "Updating..." : "Update" }
                        </Button>
                    </p>
                </form>
            }
        </div>
    );
};

export default Notes;
