export interface CompletedOrder {
    orderId: string;
    vinSearchDate: string;
    vinSearches: VinInfo[];
    customerName: string;
    email: string;
    employeeName: string;
    employeeEmail: string;
    batchId: string;
}

export interface VinInfo {
    orderId: string,
    vinSearchDate: string,
    vin: string,
    bodyStyle: string,
    year: string,
    make: string,
    model: string,
    skSearchResponse: string,
    vehicleRegistration: VehicleRegistration,
    notes: string,
}

export interface VehicleRegistration {
    lastRegistrationJurisdiction: JurisdictionAndStatusInfo;
    previousRegistrationJurisdictions: JurisdictionAndStatusInfo[];
    jurisdictionsNotResponding: string[];
    inspectionInfo: InspectionInfo | undefined;
}

export interface JurisdictionAndStatusInfo {
    jurisdiction: string;
    skStatus: string | undefined;
    ireLocation: string | undefined;
    ireCondition: string | undefined;
}

export interface InspectionInfo {
    vehicleRegisterable: string;
    inspectionRequired: string;
    importantInformation: string[];
}

export interface CompletedOrderSummary
{
    orderId: string;
    orderDate: string;
    customerName: string;
    email: string;
    vins: string[];
    employeeName: string;
    batchId: string;
}

// === Defaults ======================================================
export const defaultInspectionInfo: InspectionInfo = {
    vehicleRegisterable: "",
    inspectionRequired: "",
    importantInformation: []
}

export const defaultJurisdictionAndStatusInfo: JurisdictionAndStatusInfo = {
    jurisdiction: "",
    skStatus: undefined,
    ireLocation: undefined,
    ireCondition: undefined
}

export const defaultVehicleRegistration: VehicleRegistration = {
    lastRegistrationJurisdiction: defaultJurisdictionAndStatusInfo,
    previousRegistrationJurisdictions: [],
    jurisdictionsNotResponding: [],
    inspectionInfo: defaultInspectionInfo,
}

export const defaultVinInfo: VinInfo = {    
    orderId : "",
    vinSearchDate: "",
    vin: "",
    bodyStyle: "",
    year: "",
    make: "",
    model: "",
    skSearchResponse: "",
    vehicleRegistration: defaultVehicleRegistration,
    notes: "",
}

export const defaultCompletedOrder: CompletedOrder = {
    orderId: "",
    vinSearchDate: "",
    vinSearches: [],
    customerName: "",
    email: "",
    employeeName: "",
    employeeEmail: "",
    batchId: "",
}

export const defaultCompletedOrderSummary: CompletedOrderSummary = {
    orderId: "",
    orderDate: "",
    customerName: "",
    email: "",
    vins: [],
    employeeName: "",
    batchId: ""
}