import { Accordion, Card } from "react-bootstrap";
import ResultsHeader from "../../Results/ResultsHeader";
import SkVinSearchResults from "../../Results/SkVinSearchResults";
import RegistrationAndInspectionDetails from "../../Results/RegistrationAndInspectionDetails";
import Disclaimer from "../../Results/Disclaimer";
import Notes from "../../../components/Notes/Notes";
import { SkData, SkVinClaimData } from "../../../../types/VehicleInfo";
import { VinInfo } from "../../../../types/CompletedOrder";
import { isNullOrWhiteSpace, isUndefinedNullOrWhiteSpace } from "../../../../utility/stringFunctions";
import { formatMakeAndModelString } from "../../../../utility/formatVehicleMakeModel";
import { mapVinInfoToVehicleInfo } from "../../../../mappers/VehicleInfoMappers";
import { SkVinServiceReturnCode } from "../../../../enums/SkVinServiceReturnCode";

interface VinSearchResultReportProps {
    index: string,
    vehicleInfo: VinInfo,
}

export function OrderResultsReport({ index, vehicleInfo }: VinSearchResultReportProps) {
    const vehicleInfoResult = mapVinInfoToVehicleInfo(vehicleInfo);
    const skData: SkData|undefined = (!isNullOrWhiteSpace(vehicleInfoResult.skSearchResponse)) ? 
        JSON.parse(vehicleInfoResult.skSearchResponse) : undefined;
    const skClaims: SkVinClaimData[] = ((skData !== undefined) && (skData.VinClaims !== null) && (skData.VinClaims.length > 0)) ? [...skData.VinClaims].sort((a, b) => a.DateOfLoss < b.DateOfLoss ? 1 : -1) : [];

    function displayNotes() {
        return (!isUndefinedNullOrWhiteSpace(vehicleInfoResult.notes)) && 
            <Notes vehicleInfo={vehicleInfoResult} editable={false} />
    }

    return (
        <Card className="vehicle-info-summary break-after">
            <Accordion.Toggle as={Card.Header} eventKey={index} className="d-print-none">
                <strong>{vehicleInfo.vin}</strong> - {vehicleInfo.year} {formatMakeAndModelString(vehicleInfo.make, vehicleInfo.model)}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index} className="vehicle-info-summary-body">
                <Card.Body>
                    {(!vehicleInfo.vin || vehicleInfo.vin.match(/^\s*$/)) ? <p>Error getting Vin Info</p> :
                        <>
                            <ResultsHeader vehicleInfo={vehicleInfoResult} />
                            { (vehicleInfoResult.vin && !isNullOrWhiteSpace(vehicleInfoResult.vin)) ? 
                                <>
                                    <RegistrationAndInspectionDetails vehicleInfo={vehicleInfoResult} />
                                    { displayNotes() }
                                </>
                            : 
                                <p>Error getting Vin Info</p> 
                            }
                            { (skData !== undefined && skData.ReturnCode === SkVinServiceReturnCode.Results) && <SkVinSearchResults skData={skData} skClaims={skClaims} /> }
                            <Disclaimer orderDate={vehicleInfo.vinSearchDate.toString()} />
                        </>
                    }
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

export default OrderResultsReport;

