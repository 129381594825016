import {useAppSelector} from "../../../../store/ReduxToolkitSamples/hooks";
import {selectVinsForSearch} from "../../../../store/VinsForSearch/VinsForSearchSlice";
import SingleVinSearch from "./SingleVinSearch";
import MultipleVinSearch from "./MultipleVinSearch";
import VinSearchListItem from "./VinSearchListItem";
import { Tabs, Tab } from "react-bootstrap";

function VinSearch() {
    const vinsForSearch = useAppSelector(selectVinsForSearch);

    return (
        <>
            <div className="row mb-5">
                <div className="col-lg-6 mb-lg-0 mb-md-5">
                    <h2 className="mb-4">VIN Search</h2>
                    <Tabs defaultActiveKey="single" id="uncontrolled-tab-example">
                        <Tab eventKey="single" title="VIN">
                            <SingleVinSearch />
                        </Tab>
                        <Tab eventKey="multiple" title="VINs">
                            <MultipleVinSearch />   
                        </Tab>
                    </Tabs>
                </div>
                <div className="col-lg-6">
                    <h2 className="mb-4">My list</h2>
                    <table className="table">
                        <tbody>
                            { vinsForSearch.map(v => <VinSearchListItem key={v.vin} vehicleInfo={v} />) }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
export default VinSearch;
