import { BatchOrderInfoResponse } from "../types/apiResponses/BatchOrderResponse";
import { BatchOrderInfo, defaultBatchOrderInfo } from "../types/BatchOrder";
import { mapCompletedOrderSummaryResponseArrayToInternalTypeArray } from "../mappers/CompletedOrderMappers";

export function mapBatchOrderInfoResponseToBatchOrderInfo (resp: BatchOrderInfoResponse) : BatchOrderInfo {
    return {...defaultBatchOrderInfo,
        batchId: resp.batchId,
        customerEmail: resp.customerEmail,
        createdByEmployee: resp.createdByEmployee,
        createdDate: resp.createdDate,
        lastUpdatedDate: resp.lastUpdatedDate,
        orderIds: resp.orderIds,
        status: resp.status,
        orderSummaries: mapCompletedOrderSummaryResponseArrayToInternalTypeArray(resp.orderSummaries),
    };
}