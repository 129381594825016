import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { ToastContainer } from "react-toastify";
import './App.css';
import Home from './app/pages/Home';
import { getScopes } from './app/components/Auth/authConfig';
import { PrivateRoute } from "./app/components/Auth/PrivateRoute";
import { developerAreaAll, vinSearchReportAll, vinSearchTransactionAll} from "./app/components/Auth/permissionConstants";
import Header from './app/components/Header';
import Footer from './app/components/Footer';
import EightPtTest from "./app/pages/EightPtTest/EightPtTest";
import PageNotFound from './app/pages/PageNotFound';
import OrderSearch from "./app/pages/Admin/OrderSearch";
import OrderResults from "./app/pages/Admin/OrderResults";
import BatchManagement from "./app/pages/Admin/BatchManagement";
import HorizontalLinearStepper from "./app/pages/CreateOrder/Stepper";
import BatchDetails from './app/pages/Admin/BatchDetails';
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { uiPaths } from "./properties/appProperties";

interface AppProps{
  pca: PublicClientApplication
}

function App({ pca }: AppProps) {
  var scopes = getScopes();
  return (
    <MsalProvider instance={pca}>
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            loadingComponent={() => <div>Authenticating...</div>}
            errorComponent={({ error }) => (
                <>{console.log('ERROR: ', error?.errorMessage)}</>
            )}
            authenticationRequest={{
                scopes: scopes,
            }}
        >
            <BrowserRouter>
                <ToastContainer className="d-print-none" />
                <Header />
                <div className="container contentContainer">
                    <div className="row">
                        <div className="col">
                            <Switch>
                                <PrivateRoute
                                    Route
                                    exact
                                    path={uiPaths.ROOT}
                                    component={Home}
                                    requiredPermissions={ [vinSearchTransactionAll]}
                                    redirectOnUnAuthorized
                                />      
                                <PrivateRoute
                                    Route
                                    exact
                                    path={uiPaths.CREATE_ORDER}
                                    component={HorizontalLinearStepper}
                                    requiredPermissions={ [vinSearchTransactionAll]}
                                    redirectOnUnAuthorized
                                />    
                                <PrivateRoute
                                    Route
                                    exact
                                    path={uiPaths.ORDER_SEARCH}
                                    component={OrderSearch}
                                    requiredPermissions={ [vinSearchReportAll]}
                                    redirectOnUnAuthorized
                                />
                                <PrivateRoute
                                    Route
                                    exact
                                    path={uiPaths.BATCH_MANAGEMENT}
                                    component={BatchManagement}
                                    requiredPermissions={ [vinSearchTransactionAll]}
                                    redirectOnUnAuthorized
                                />
                                <PrivateRoute
                                    Route
                                    exact
                                    path={uiPaths.BATCH_DETAILS}
                                    component={BatchDetails}
                                    requiredPermissions={ [vinSearchTransactionAll]}
                                    redirectOnUnAuthorized
                                />
                                <PrivateRoute
                                    Route
                                    exact
                                    path={uiPaths.ORDER_RESULT}
                                    component={OrderResults}
                                    requiredPermissions={ [vinSearchTransactionAll,vinSearchReportAll]}
                                    requireAll={false}
                                    redirectOnUnAuthorized
                                />
                                <PrivateRoute
                                    Route
                                    exact
                                    path="/EightPtTest"
                                    component={EightPtTest}
                                    requiredPermissions={ [developerAreaAll]}                    
                                    redirectOnUnAuthorized
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </div>
                    </div>
                </div>
                <Footer />
            </BrowserRouter>
        </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;
