import { PaginationOptions } from "react-bootstrap-table-next";

function sizePerPageRenderer ({ options, currSizePerPage, onSizePerPageChange }) {
    function buttonClasses(page): string {
        let classes = "btn ";
        classes += (currSizePerPage === `${page}`) ? "btn-secondary" : "btn-outline-secondary";
        return classes;
    }
    
    return(
        <div className="btn-group mr-3" role="group">
            { options.map(option => (
                    <button key={ option.text } type="button" onClick={ () => onSizePerPageChange(option.page) }
                        className={ buttonClasses(option.page) }>
                        { option.text }
                    </button>
              )) }
        </div>
    );
}

export const paginationOptions: PaginationOptions = {
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: sizePerPageRenderer
};