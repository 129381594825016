import { toast } from 'react-toastify';
import TableActionButton, { MenuOption } from "../../../components/TableActionButton";
import { useDeleteBatchOrderMutation } from "../../../../store/APIs/batchHandlingApi";
import { BatchOrderInfo } from "../../../../types/BatchOrder";
import { useHistory } from 'react-router-dom';
import { uiPaths } from '../../../../properties/appProperties';


interface BatchSearchActionButtonProps {
    row: BatchOrderInfo;
    onDeleteHandler: (batchId: string) => void;
}

function BatchSearchActionButton({ row, onDeleteHandler } : BatchSearchActionButtonProps) {    
    const history = useHistory();
    const [deleteBatchOrder] = useDeleteBatchOrderMutation();

    function handleViewDetailsClick() {
        // Pass Batch ID to the batch details screen.
        history.push({pathname: uiPaths.BATCH_DETAILS, state: { batchId: row.batchId}});        
    }

    function handleDeleteBatchClick() {
        
        let Continue =  window.confirm("Are you sure you would like to delete the batch order?");
        if (Continue) {              
            deleteBatchOrder(row.batchId)
                .unwrap()
                .then((resp) => {
                    onDeleteHandler(row.batchId);
                    toast.success("Successfully deleted batch order from the system.");            
                })
                .catch((error) => {
                    toast.error("An error was encountered when deleting the Batch order. Try again or call support.");
                });
        }
        else
        {
            toast.info("Batch delete canceled!");
        }        
    }

    const menuOptions: MenuOption[] = [
        {menuText: "View Details", onClickFunction: handleViewDetailsClick, dataTestId: "viewDetails"},
        {menuText: "Delete Batch", onClickFunction: handleDeleteBatchClick, dataTestId: "deleteBatch"},
    ];

    return (
        <TableActionButton buttonId={`dropdown-button-drop-${row.batchId}`} menuOptions={menuOptions} />
    );
}

export default BatchSearchActionButton;