import { useEffect, useState } from "react";
import SkVinSearchResults from "./SkVinSearchResults";
import ResultsHeader from "./ResultsHeader";
import JsonDisplay from "./JsonDisplay";
import Notes from "../../components/Notes/Notes";
import RegistrationAndInspectionDetails from "./RegistrationAndInspectionDetails";
import Disclaimer from "./Disclaimer";
import { useAppSelector } from "../../../store/ReduxToolkitSamples/hooks";
import { selectOrderInfo } from "../../../store/transaction/transactionSlice";
import { VehicleInfo, SkData, SkVinClaimData } from "../../../types/VehicleInfo";
import { SkVinServiceReturnCode } from "../../../enums/SkVinServiceReturnCode";
import { isNullOrWhiteSpace } from "../../../utility/stringFunctions";

interface VehicleDataProps {
    vehicleInfo:VehicleInfo;
}

export function VehicleData( {vehicleInfo} : VehicleDataProps ) {    
    const [showJson, setShowJson] = useState(false);
    const [skData, setSkData] = useState<SkData>();
    const [skClaims, setSkClaims] = useState<SkVinClaimData[]>([]);
    const orderInfo = useAppSelector(selectOrderInfo);

    // Need to wait until SkSearchResponse data is returned from API and loaded into state before we can parse and display it.
    useEffect(() => {
        if (!isNullOrWhiteSpace(vehicleInfo.skSearchResponse)) {
            let newSkData: SkData = JSON.parse(vehicleInfo.skSearchResponse);
            let newClaims = newSkData.VinClaims;
            ((newClaims !== null) && (newClaims.length > 1)) && newClaims.sort((a, b) => a.DateOfLoss < b.DateOfLoss ? 1 : -1);
            setSkData(newSkData);
            setSkClaims(newClaims);
        }
    }, [vehicleInfo]);

    function showJsonOnClick(e: React.MouseEvent<HTMLButtonElement>) {
        setShowJson(prevValue => !prevValue);
    }

    return (   
        <>
            <ResultsHeader vehicleInfo={vehicleInfo} handleShowHideJson={showJsonOnClick} showJson={showJson} editable={true} />
            { (vehicleInfo.vin && !isNullOrWhiteSpace(vehicleInfo.vin)) ? 
                <>
                    <RegistrationAndInspectionDetails vehicleInfo={vehicleInfo} editable={true} />
                    <Notes vehicleInfo={vehicleInfo} editable={true} />
                </>
            : 
                <p>Error getting Vin Info</p> 
            }
            { (skData !== undefined && skData.ReturnCode === SkVinServiceReturnCode.Results) && <SkVinSearchResults skData={skData} skClaims={skClaims} /> }
            <Disclaimer orderDate={orderInfo.creationDate} />
            { showJson && <JsonDisplay vehicleInfo={vehicleInfo} /> }
        </>
    );
}

export default VehicleData;
