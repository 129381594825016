import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { useEffect, useState } from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { produceCsvProps } from "./SearchResultFormatters";
import { CompletedOrderSummary } from "../../../../types/CompletedOrder";

export interface EmployeeSummaryTableProps {
    data: CompletedOrderSummary[];
    startDate: string;
    endDate: string;
}

export interface EmployeeReportProps {
    employeeName: string;
    orderTotal: number;
    vinTotal: number;
}

function EmployeeSummaryTable({ data, startDate, endDate } : EmployeeSummaryTableProps) {
    const [employeeSummaryData, setEmployeeSummaryData] = useState<EmployeeReportProps[]>([]);
    const { ExportCSVButton } = CSVExport;

    useEffect(() => {
        if (data != null && data.length > 0) {
            let unknown: EmployeeReportProps = { employeeName: "Unknown", orderTotal: 0, vinTotal: 0 };
            let reducedData: EmployeeReportProps[] = Object.values( data.reduce((r, o) => {
                    if (o.employeeName != null && o.employeeName !== "") {
                        r[o.employeeName] = r[o.employeeName] || { employeeName: o.employeeName, orderTotal: 0, vinTotal: 0 };
                        r[o.employeeName].orderTotal += 1;
                        r[o.employeeName].vinTotal += o.vins.length;
                        return r;
                    } else {
                        unknown.orderTotal += 1;
                        unknown.vinTotal += o.vins.length;
                        return r;
                    }
                }, {}) );
            reducedData.push(unknown);
            setEmployeeSummaryData(reducedData);
        }
    },[data]);

    const employeeSummaryDataColumns: ColumnDescription[] = [{
        dataField: 'employeeName',
        text: 'Employee',
        sort: true,
    },{
        dataField: 'orderTotal',
        text: 'Total Orders',
        sort: true,
    }, {
        dataField: 'vinTotal',
        text: 'Total Vins',
        sort: true,
    }]; 

    function noDataIndication() {
        return "There are no order results that match the search criteria in the system.";
    }

    return (
        <ToolkitProvider keyField="employeeName" data={ employeeSummaryData } columns={ employeeSummaryDataColumns } bootstrap4={ true } exportCSV={ produceCsvProps("EmployeeSummary", startDate, endDate) }>
            {
                props => (
                    <>
                        <BootstrapTable 
                            { ...props.baseProps }
                            id="employee-summary-result-table" 
                            classes="my-3"
                            wrapperClasses="d-none"
                            noDataIndication={noDataIndication}
                            defaultSorted={[{ dataField: "employeeName", order: "asc" }]}
                            defaultSortDirection="asc"
                        />
                        <ExportCSVButton className="btn btn-outline-primary mr-1" { ...props.csvProps }>Export Employee Summary</ExportCSVButton>
                    </>
                )}
        </ToolkitProvider>
    );
}

export default EmployeeSummaryTable;