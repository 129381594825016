import { Option } from "react-multi-select-component/src/lib/interfaces";
import { JurisdictionCode } from "../types/VehicleRegistrationInfo";

export function preLoadSelectedOptions(selections: string[]): Option[] {
    let formattedSelected: Option[] = [];
    selections.forEach(s => s !== "" && formattedSelected.push({ label: s, value: s }));
    return formattedSelected;
}

export function GenerateJurisdictionMultiSelectOptions() {
    let options: Option[] = [];
    Object.keys(JurisdictionCode).forEach(key => {
        const value = JurisdictionCode[key as keyof typeof JurisdictionCode];
        (key !== "Empty") && options.push({ label: value, value: value });
    });
    return options;
}