import { IPublicClientApplication } from '@azure/msal-browser';

export function isAuthorized(
  permissions: string[],
  requiredPerms: string[],
  requireAll = true,
): Boolean {
  if (!requiredPerms || requiredPerms.length === 0) return true;
  if (!permissions || permissions.length === 0) return false;
  let authorized: Boolean;
  requireAll
    ? (authorized = requiredPerms.every((reqPermission) =>
                    permissions.includes(reqPermission)
      ))
    : (authorized = requiredPerms.some((reqPermission) =>
    permissions.includes(reqPermission)
));
  return authorized;
}

interface IdTokenClaims {
  email?: string;
  permissions?: string[];
}

export function getActiveAccountName(msalInstance: IPublicClientApplication) {
  return msalInstance.getActiveAccount()?.name;
}

export function getActiveAccountEmail(msalInstance: IPublicClientApplication) {
  return (msalInstance.getActiveAccount()?.idTokenClaims as IdTokenClaims)
    ?.email
    ? (msalInstance.getActiveAccount()?.idTokenClaims as IdTokenClaims)
        ?.email
    : [];
}
