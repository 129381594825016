// Used to structure Vehicle Registration Info in Redux state
export interface VehicleRegistrationBasicInfo {
	vin: string;
	lastRegistrationJurisdiction: JurisdictionAndStatusInfo;
	previousRegistrationJurisdictions: JurisdictionAndStatusInfo[];
	jurisdictionsNotResponding: JurisdictionCode[];
	inspectionInfo: InspectionInfo | undefined;
	inspectionDetailsUnsaved: boolean;
}

export interface JurisdictionAndStatusInfo {
	jurisdiction: JurisdictionCode;
	skStatus: SkVehicleRegistrationStatus | undefined;
	ireLocation: IreVehicleLocation | undefined;
	ireCondition: IreVehicleCondition | undefined;
}

export interface PreviousJurisdictionsAndStatusInfo {
	jurisdictions: JurisdictionCode[];
	ireLocation: IreVehicleLocation | undefined;
	ireCondition: IreVehicleCondition | undefined;
}

export interface InspectionCertificate {
	inspectionCertificateType: number;
	inspectionDate: string;
	inspectionComment: string;
}

export interface LegacyInfo {
	deferInspectionExist: boolean;
	inspectionCertificates: InspectionCertificate[];
}

export interface InspectionInfo {
	vehicleRegisterable: boolean;
	inspectionRequired: string;
	importantInformation: string[];
	legacyInfo: LegacyInfo | undefined;
}

export enum JurisdictionCode {
	Empty = "",
	AB = "Alberta",
	NT = "Northwest Territories",
	BC = "British Columbia",
	NU = "Nunavut",
	MB = "Manitoba",
	ON = "Ontario",
	NB = "New Brunswick",
	PE = "Prince Edward Island",
	NF = "Newfoundland and Labrador",
	QC = "Quebec",
	NS = "Nova Scotia",
	SK = "Saskatchewan",
	YT = "Yukon",
}

export const skRegistrationStatuses = [
	"Normal",
	"Abandoned",
	"Cosmetic",
	"Dismantled",
	"Hulk",
	"Mechanical",
	"Non-repairable",
	"Rebuilt",
	"Registerable TLV",
	"Stolen",
	"No Information",
	"Unsafe-Inspection",
	"Unsafe-Repair",
	"Very Unsafe",
] as const;
export type SkVehicleRegistrationStatus = typeof skRegistrationStatuses[number];

export const ireVehicleConditions = [
	"",
	"Normal",
	"Damaged",
	"Non-repairable",
	"Rebuilt",
	"Salvage",
	"Stolen",
] as const;
export type IreVehicleCondition = typeof ireVehicleConditions[number];

export const ireVehicleLocations = ["", "Stolen"] as const;
export type IreVehicleLocation = typeof ireVehicleLocations[number];

// === Defaults ======================================================
export const defaultJurisdictionAndStatusInfo: JurisdictionAndStatusInfo = {
	jurisdiction: JurisdictionCode.Empty,
	skStatus: undefined,
	ireLocation: undefined,
	ireCondition: undefined,
};

export const defaultVehicleRegistrationBasicInfo: VehicleRegistrationBasicInfo =
	{
		vin: "",
		lastRegistrationJurisdiction: defaultJurisdictionAndStatusInfo,
		previousRegistrationJurisdictions: [defaultJurisdictionAndStatusInfo],
		jurisdictionsNotResponding: [],
		inspectionInfo: undefined,
		inspectionDetailsUnsaved: false,
	};

export const defaultInspectionInfo: InspectionInfo = {
	vehicleRegisterable: false,
	inspectionRequired: "",
	importantInformation: [],
	legacyInfo: undefined,
};
