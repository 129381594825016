import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AppInsightsContext, ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import "./scss/App.scss";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ai } from "./AppInsights";
import { initializeMsalInstance, msalInstance } from "./app/components/Auth/initializeMsal";
import { store } from './store/store';
import { EnvironmentVariableContext } from "./store/Contexts/EnvironmentVariableContext";
import { EnvironmentVariableConfig, devEnvironmentVariableConfig } from "./types/EnvironmentVariableConfig";
import { RuntimeConfigPath, setBffApiBaseUrl } from "./properties/appProperties";
import "./styles/custom.css";
import "./styles/print.css";

startApp();

async function startApp() {
  let config = devEnvironmentVariableConfig;
  (window as any)['runConfig'] = config;
  if (process.env.NODE_ENV === "development") {
  //   const { worker } = require('./mocks/browser');
  //   worker.start();
  //   worker.printHandlers();
  }

  if (process.env.NODE_ENV !== "development") {
    try {      
      let result = await fetch(RuntimeConfigPath);
      config = await result.json();
      (window as any)['runConfig'] = config;
      if (config.REACT_APP_BFF_API_BASEURL === 'undefined') {
        console.error("There was a problem initializing the application: E5300");
        throw new Error();
      }
    } catch (error: any) {
      if (error.message) {
        console.error("There was a problem initializing the application: E5100");
      }
      renderApp();
      return;
    }
  }
  setBffApiBaseUrl(config.REACT_APP_BFF_API_BASEURL);
  initializeMsalInstance();
  ai.initialize(config.REACT_APP_APPINSIGHTS_INSTR_KEY).then(({ appInsights, reactPlugin }) => {
    renderApp(config, reactPlugin);
  }).catch((error) => {
    console.error("There was a problem initializing the application: E5200");
    renderApp(config);
  });
}

function renderApp(runtimeConfig?: EnvironmentVariableConfig, reactPlugin?: ReactPlugin) {
  let persistor = persistStore(store);  
  ReactDOM.render(
    <React.StrictMode>
      {runtimeConfig !== undefined ? (
        <EnvironmentVariableContext.Provider value={runtimeConfig!}>
          <AppInsightsContext.Provider value={reactPlugin!}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <App pca={msalInstance} />
              </PersistGate>
            </Provider>
          </AppInsightsContext.Provider>
        </EnvironmentVariableContext.Provider>
      ) : (
        <div>
          There was an error loading the application. Please contact support.
        </div>
      )}
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
