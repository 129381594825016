import { devEnvironmentVariableConfig } from "../types/EnvironmentVariableConfig";

export const RuntimeConfigPath = "/config.json";

export let BFF_API_BASEURL = devEnvironmentVariableConfig.REACT_APP_BFF_API_BASEURL;

export function setBffApiBaseUrl(bffApiBaseUrl: string) {
  BFF_API_BASEURL = bffApiBaseUrl;
}

export enum uiPaths {
    ROOT = "/",
    CREATE_ORDER = "/CreateOrder",
    ORDER_SEARCH = "/OrderSearch",
    ORDER_RESULT = "/Order/ResultReport",
    BATCH_MANAGEMENT = "/Batch/Manage",
    BATCH_DETAILS = "/Batch/Details",
}

export enum bffEndpoints {
    GET_BASIC_VEHICLE_INFO = '/getBasicVehicleInfo',
    GET_BASIC_VEHICLE_INFO_LIST = '/getBasicVehicleInfoList',    
    SET_BASIC_VEHICLE_INFO = '/setBasicVehicleInfo',
    CREATE_COMPLETED_ORDER = '/createCompletedOrder',
    SEARCH_COMPLETED_ORDERS = '/getCompletedOrders',
    GET_COMPLETED_ORDER = '/getCompletedOrder',
    UPDATE_VEH_REGISTRATION_INFO = '/updateVehicleRegistrationInfo',
    UPDATE_VEH_NOTES = "/updateVehicleNotes",
    UPDATE_VEH_REGISTRATION_INSPECTION_DETAILS = "/updateVehicleRegistrationInspectionDetails",
    CREATE_IN_PROGRESS_ORDER = '/createInProgressOrder',
    UPDATE_IN_PROGRESS_ORDER = '/updateInProgressOrder',
    DELETE_IN_PROGRESS_ORDER = '/deleteInProgressOrder',
    GET_IN_PROGRESS_ORDER = '/getInProgressOrder',
    GET_IN_PROGRESS_ORDERS = '/getInProgressOrders',
    CREATE_BATCH_ORDER = '/createBatchOrder',
    GET_BATCH_ORDER = '/getBatchOrder',
    DELETE_BATCH_ORDER = '/deleteBatchOrder',
    SEARCH_BATCH_ORDERS = '/getBatchOrders',
    ADD_COMPLETED_ORDERS_TO_BATCH_ORDER = '/addOrdersToBatchOrder',
    REMOVE_COMPLETED_ORDERS_FROM_BATCH_ORDER = '/removeOrdersFromBatchOrder',
    GET_ALL_PERMISSIONS = '/getAllPermissions',
    CREATE_LOGIN_AUDIT_LOG = '/auditlog/login',
    CREATE_LOGOUT_AUDIT_LOG = '/auditlog/logout',
}