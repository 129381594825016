import React, { useState } from "react";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import { Form, Button, Col } from "react-bootstrap"
import { FormErrors } from ".";
import moment from 'moment';

export interface SearchFormProps {
    onSubmit: (searchInputs: SearchInputs) => void;
    formErrors: FormErrors;
    setFormErrors: any;
}

export interface SearchInputs {
    searchVin: string;
    customerName: string;
    customerEmail: string;
    startDate: string;
    endDate: string;
}

const SearchForm = ({ onSubmit, formErrors, setFormErrors }: SearchFormProps) =>  {
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment().add(1, 'day'));
    const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
    const [formInputs, setFormInputs] = useState<SearchInputs>({
        searchVin: "",
        customerName: "",
        customerEmail: "",
        startDate: "",
        endDate: ""
    });
  
   const onBlurEmail = (event: { target: HTMLInputElement }) =>
    {
            const validEmailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
            if (!event.target.value.match(validEmailFormat))
                setFormErrors({emailError:'Please provide a valid email address'});
            else
                setFormErrors({emailError:undefined});
    }

    function handleDateChange(datesPicked) {
        setStartDate(datesPicked.startDate);
        setEndDate(datesPicked.endDate);
    }

    function handleFocusChange(focusedInput: FocusedInputShape | null) {
        setFocusedInput(focusedInput);
      }

    function handleInputChange({target}) {
        setFormInputs({ ...formInputs, [target.name]: target.value});
    }

    function handleFormSubmit(event) {
        event.preventDefault();
        const inputsForSubmit = { ...formInputs };
        if (startDate != null && endDate != null) {
            inputsForSubmit.startDate = startDate.utc().format("YYYY-MM-DD");
            inputsForSubmit.endDate = endDate.utc().format("YYYY-MM-DD");
            onSubmit(inputsForSubmit);
        }
    }

    return (
        <div className="d-print-none mb-5">
            <h2>Order Search</h2>
            <Form noValidate onSubmit={handleFormSubmit} id="vinSearchResultSearchForm">
                <Form.Row>
                    <Form.Group as={Col} controlId="formSearchVin">
                        <Form.Label>VIN</Form.Label>
                        <Form.Control type="text" name="searchVin" data-testid="searchFormVinNumber" placeholder="VIN" value={formInputs.searchVin} onChange={handleInputChange} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formCustomerName">
                        <Form.Label>Customer Name</Form.Label>
                        <Form.Control type="text" name="customerName" data-testid="searchFormCustomerName" placeholder="Customer Name" value={formInputs.customerName} onChange={handleInputChange} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" name="customerEmail" data-testid="searchFormEmail" placeholder="Customer Email" onBlur={onBlurEmail} isInvalid={!!formErrors.emailError} onChange={handleInputChange} value={formInputs.customerEmail} />
                        
                        <Form.Control.Feedback type="invalid">{formErrors.emailError}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formSearchDate">
                        <Form.Label>Search Date</Form.Label>
                        {/* <Form.Control type="date" name="searchDate" placeholder="VIN Search Date" /> */}
                        <DateRangePicker 
                            startDate={startDate}
                            startDateId="startDate"
                            endDate={endDate}
                            endDateId="endDate"
                            onDatesChange={handleDateChange}
                            focusedInput={focusedInput}
                            onFocusChange={handleFocusChange}
                            isOutsideRange={() => false}
                        />
                    </Form.Group>
                </Form.Row>
                <p className="text-right"><Button variant="primary" type="submit">Search</Button></p>
            </Form>
        </div>
    );
}

export default SearchForm;